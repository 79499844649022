export const COLUMNS_EXERCISE = 'COLUMNS_EXERCISE'
export const PICTURES_EXERCISE = 'PICTURES_EXERCISE'
export const REPEAT_AFTER_TEACHER_ACTIVITY = 'REPEAT_AFTER_TEACHER_ACTIVITY'
export const SPELL_WORD_ACTIVITY = 'SPELL_WORD_ACTIVITY'
export const ANSWER_THE_QUESTION_ACTIVITY = 'ANSWER_THE_QUESTION_ACTIVITY'
export const TILT_HEAD_ACTIVITY = 'TILT_HEAD_ACTIVITY'
export const DETECT_PHONEMES = 'DETECT_PHONEMES'
export const LETTER_SOUND_FLUENCY_ACTIVITY = 'LETTER_SOUND_FLUENCY_ACTIVITY'
export const FIRST_SOUND_FLUENCY_ACTIVITY = 'FIRST_SOUND_FLUENCY_ACTIVITY'
export const FIRST_SOUND_FLUENCY_EXERCISE = 'FIRST_SOUND_FLUENCY_EXERCISE'
export const LETTER_SOUND_FLUENCY_EXERCISE = 'LETTER_SOUND_FLUENCY_EXERCISE'
export const LETTER_NAMING_FLUENCY_EXERCISE = 'LETTER_NAMING_FLUENCY_EXERCISE'
export const LETTER_NAMING_FLUENCY_ACTIVITY = 'LETTER_NAMING_FLUENCY_ACTIVITY'
export const LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY = 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY'
export const LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE = 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE'
export const LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_ACTIVITY =
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_ACTIVITY'
export const LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_ACTIVITY = 'LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_ACTIVITY'
export const LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_EXERCISE = 'LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_EXERCISE'
export const LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_EXERCISE =
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_EXERCISE'
export const LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_ACTIVITY = 'LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_ACTIVITY'
export const LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_EXERCISE = 'LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_EXERCISE'
export const LETTER_SOUND_FLUENCY_COMPLETIONS_ACTIVITY = 'LETTER_SOUND_FLUENCY_COMPLETIONS_ACTIVITY'
export const LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE = 'LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE'
export const ORAL_READING_FLUENCY_ACTIVITY = 'ORAL_READING_FLUENCY_ACTIVITY'
export const ORAL_READING_FLUENCY_EXERCISE = 'ORAL_READING_FLUENCY_EXERCISE'
export const MAZE_ACTIVITY = 'MAZE_ACTIVITY'
export const MAZE_EXERCISE = 'MAZE_EXERCISE'
export const PHONEME_SEGMENTATION_EXERCISE = 'PHONEME_SEGMENTATION_EXERCISE'
export const PHONEME_SEGMENTATION_ACTIVITY = 'PHONEME_SEGMENTATION_ACTIVITY'
export const FIRST_SOUND_FLUENCY_NO_TIMER_EXERCISE = 'FIRST_SOUND_FLUENCY_NO_TIMER_EXERCISE'
export const FIRST_SOUND_FLUENCY_NO_TIMER_ACTIVITY = 'FIRST_SOUND_FLUENCY_NO_TIMER_ACTIVITY'
export const LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_ACTIVITY =
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_ACTIVITY'
export const LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_EXERCISE =
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_EXERCISE'
export const LNF_ACCURACY_SCORE_THRESHOLD = 50
