import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Eye, Trash2 } from 'lucide-react'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import FirstSoundFluencyScore from '@/components/Admin/Dashboards/FirstSoundFluencyScore'
import HeaderDetails from '@/components/Admin/Dashboards/HeaderDetails'
import LetterNamingFluencyScore from '@/components/Admin/Dashboards/LetterNamingFluencyScore'
import LetterSoundCompletionFluencyScore from '@/components/Admin/Dashboards/LetterSoundFluencyScore'
import MazeScore from '@/components/Admin/Dashboards/MazeScore'
import OralReadingFluencyScore from '@/components/Admin/Dashboards/OralReadingFluencyScore'
import PhonemeSegmentationScore from '@/components/Admin/Dashboards/PhonemeSegmentationFluencyScore'
import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { LoadingIcon, LoadingWrapper } from '@/components/Loading/Loading'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { camelToKebabCase, kebabToCamelCase } from '@/lib/utils'
import { LessonAssignment, LessonId, LessonIds } from '@/types/lesson-assignment'
import { deleteLessonAssignment, getStudentDetails, getStudentResults } from '@/utils/api'

type LetterNamingLessonIds = Extract<
  LessonId,
  | 'letter-naming-correspondence'
  | 'letter-naming-correspondence-no-highlight'
  | 'letter-naming-no-highlight'
  | 'letter-naming-fluency'
  | 'letter-naming-new-highlight'
  | 'letter-naming-fluency-with-steps'
  | 'letter-naming-correspondence-with-steps'
>

function StudentLessonDetails() {
  const { lessonId: lessonIdParam, id } = useParams<{ lessonId: string; id: string }>()
  const lessonId = camelToKebabCase(lessonIdParam!) as LessonId
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const queryString = window.location.search

  const {
    data: student,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['student', id],
    queryFn: async () => {
      const { data } = await getStudentDetails(id!)
      return data
    },
    enabled: !!id,
  })

  const {
    data: lessonAssignmentsResults,
    isLoading: isLoadingLessonAssignmentsResults,
    isFetching: isFetchingLessonAssignmentsResults,
  } = useQuery({
    queryKey: ['student-results', id, lessonId],
    queryFn: async () => {
      const { data } = await getStudentResults(id!, lessonId)
      return data
    },
    enabled: !!id && !!lessonId,
  })

  const prefetchAssessmengScores = useCallback(
    (assignmentId: string) => {
      queryClient.prefetchQuery({
        queryKey: ['student-results', id, lessonId, assignmentId],
        queryFn: async () => {
          const { data } = await getStudentResults(id!, lessonId, assignmentId)
          return data
        },
      })
    },
    [id, lessonId, queryClient]
  )

  const deleteLessonMutation = useMutation({
    mutationFn: (assignmentId: string) => deleteLessonAssignment(assignmentId),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['student-results', id, lessonId] }),
  })

  const handleDeleteAssignment = useCallback(
    (assignmentId: string) => {
      if (window.confirm('Are you sure you want to delete this assignment?')) {
        deleteLessonMutation.mutate(assignmentId)
      }
    },
    [deleteLessonMutation]
  )

  const lessonAssignments = useMemo(() => {
    if (!lessonAssignmentsResults) return []
    const results = lessonAssignmentsResults?.[0]?.lessonAssignments

    return results
      ?.filter((assignment) => assignment.lessonId === lessonId && Boolean(assignment.completedAt))
      .sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime())
  }, [lessonAssignmentsResults, lessonId])

  const isStudentLoading = isLoading || isFetching
  const isAssignmentLoading = isLoadingLessonAssignmentsResults || isFetchingLessonAssignmentsResults

  return (
    <AdminLayout title="Assessment Details" backUrl={`/admin/students/${id}${queryString}`}>
      <div className="flex min-h-full flex-col p-8 pt-24">
        <div className="text-gray-600">
          <HeaderDetails
            loading={isStudentLoading}
            name={student?.name}
            email={student?.email}
            grade={student?.grade}
            assessment={LessonIds[lessonId as LessonId]}
          />
        </div>
        {isAssignmentLoading ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <LoadingWrapper>
              <LoadingIcon />
            </LoadingWrapper>
          </div>
        ) : (
          <div className="mt-16">
            <section className="flex flex-col gap-8">
              <div>
                <h3 className="mb-4 text-lg font-semibold">Assessment Score</h3>

                {[
                  'letter-naming-correspondence',
                  'letter-naming-correspondence-no-highlight',
                  'letter-naming-no-highlight',
                  'letter-naming-fluency',
                  'letter-naming-new-highlight',
                  'letter-naming-fluency-with-steps',
                  'letter-naming-correspondence-with-steps',
                ].includes(lessonId) && (
                  <LetterNamingFluencyScore
                    filteredAssessments={lessonAssignments as LessonAssignment<LetterNamingLessonIds>[]}
                  />
                )}

                {['first-sound-fluency', 'first-sound-fluency-no-timer'].includes(lessonId) && (
                  <FirstSoundFluencyScore
                    filteredAssessments={lessonAssignments as LessonAssignment<'first-sound-fluency'>[]}
                  />
                )}

                {lessonId === 'letter-sound-completions-fluency' && (
                  <LetterSoundCompletionFluencyScore
                    filteredAssessments={lessonAssignments as LessonAssignment<'letter-sound-completions-fluency'>[]}
                  />
                )}

                {lessonId === 'oral-reading-fluency' && (
                  <OralReadingFluencyScore
                    filteredAssessments={lessonAssignments as LessonAssignment<'oral-reading-fluency'>[]}
                  />
                )}

                {lessonId === 'maze' && (
                  <MazeScore filteredAssessments={lessonAssignments as LessonAssignment<'maze'>[]} />
                )}

                {lessonId === 'phoneme-segmentation-fluency' && (
                  <PhonemeSegmentationScore
                    filteredAssessments={lessonAssignments as LessonAssignment<'phoneme-segmentation-fluency'>[]}
                  />
                )}
              </div>

              <Card className="mt-8">
                <CardHeader>
                  <CardTitle>Completed Assessments</CardTitle>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead>Assessment Name</TableHead>
                        <TableHead>Completed Date</TableHead>
                        <TableHead className="text-right">Actions</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {isLoading || isFetching ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <div className="flex h-24 items-center justify-center">
                              <LoadingIcon />
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : lessonAssignments?.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <div className="flex h-24 items-center justify-center text-sm text-gray-500">
                              No assessments found
                            </div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        lessonAssignments?.map((assignment) => (
                          <TableRow
                            key={assignment.id}
                            className="hover:bg-gray-50"
                            onMouseEnter={() => prefetchAssessmengScores(assignment.id)}
                          >
                            <TableCell className="">{assignment?.lesson?.title}</TableCell>
                            <TableCell className="">{new Date(assignment.completedAt).toLocaleString()}</TableCell>
                            <TableCell className="text-right">
                              <div className="flex justify-end gap-2">
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  onClick={() => {
                                    navigate(
                                      `/admin/students/${id}/${kebabToCamelCase(assignment.lessonId)}/${
                                        assignment.id
                                      }${queryString}`
                                    )
                                  }}
                                  title="View assessment scores"
                                >
                                  <Eye className="size-4" />
                                </Button>

                                <Button
                                  variant="ghost"
                                  size="icon"
                                  onClick={() => handleDeleteAssignment(assignment.id)}
                                  className="text-destructive hover:text-destructive"
                                  title="Delete assessment"
                                >
                                  <Trash2 className="size-4" />
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </section>
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

export default StudentLessonDetails
