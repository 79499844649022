// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export interface Source {
  word: string
  PronunciationAssessment: {
    AccuracyScore: number
    ErrorType: string
  }
}

type ResultItem = {
  letter: string
  operation: 'MATCH' | 'SUBSTITUTE' | 'DELETE' | 'INSERT'
  saidLetter?: string
}

export default function calcSequenceResults({ source, target }: { source: Source[]; target: string[] }) {
  // Function to compute edit distance matrix with operations
  function computeEditDistanceMatrix(source: string[], target: Source[]) {
    const matrix = Array(source.length + 1)
      .fill()
      .map(() =>
        Array(target.length + 1)
          .fill()
          .map(() => ({ distance: 0, operation: '' }))
      )
    // Initialize first row and column
    for (let i = 0; i <= source.length; i++) {
      matrix[i][0] = { distance: i, operation: i > 0 ? 'DELETE' : '' }
    }
    for (let j = 0; j <= target.length; j++) {
      matrix[0][j] = { distance: j, operation: j > 0 ? 'INSERT' : '' }
    }
    // Fill the matrix
    for (let i = 1; i <= source.length; i++) {
      for (let j = 1; j <= target.length; j++) {
        if (source[i - 1] === target[j - 1].word) {
          matrix[i][j] = {
            distance: matrix[i - 1][j - 1].distance,
            operation: 'MATCH',
          }
        } else {
          const substitution = matrix[i - 1][j - 1].distance + 1
          const deletion = matrix[i - 1][j].distance + 1
          const insertion = matrix[i][j - 1].distance + 1
          const minDist = Math.min(substitution, deletion, insertion)
          let operation
          if (minDist === substitution) operation = 'SUBSTITUTE'
          else if (minDist === deletion) operation = 'DELETE'
          else operation = 'INSERT'
          matrix[i][j] = { distance: minDist, operation }
        }
      }
    }
    return matrix
  }
  // Function to backtrack and find all operations
  function getEditOperations(matrix: any, source: string[], target: Source[]) {
    let i = source.length
    let j = target.length
    const operations = []
    while (i > 0 || j > 0) {
      const current = matrix[i][j]
      const operation = current.operation
      if (operation === 'MATCH' || operation === 'SUBSTITUTE') {
        operations.unshift({
          type: operation,
          sourceChar: source[i - 1],
          targetChar: target[j - 1].word,
          position: j - 1,
          pronunciation: target[j - 1]?.PronunciationAssessment,
        })
        i--
        j--
      } else if (operation === 'DELETE') {
        operations.unshift({
          type: 'DELETE',
          sourceChar: source[i - 1],
          position: j,
          pronunciation: target[j - 1]?.PronunciationAssessment,
        })
        i--
      } else if (operation === 'INSERT') {
        operations.unshift({
          type: 'INSERT',
          targetChar: target[j - 1].word,
          position: j - 1,
          pronunciation: target[j - 1]?.PronunciationAssessment,
        })
        j--
      }
    }
    return operations
  }
  const matrix = computeEditDistanceMatrix(target, source)
  const operations = getEditOperations(matrix, target, source)

  const results: ResultItem[] = []
  operations.forEach((op, _index) => {
    switch (op.type) {
      case 'MATCH':
        results.push({
          letter: op.sourceChar!,
          operation: 'MATCH',
          pronunciation: op.pronunciation,
          score: op.pronunciation,
        })

        break
      case 'SUBSTITUTE':
        results.push({
          letter: op.sourceChar!,
          operation: 'SUBSTITUTE',
          saidLetter: op.targetChar,
          pronunciation: op.pronunciation,
          score: op.pronunciation,
        })

        break
      case 'DELETE':
        results.push({
          letter: op.sourceChar!,
          operation: 'DELETE',
          pronunciation: op.pronunciation,
          score: op.pronunciation,
        })

        break
      case 'INSERT':
        results.push({
          letter: op.targetChar!,
          operation: 'INSERT',
          pronunciation: op.pronunciation,
          score: op.pronunciation,
        })

        break
    }
  })

  return results
}
