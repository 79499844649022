import { Invitation } from '@alpha-school/reading-fluency-models'

import { LoadingIcon } from '@/components/Loading/Loading'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { getStudentInvitations } from '@/utils/api'
import { revokeStudentInvitation } from '@/utils/api'

interface InvitationsTableProps {
  invitations: Invitation[]
  isLoading: boolean
  setInvitations: (invitations: Invitation[]) => void
}

export default function InvitationsTable({ invitations, isLoading, setInvitations }: InvitationsTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Email</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Created At</TableHead>
          <TableHead>Updated At</TableHead>
          <TableHead className="text-right">Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <TableRow>
            <TableCell colSpan={5}>
              <div className="flex h-24 items-center justify-center">
                <LoadingIcon />
              </div>
            </TableCell>
          </TableRow>
        ) : invitations.length === 0 ? (
          <TableRow>
            <TableCell colSpan={5}>
              <div className="flex h-24 items-center justify-center text-sm text-gray-500">No invitations found</div>
            </TableCell>
          </TableRow>
        ) : (
          invitations?.map((invitation) => (
            <TableRow key={invitation.id} className="hover:bg-gray-50">
              <TableCell className="whitespace-nowrap px-6 py-4">{invitation.emailAddress}</TableCell>
              <TableCell className="whitespace-nowrap px-6 py-4 capitalize">{invitation.status}</TableCell>
              <TableCell className="whitespace-nowrap px-6 py-4">
                {new Date(invitation.createdAt).toLocaleString()}
              </TableCell>
              <TableCell className="whitespace-nowrap px-6 py-4">
                {new Date(invitation.updatedAt).toLocaleString()}
              </TableCell>
              <TableCell className="whitespace-nowrap px-6 py-4 text-right">
                {invitation.status === 'pending' && (
                  <Button
                    variant="destructive"
                    onClick={async () => {
                      await revokeStudentInvitation(invitation.id)
                      getStudentInvitations().then(({ data }) => {
                        setInvitations(data)
                      })
                    }}
                  >
                    Revoke
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  )
}
