import { useMicVAD } from '@ricky0123/vad-react'
import { forwardRef, useImperativeHandle } from 'react'

export interface VADRef {
  start: () => void
  stop: () => void
  listening: boolean
}

interface VADComponentProps {
  onSpeechStart: () => void
  onSpeechEnd: () => void
}

export const VADComponent = forwardRef<VADRef, VADComponentProps>(({ onSpeechStart, onSpeechEnd }, ref) => {
  const vad = useMicVAD({
    positiveSpeechThreshold: 0.9,
    minSpeechFrames: 1,
    preSpeechPadFrames: 0,
    submitUserSpeechOnPause: true,
    model: 'legacy',
    startOnLoad: false,
    redemptionFrames: 0,
    onSpeechStart,
    onSpeechEnd,
  })

  useImperativeHandle(ref, () => ({
    start: vad.start,
    stop: vad.pause,
    listening: vad.listening,
  }))

  return null
})

VADComponent.displayName = 'VADComponent'
