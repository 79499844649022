import homeIconSvg from '../../assets/homeIcon.svg'

export default function GoHomeButton() {
  const queryString = location.search
  const goHome = () => {
    window.location.href = `/${queryString}`
  }

  return (
    <button
      onClick={goHome}
      className="flex animate-scale-pulse items-center justify-center rounded-3xl border-b-8 border-none border-blue-600 bg-blue-500 px-6 py-4 text-white shadow-xl transition-transform hover:translate-y-1 active:translate-y-2"
    >
      <img src={homeIconSvg} />
    </button>
  )
}
