/* eslint-disable no-useless-escape */
export function cleanString(input = ''): string {
  const output = input
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()?\[\]]/g, '')
    .replace(/\s{2,}/g, ' ')
    .replace(/\"/g, '')
    .replace(/\'/g, '')
    .toLowerCase()

  return output
}

export function getAllIndexesOfWord({ lessonPhraseWords, word }: { lessonPhraseWords: any[]; word: any }): any {
  const indexes: number[] = []

  lessonPhraseWords.forEach((currentWord, index) => {
    if (currentWord === word) {
      indexes.push(index)
    }
  })

  return indexes
}

export function recognizerEdgeCases(word: string) {
  switch (word) {
    case '0':
      return 'o'
    default:
      return word
  }
}

export function numberToWords(sentence: string): string {
  const numberWords = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

  return sentence.replace(/\b\d+\b/g, (match: any) => {
    const num = parseInt(match, 10)
    return num <= 100 ? numberWords[num] : match
  })
}

export function convertOrdinal(sentence: string): string {
  switch (sentence) {
    case '1st':
      return 'first'
    case '2nd':
      return 'second'
    case '3rd':
      return 'third'
    case '4th':
      return 'fourth'
    case '5th':
      return 'fifth'
    case '6th':
      return 'sixth'
    case '7th':
      return 'seventh'
    case '8th':
      return 'eighth'
    case '9th':
      return 'ninth'
    case '10th':
      return 'tenth'
  }

  return sentence
}

export const getLastOccurrence = ({ phrase, word, remainingWords, indexes }: any) => {
  const amountOfRepeatingWordOnOriginalPhrase = phrase.split(' ').filter((phraseWord: string) => {
    return phraseWord === word
  }).length

  const amountOfRepeatingWordOnRemainingWords = remainingWords.filter((remainingWord: string) => {
    return remainingWord === word
  }).length

  const delta = amountOfRepeatingWordOnOriginalPhrase - amountOfRepeatingWordOnRemainingWords

  return indexes[delta]
}

export function reduceToOriginalPart(arrayOfArrays: any, originalPhrase: any) {
  const finalArray: any = []
  let currentIndex = 0

  arrayOfArrays.forEach((arr: any) => {
    arr.forEach((word: any) => {
      const wordIndex = originalPhrase.indexOf(word, currentIndex)

      // Ensure the word is in the correct sequence and has not already been added
      if (wordIndex !== -1 && wordIndex === currentIndex) {
        finalArray.push(word)
        currentIndex = wordIndex + word.length + 1 // Update currentIndex to move to the next word
      }
    })
  })

  return finalArray
}

export function transformCaseArray(letters: string[], type: 'upper' | 'lower' | 'mixed' = 'mixed'): string[] {
  return letters.map((letter) => {
    switch (type) {
      case 'upper':
        return letter.toUpperCase()
      case 'lower':
        return letter.toLowerCase()
      case 'mixed':
        return Math.random() < 0.5 ? letter.toLowerCase() : letter.toUpperCase()
      default:
        return letter
    }
  })
}

export function randomizeCase(letter: string): string {
  return Math.random() < 0.5 ? letter.toLowerCase() : letter.toUpperCase()
}

export function getRandomLetter() {
  return String.fromCharCode(Math.floor(Math.random() * 26) + 97)
}

export function getTextWidth(text: any, font = '16px Arial') {
  const canvas = document.createElement('canvas')
  const context: any = canvas.getContext('2d')

  context.font = font

  const metrics = context.measureText(text)

  return metrics.width
}

export function findLongestWord(data: any) {
  let longestWord = ''

  data.forEach((item: any) => {
    item.options.forEach((word: string) => {
      if (word.length > longestWord.length) {
        longestWord = word
      }
    })
  })

  return longestWord
}

export function generateRandomLettersArray(x: number, allowRepetition = false) {
  if (allowRepetition) {
    return Array.from({ length: x }, () => getRandomLetter()).map((letter) => randomizeCase(letter))
  }

  const result: string[] = []
  const usedLetters = new Set<string>()

  while (result.length < x) {
    const letter = getRandomLetter()
    // Check if letter exists in any case
    if (!usedLetters.has(letter.toLowerCase()) && !usedLetters.has(letter.toUpperCase())) {
      const randomizedLetter = randomizeCase(letter)
      result.push(randomizedLetter)
      usedLetters.add(letter.toLowerCase())
    }
  }

  return result
}
