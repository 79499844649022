import React, { useEffect } from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  isClosable?: boolean
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, isClosable }) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (!isClosable) return
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [onClose])

  if (!isOpen) return null

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === event.currentTarget) {
      if (!isClosable) return
      onClose()
    }
  }

  return (
    <div className="fixed inset-0 z-50 size-full overflow-y-auto bg-gray-600/50" onClick={handleOverlayClick}>
      <div className="relative top-20 mx-auto w-11/12 min-w-[80vw] rounded-[24px] bg-white shadow-lg sm:w-3/4 md:w-1/2 lg:w-1/3">
        {children}
        {isClosable && (
          <button
            onClick={() => {
              onClose()
            }}
            className="absolute right-0 top-0 ml-auto mr-2 mt-2 inline-flex items-center rounded-[24px] bg-transparent p-1.5 text-lg text-white hover:bg-gray-200 hover:text-black"
          >
            <svg
              className="size-6"
              fill="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}
      </div>
    </div>
  )
}

export default Modal
