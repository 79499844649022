import { useEffect, useRef } from 'react'

import closedMicPng from '@/assets/closedMic.png'
import openMicPng from '@/assets/openMic.png'
import { useAudioRecording } from '@/context/AudioRecordingContext/AudioRecordingContext'
import { useMasteryLesson } from '@/context/MasteryLessonContext/MasteryLessonContext'
import { cn } from '@/lib/utils'

import AudioVisualizer from '../AudioVisualizer'

interface TopBarProps {
  title: string
  showTitle?: boolean
}

function TopBar({ title, showTitle = true }: TopBarProps) {
  const { isRecording, mediaStream, showVisualizer } = useAudioRecording()
  const { progressPercentage, showProgress } = useMasteryLesson()
  const progressRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (progressRef.current && showProgress) {
      // Using Math.pow with 0.4 to make progress appear faster at the start
      const easedProgress = Math.pow(progressPercentage / 100, 0.4) * 100
      progressRef.current.style.width = `${easedProgress}%`
    }
  }, [progressPercentage, showProgress])

  return (
    <div
      className={cn(
        `relative mb-2 flex flex-col rounded-xl rounded-b-[24px] border-2 border-b-[12px]`,
        !isRecording || !showVisualizer ? 'border-[#3800D7] bg-purple-gradient' : 'border-bright-yellow'
      )}
    >
      {/* Main content container */}
      <div className="flex items-center justify-between px-2 pt-2">
        <div
          className={cn(
            `ml-4 mt-2 p-2 text-lg font-semibold text-white`,
            !isRecording || !showVisualizer ? 'border-[#b98dfd] bg-purple-light' : 'border-bright-yellow',
            showTitle && 'border rounded-xl'
          )}
        >
          {showTitle && <span>{title}</span>}
        </div>

        <div className="flex items-center justify-center p-2">
          <div className="flex items-center gap-2">
            {isRecording && (
              <AudioVisualizer isRecording={isRecording} stream={mediaStream || undefined} show={showVisualizer} />
            )}
            <div className="right-0 top-0 ml-4 size-[48px]">
              <img src={isRecording && showVisualizer ? closedMicPng : openMicPng} alt="microphone status" />
            </div>
          </div>
        </div>
      </div>

      {/* Progress bar container */}
      <div className="px-4 pb-4 pt-2">
        <div
          className={cn(
            'ml-[7px] relative h-4 overflow-hidden rounded-full border-2 border-purple-light/50',
            showProgress ? 'opacity-100' : 'opacity-0',
            'transition-opacity duration-300'
          )}
        >
          <div className="bg-purple-light/20 absolute inset-0" />
          <div
            ref={progressRef}
            className="h-full bg-progress-gradient transition-all duration-700 ease-out"
            style={{ width: '0%' }}
          />
        </div>
      </div>
    </div>
  )
}

export default TopBar
