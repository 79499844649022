import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import FirstSoundFluencyScore from '@/components/Admin/Dashboards/FirstSoundFluencyScore'
import HeaderDetails from '@/components/Admin/Dashboards/HeaderDetails'
import LetterNamingFluencyScore from '@/components/Admin/Dashboards/LetterNamingFluencyScore'
import LetterSoundFluencyScore from '@/components/Admin/Dashboards/LetterSoundFluencyScore'
import MazeScore from '@/components/Admin/Dashboards/MazeScore'
import OralFluencyScore from '@/components/Admin/Dashboards/OralReadingFluencyScore'
import PhonemeSegmentationScore from '@/components/Admin/Dashboards/PhonemeSegmentationFluencyScore'
import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { LoadingIcon, LoadingWrapper } from '@/components/Loading/Loading'
import { camelToKebabCase } from '@/lib/utils'
import { LessonAssignment, LessonId } from '@/types/lesson-assignment'
import { LessonIds } from '@/types/lesson-assignment'
import { LOCALE_OPTIONS } from '@/types/locale-option-type'
import { getLessonAssignmentResult, getStudentDetails } from '@/utils/api'

type LetterNamingLessonIds = Extract<
  LessonId,
  | 'letter-naming-correspondence'
  | 'letter-naming-correspondence-no-highlight'
  | 'letter-naming-no-highlight'
  | 'letter-naming-fluency'
  | 'letter-naming-new-highlight'
  | 'letter-naming-fluency-with-steps'
  | 'letter-naming-correspondence-with-steps'
>

function AssignmentDetails() {
  const queryString = window.location.search
  const {
    lessonId: lessonIdParam,
    id,
    assignmentId,
  } = useParams<{ lessonId: string; id: string; assignmentId: string }>()
  const lessonId = camelToKebabCase(lessonIdParam!) as LessonId

  const {
    data: student,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['student', id],
    queryFn: async () => {
      const { data } = await getStudentDetails(id!)
      return data
    },
  })

  const {
    data: lessonAssignmentResult,
    isLoading: isLoadingLessonAssignmentResult,
    isFetching: isFetchingLessonAssignmentResult,
  } = useQuery({
    queryKey: ['lesson-assignment-result', assignmentId],
    queryFn: async () => {
      const { data } = await getLessonAssignmentResult(assignmentId!)
      return data as LessonAssignment<LessonId> & { locale?: string }
    },
  })

  const lessonAssignment = useMemo(() => {
    return lessonAssignmentResult
  }, [lessonAssignmentResult])

  const locale = useMemo(() => {
    const locale = lessonAssignmentResult?.lesssonAssignmentResults?.[0]?.locale
    return LOCALE_OPTIONS.find((option) => option.value === locale)?.label
  }, [lessonAssignmentResult])

  const isStudentLoading = isLoading || isFetching || isLoadingLessonAssignmentResult
  const isAssignmentLoading = isFetchingLessonAssignmentResult || isLoadingLessonAssignmentResult
  console.log('lessonAssignment', lessonAssignment)
  console.log('lessonId', lessonId)
  return (
    <AdminLayout title="Individual Assessment Details" backUrl={`/admin/students/${id}/${lessonIdParam}${queryString}`}>
      <div className="flex min-h-full flex-col p-8 pt-24">
        <div className="text-gray-600">
          <HeaderDetails
            loading={isStudentLoading}
            name={student?.name}
            email={student?.email}
            grade={student?.grade}
            completedAt={lessonAssignment?.completedAt}
            locale={locale}
          />
        </div>
        {isAssignmentLoading ? (
          <div className="flex flex-1 flex-col items-center justify-center">
            <LoadingWrapper>
              <LoadingIcon />
            </LoadingWrapper>
          </div>
        ) : (
          <div className="mt-16">
            <section className="flex flex-col gap-8">
              <div>
                <h3 className="mb-4 text-lg font-semibold">{LessonIds[lessonId as LessonId]} Score</h3>

                {[
                  'letter-naming-correspondence',
                  'letter-naming-correspondence-no-highlight',
                  'letter-naming-no-highlight',
                  'letter-naming-new-highlight',
                  'letter-naming-fluency',
                  'letter-naming-fluency-with-steps',
                  'letter-naming-correspondence-with-steps',
                ].includes(lessonId) && (
                  <LetterNamingFluencyScore
                    filteredAssessments={[lessonAssignment as LessonAssignment<LetterNamingLessonIds>]}
                  />
                )}

                {['first-sound-fluency', 'first-sound-fluency-no-timer'].includes(lessonId) && (
                  <FirstSoundFluencyScore
                    filteredAssessments={[lessonAssignment as LessonAssignment<'first-sound-fluency'>]}
                  />
                )}

                {lessonId === 'letter-sound-completions-fluency' && (
                  <LetterSoundFluencyScore
                    filteredAssessments={[lessonAssignment as LessonAssignment<'letter-sound-completions-fluency'>]}
                  />
                )}

                {lessonId === 'oral-reading-fluency' && (
                  <OralFluencyScore
                    filteredAssessments={[lessonAssignment as LessonAssignment<'oral-reading-fluency'>]}
                  />
                )}

                {lessonId === 'maze' && (
                  <MazeScore filteredAssessments={[lessonAssignment as LessonAssignment<'maze'>]} />
                )}

                {lessonId === 'phoneme-segmentation-fluency' && (
                  <PhonemeSegmentationScore
                    filteredAssessments={[lessonAssignment as LessonAssignment<'phoneme-segmentation-fluency'>]}
                  />
                )}
              </div>
            </section>
          </div>
        )}
      </div>
    </AdminLayout>
  )
}

export default AssignmentDetails
