import { ArrowLeft } from 'lucide-react'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'

import Sidebar from '@/components/Admin/Sidebar'
import PageLayout from '@/components/PageLayout/PageLayout'
import { Button } from '@/components/ui/button'

interface AdminLayoutProps extends PropsWithChildren {
  title: string
  backUrl?: string
  headerAction?: React.ReactNode
}

export default function AdminLayout({ children, title, backUrl, headerAction }: AdminLayoutProps) {
  const navigate = useNavigate()
  return (
    <PageLayout className="flex">
      <div className="flex w-full">
        <Sidebar />
        <div className="max-h-screen flex-1 overflow-y-auto bg-gray-100">
          <div className="fixed right-0 top-0 z-10 flex h-[65px] w-[calc(100%-256px)] items-center justify-between border-b bg-white px-8 py-4">
            <div className="flex items-center gap-4">
              {backUrl && (
                <Button variant="outline" onClick={() => navigate(backUrl)} className="flex items-center gap-2">
                  <ArrowLeft className="size-4" />
                  <span>Back</span>
                </Button>
              )}
              <h1 className="text-2xl font-bold">{title}</h1>
            </div>
            {headerAction}
          </div>

          {children}
        </div>
      </div>
    </PageLayout>
  )
}
