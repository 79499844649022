import { Invitation } from '@alpha-school/reading-fluency-models'
import { Plus } from 'lucide-react'
import { useEffect, useState } from 'react'

import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import InvitationsTable from '@/pages/Admin/Invitations/InvitationsTable'
import { InviteUserModal } from '@/pages/Admin/Invitations/InviteUserModal'
import { getStudentInvitations } from '@/utils/api'

export default function Invitations() {
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const [showInviteModal, setShowInviteModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getStudentInvitations().then(({ data }) => {
      setInvitations(data)
      setIsLoading(false)
    })
  }, [])

  return (
    <AdminLayout
      title="Invitations"
      headerAction={
        <Button
          variant="primary-alt"
          className="flex items-center gap-2"
          type="button"
          onClick={() => setShowInviteModal(true)}
        >
          <Plus className="size-4" />
          Invite Student
        </Button>
      }
    >
      <div className="p-8 pt-24">
        <Card className="pt-6">
          <CardContent>
            <InvitationsTable invitations={invitations} isLoading={isLoading} setInvitations={setInvitations} />
          </CardContent>
        </Card>
      </div>

      <InviteUserModal
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        onSuccess={() => {
          getStudentInvitations().then(({ data }) => {
            setInvitations(data)
          })
        }}
      />
    </AdminLayout>
  )
}
