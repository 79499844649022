import { useEffect, useRef, useState } from 'react'

import { useAppConfig } from '@/context/AppConfig'

const slotsClass =
  'mx-2 p-2 rounded-md w-12 text-center cursor-pointer transition-all duration-200 ease-in-out transform hover:scale-105 hover:bg-green-300'

const slots = [0, 1, 2, 3, 4]

function ConfigPanel() {
  const { configs, currentConfigIndex, saveConfig, switchConfig } = useAppConfig()

  const [isExpanded, setIsExpanded] = useState(true)
  const panelRef = useRef<HTMLDivElement>(null)

  const togglePanel = () => setIsExpanded(!isExpanded)

  const handleClickOutside = (event: MouseEvent) => {
    if (panelRef.current && !panelRef.current.contains(event.target as Node)) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={panelRef}
      className="absolute left-[15px] top-[15px] z-50 max-w-[500px] rounded-lg bg-gray-700 p-4 shadow-lg"
    >
      <div className="flex cursor-pointer items-center justify-between" onClick={togglePanel}>
        <h1 className="flex items-center justify-center text-lg font-semibold text-white">
          Config Panel{' '}
          {!isExpanded && (
            <svg
              className="ms-3 size-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          )}
        </h1>
      </div>
      {isExpanded && (
        <div>
          <div className="mt-4 text-sm text-gray-400">
            These are config slots, so you can easily switch between configs.
          </div>
          <div className="mb-4 mt-2 flex  justify-between">
            {slots.map((slotIndex) => (
              <div
                key={slotIndex}
                className={`${slotsClass} ${
                  currentConfigIndex === slotIndex ? 'bg-green-500 text-white' : 'bg-white text-gray-700'
                }`}
                onClick={() => {
                  switchConfig(slotIndex)
                }}
              >
                {slotIndex + 1}
              </div>
            ))}
          </div>

          {configs.map((config, index) => (
            <div key={index} className={`${currentConfigIndex !== index ? 'hidden' : ''}`}>
              <span className="mb-4 block w-full border-b border-white text-white"></span>
              <span className="text-xl font-bold text-white ">
                Azure SDK Config
                <br />
              </span>

              <div className="mb-4 ml-4">
                <label className="text-white">
                  Pronunciation Score Threshold:
                  <input
                    type="number"
                    value={config.pronunciationScoreThreshold !== undefined ? config.pronunciationScoreThreshold : ''}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value === '') {
                        saveConfig({
                          config: {
                            ...config,
                            pronunciationScoreThreshold: undefined,
                          },
                          index,
                        })
                        return
                      }

                      const numericValue = Number(value)
                      if (numericValue >= 1) {
                        saveConfig({
                          config: {
                            ...config,
                            pronunciationScoreThreshold: numericValue,
                          },
                          index,
                        })
                      }
                    }}
                    className="ml-4 w-16 rounded bg-gray-200 p-2 text-black focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
                    min={0}
                    max={100}
                  />
                </label>
                <div className="text-sm text-gray-400">Value between 0 and 100</div>
                <div className="text-sm text-gray-400">
                  Any result BELLOW the threshold will be considered a mispronunciation.
                </div>
              </div>
              <div className="mb-4 ml-4">
                <label className="text-white">
                  Unexpected Breaks Threshold:
                  <input
                    type="number"
                    step="0.01"
                    max={30}
                    value={config.unexpectedBreakThreshold !== undefined ? config.unexpectedBreakThreshold : ''}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value === '') {
                        saveConfig({
                          config: {
                            ...config,
                            unexpectedBreakThreshold: undefined,
                          },
                          index,
                        })
                        return
                      }

                      const numericValue = Number(value)
                      if (numericValue >= 1) {
                        saveConfig({
                          config: {
                            ...config,
                            unexpectedBreakThreshold: numericValue,
                          },
                          index,
                        })
                      }
                    }}
                    className="ml-4 w-16 rounded bg-gray-200 p-2 text-black focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
                  />
                </label>
                <div className="text-sm text-gray-400">Value between 0.01 and 30</div>
                <div className="text-sm text-gray-400">
                  Any result ABOVE the threshold will be considered an unexpected break.
                </div>
              </div>
              <div className="mb-4 ml-4">
                <label className="text-white">
                  Use Azure Pronunciation:
                  <input
                    type="checkbox"
                    checked={config.useAzureMispronunciationFlag}
                    onChange={(e) => {
                      saveConfig({
                        config: {
                          ...config,
                          useAzureMispronunciationFlag: e.target.checked,
                        },
                        index,
                      })
                    }}
                    className="ml-4 scale-125 focus:outline-none focus:ring-2 focus:ring-green-500"
                  />
                </label>
                <div className="mb-1 text-sm text-gray-400">Account for what Azure thinks is a mispronunciation.</div>
              </div>

              <div className="mb-4 ml-4">
                <label className="text-white">
                  Minimum Time Between Recognition (ms):
                  <input
                    type="number"
                    value={config.minTimeBetweenRecognition !== undefined ? config.minTimeBetweenRecognition : ''}
                    onChange={(e) => {
                      const value = e.target.value
                      if (value === '') {
                        saveConfig({
                          config: {
                            ...config,
                            minTimeBetweenRecognition: undefined,
                          },
                          index,
                        })
                        return
                      }

                      const numericValue = Number(value)
                      if (numericValue >= 0) {
                        saveConfig({
                          config: {
                            ...config,
                            minTimeBetweenRecognition: numericValue,
                          },
                          index,
                        })
                      }
                    }}
                    className="ml-4 w-24 rounded bg-gray-200 p-2 text-black focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
                    min={0}
                  />
                </label>
                <div className="text-sm text-gray-400">
                  Minimum time in milliseconds between speech recognition attempts
                </div>
              </div>

              <span className="mb-4 block w-full border-b border-white text-white"></span>
              <span className="mt-4 text-xl font-bold text-white">Openai Realtime SDK Config</span>
              <div className="mb-4 ml-4 mt-2">
                <label className="text-white">
                  Temperature:
                  <input
                    step="0.1"
                    value={
                      config?.openaiRealtimeConfig?.temperature !== undefined
                        ? config.openaiRealtimeConfig.temperature
                        : 0.8
                    }
                    onChange={(e) => {
                      const value = e.target.value

                      saveConfig({
                        config: {
                          ...config,
                          openaiRealtimeConfig: {
                            temperature: value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'),
                          },
                        },
                        index,
                      })
                      // }
                    }}
                    className="ml-4 w-16 rounded bg-gray-200 p-2 text-black focus:bg-white focus:outline-none focus:ring-2 focus:ring-green-500"
                    min={0}
                    max={100}
                  />
                </label>
                <div className="my-1 text-sm text-gray-400">
                  Dictates how creative the AI is with the results, the bigger the number, the bigger the randomness.
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ConfigPanel
