import { useAuth as useClerkAuth, useOrganizationList } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'
import { PropsWithChildren, useEffect, useState } from 'react'

export default function OrganizationLoader({ children }: PropsWithChildren) {
  const { setActive, isLoaded } = useOrganizationList()
  const { userId } = useClerkAuth()
  const [isOrgLoaded, setIsOrgLoaded] = useState(false)

  useEffect(() => {
    if (!isLoaded || !userId) {
      setIsOrgLoaded(true)
      return
    }

    const setOrg = async () => {
      try {
        await setActive({ organization: import.meta.env.VITE_CLERK_ORGANIZATION_ID })
        setIsOrgLoaded(true)
      } catch (error) {
        Sentry.captureException(error)
        console.error('Error setting organization:', error)
        setIsOrgLoaded(true)
      }
    }

    void setOrg()
  }, [isLoaded, setActive, userId])

  if (!isOrgLoaded) {
    return null
  }

  return <>{children}</>
}
