import { lazy } from 'react'

import {
  COLUMNS_EXERCISE,
  FIRST_SOUND_FLUENCY_EXERCISE,
  FIRST_SOUND_FLUENCY_NO_TIMER_EXERCISE,
  LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE,
  LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_EXERCISE,
  LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_EXERCISE,
  LETTER_NAMING_FLUENCY_EXERCISE,
  LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_EXERCISE,
  LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_EXERCISE,
  LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE,
  MAZE_EXERCISE,
  ORAL_READING_FLUENCY_EXERCISE,
  PHONEME_SEGMENTATION_EXERCISE,
  PICTURES_EXERCISE,
} from './constants'

const ColumnsExercise = lazy(() => import('@/components/Exercises/ColumnsExercise'))
const PicturesExercise = lazy(() => import('@/components/Exercises/PicturesExercise'))
const FirstSoundFluencyExercise = lazy(() => import('@/components/Exercises/FirstSoundFluencyExercise'))
const LetterNamingFluencyExercise = lazy(() => import('@/components/Exercises/LetterNamingFluencyExercise'))
const LetterNamingFluencyCorrespondenceExercise = lazy(
  () => import('@/components/Exercises/LetterNamingFluencyCorrespondenceExercise')
)
const LetterSoundFluencyCompletionsExercise = lazy(
  () => import('@/components/Exercises/LetterSoundFluencyCompletionsExercise')
)
const OralReadingFluencyExercise = lazy(() => import('@/components/Exercises/OralReadingFluencyExercise'))
const MazeExercise = lazy(() => import('@/components/Exercises/MazeExercise'))
const PhonemeSegmentationExercise = lazy(() => import('@/components/Exercises/PhonemeSegmentationExercise'))
const LetterNamingFluencyCorrespondenceNoHighlightExercise = lazy(
  () => import('@/components/Exercises/LetterNamingFluencyCorrespondenceNoHighlightExercise')
)
const LetterNamingFluencyNoHighlightExercise = lazy(
  () => import('@/components/Exercises/LetterNamingFluencyNoHighlightExercise')
)
const LetterNamingFluencyNewHighlightExercise = lazy(
  () => import('@/components/Exercises/LetterNamingFluencyNewHighlightExercise')
)
const FirstSoundFluencyNoTimerExercise = lazy(() => import('@/components/Exercises/FirstSoundFluencyNoTimerExercise'))
const LetterNamingFluencyCorrespondenceWithStepsExercise = lazy(
  () => import('@/components/Exercises/LetterNamingFluencyCorrespondenceWithStepsExercise')
)

const componentsMap: Record<string, React.LazyExoticComponent<any>> = {
  [COLUMNS_EXERCISE]: ColumnsExercise,
  [PICTURES_EXERCISE]: PicturesExercise,
  [FIRST_SOUND_FLUENCY_EXERCISE]: FirstSoundFluencyExercise,
  [LETTER_NAMING_FLUENCY_EXERCISE]: LetterNamingFluencyExercise,
  [LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE]: LetterNamingFluencyCorrespondenceExercise,
  [LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE]: LetterSoundFluencyCompletionsExercise,
  [ORAL_READING_FLUENCY_EXERCISE]: OralReadingFluencyExercise,
  [MAZE_EXERCISE]: MazeExercise,
  [PHONEME_SEGMENTATION_EXERCISE]: PhonemeSegmentationExercise,
  [LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_EXERCISE]: LetterNamingFluencyCorrespondenceNoHighlightExercise,
  [LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_EXERCISE]: LetterNamingFluencyNoHighlightExercise,
  [FIRST_SOUND_FLUENCY_NO_TIMER_EXERCISE]: FirstSoundFluencyNoTimerExercise,
  [LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_EXERCISE]: LetterNamingFluencyNewHighlightExercise,
  [LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_EXERCISE]: LetterNamingFluencyCorrespondenceWithStepsExercise,
}

export default componentsMap
