import { useMemo } from 'react'

import { useAppConfig } from '@/context/AppConfig'
import { getFirstSoundFluencyScores } from '@/lib/parsers/first-sound-fluency'
import { getLetterNamingFluencyScores } from '@/lib/parsers/letter-naming-fluency'
import { getPhonemeSegmentationFluencyScores } from '@/lib/parsers/phoneme-segmentation-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'
import { StudentDetailsResponse } from '@/types/student'

import { GradeBenchmark } from './GradeBenchmark'
import { PeriodsBenchmark } from './types/periods'

export function KindergartenBenchmark({ student }: { student: StudentDetailsResponse }) {
  const { currentConfig } = useAppConfig()

  const letterNamingFluencyScore = useMemo(() => {
    return getLetterNamingFluencyScores(
      student.lessonAssignments.filter((assignment) =>
        [
          'letter-naming-fluency',
          'letter-naming-no-highlight',
          'letter-naming-new-highlight',
          'letter-naming-fluency-with-steps',
        ].includes(assignment.lessonId)
      ) as LessonAssignment<'letter-naming-fluency'>[],
      currentConfig.pronunciationScoreThreshold!,
      currentConfig.useAzureMispronunciationFlag!
    )
  }, [student.lessonAssignments, currentConfig.pronunciationScoreThreshold, currentConfig.useAzureMispronunciationFlag])

  const firstSoundFluencyScore = useMemo(() => {
    return getFirstSoundFluencyScores(
      student.lessonAssignments.filter((assignment) =>
        ['first-sound-fluency', 'first-sound-fluency-no-timer'].includes(assignment.lessonId)
      ) as LessonAssignment<'first-sound-fluency'>[],
      currentConfig.pronunciationScoreThreshold!
    )
  }, [student.lessonAssignments, currentConfig.pronunciationScoreThreshold])

  const phonemeSegmentationScore = useMemo(() => {
    return getPhonemeSegmentationFluencyScores(
      student.lessonAssignments.filter(
        (assignment) => assignment.lessonId === 'phoneme-segmentation-fluency'
      ) as LessonAssignment<'phoneme-segmentation-fluency'>[],
      currentConfig.pronunciationScoreThreshold!
    )
  }, [student.lessonAssignments, currentConfig.pronunciationScoreThreshold])

  const benchmark = useMemo<PeriodsBenchmark>(() => {
    const beginningDate = [firstSoundFluencyScore.beginner?.date, letterNamingFluencyScore.beginner?.date].sort()
    const middleDate = [
      firstSoundFluencyScore.middle?.date,
      letterNamingFluencyScore.middle?.date,
      phonemeSegmentationScore.middle?.date,
    ].sort()
    const endDate = [firstSoundFluencyScore.end?.date, letterNamingFluencyScore.end?.date].sort()

    const beginningScore =
      (letterNamingFluencyScore.beginner?.score ?? 0) + (firstSoundFluencyScore.beginner?.score ?? 0)

    const middleScore =
      (letterNamingFluencyScore.middle?.score ?? 0) +
      (firstSoundFluencyScore.middle?.score ?? 0) +
      (phonemeSegmentationScore.middle?.score ?? 0)

    const endScore = (letterNamingFluencyScore.end?.score ?? 0) + (phonemeSegmentationScore.end?.score ?? 0)

    return {
      beginning: {
        date: beginningDate[beginningDate.length - 1],
        thresholds: {
          blue: 38,
          green: 26,
          yellow: 13,
          red: 0,
        },
        score: beginningScore,
      },
      middle: {
        date: middleDate[middleDate.length - 1],
        thresholds: {
          blue: 156,
          green: 122,
          yellow: 85,
          red: 0,
        },
        score: beginningScore + middleScore,
      },
      end: {
        date: endDate[endDate.length - 1],
        thresholds: {
          blue: 152,
          green: 119,
          yellow: 89,
          red: 0,
        },
        score: beginningScore + middleScore + endScore,
      },
    }
  }, [letterNamingFluencyScore, firstSoundFluencyScore, phonemeSegmentationScore])

  return <GradeBenchmark benchmark={benchmark} />
}
