import {
  PhonemeSegmentationFluencyAnalysis,
  PhonemeSegmentationFluencyResult,
} from '@/types/assessments/phoneme-segmentation-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'

import { getPartialCompositeScores } from './parsers-utils'
import { PartialCompositeScore } from './types/composite-score'

export function getPhonemeSegmentationFluencyScores(
  assessmentResults: LessonAssignment<'phoneme-segmentation-fluency'>[],
  pronunciationScoreThreshold: number
): PartialCompositeScore {
  return getPartialCompositeScores(
    assessmentResults,
    parsePhonemeSegmentationFluency,
    pronunciationScoreThreshold,
    false
  )
}

export function parsePhonemeSegmentationFluency(
  assessmentResult: PhonemeSegmentationFluencyResult
): PhonemeSegmentationFluencyAnalysis {
  const analysis: PhonemeSegmentationFluencyAnalysis = {
    correctCount: 0,
    incorrectCount: 0,
    correctPhonemes: [],
    missedPhonemes: [],
    commonlyMissedPhonemes: [],
    totalScore: 0,
  }

  const exercise = assessmentResult.exercises['0']
  const activity = exercise.activities['0']

  activity.results.forEach((result) => {
    if (result.api_error) {
      return
    }

    const isCorrect = result.correct_match_in_top_1
    const phonemeData = {
      phoneme: result.reference_phoneme,
      matchQuality: result.reference_phoneme_match_quality * 100,
      topMatches: result.top_matches,
      points: isCorrect ? 1 : 0,
    }

    if (isCorrect) {
      analysis.correctCount++
      analysis.totalScore += 1
      analysis.correctPhonemes.push(phonemeData)
    } else {
      analysis.incorrectCount++
      analysis.missedPhonemes.push(phonemeData)
      analysis.commonlyMissedPhonemes.push(phonemeData)
    }
  })

  // Sort commonly missed phonemes by match quality (ascending)
  analysis.commonlyMissedPhonemes.sort((a, b) => a.matchQuality - b.matchQuality)

  return analysis
}
