import { Role } from '@alpha-school/reading-fluency-models'
import { useAuth, UserButton } from '@clerk/clerk-react'
import { ChartColumnBigIcon } from 'lucide-react'
import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import homeIcon from '@/assets/homeIcon.svg'
import { cn } from '@/lib/utils'

function PageLayout({ children, className }: { children: React.ReactNode; className?: string }) {
  const location = useLocation()
  const navigate = useNavigate()
  const queryString = window.location.search
  const { orgRole } = useAuth()

  const canAccessAdminPanel = useMemo(
    () => [Role.ADMIN, Role.FLUENCY_ADMIN, Role.TEACHER].includes(orgRole as Role),
    [orgRole]
  )

  return (
    <div className={cn('text-black min-h-[100vh] min-w-[100vw] flex flex-col items-center', className)}>
      <div
        className={cn(`flex items-center p-4 z-[100]`, {
          'absolute top-8 right-[280px] justify-end': location.pathname === '/',
          'absolute top-0 right-0 justify-end': !location.pathname.startsWith('/admin') && location.pathname !== '/',
          'fixed bg-[#5e1bfe] top-0 left-0 w-[256px] h-[64px] justify-between': location.pathname.startsWith('/admin'),
        })}
      >
        {canAccessAdminPanel && !location.pathname.startsWith('/admin') && (
          <span
            onClick={() => navigate(`/admin/dashboard${queryString}`)}
            className="mr-4 flex cursor-pointer items-center gap-2 text-sm text-white"
          >
            <ChartColumnBigIcon className="size-4" />
            Admin
          </span>
        )}

        {location.pathname.startsWith('/admin') && (
          <img
            src={homeIcon}
            alt="Home"
            onClick={() => navigate(`/${queryString}`)}
            className="mr-2 size-6 cursor-pointer transition-opacity hover:opacity-80"
            title="Go to Home"
          />
        )}

        <UserButton
          showName
          appearance={{
            elements: {
              userButtonOuterIdentifier: {
                color: 'white',
              },
            },
          }}
        />
      </div>
      {children}
    </div>
  )
}

export default PageLayout
