import * as tf from '@tensorflow/tfjs'
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { VADComponent, type VADRef } from './VADComponent'

interface InitStatus {
  audio: boolean
  video: boolean
  faceDetector: boolean
}

interface MouthMetrics {
  mouth: {
    opening: number
    change: number
    jawChange: number
    teethChange: number
    roundness: number
    upperLipToLowerTeeth: number
  }
  voice: boolean
  isQuiet: boolean
  thresholds: {
    open: number
    jaw: number
    teeth: number
    change: number
    roundness: number
    lipContact: number
  }
}

interface Factors {
  mouthOpening: number
  jawMovement: number
  teethVisibility: number
  totalChange: number
  roundness: number
  lipContact: number
  voice: number
}

interface IMouthReadingContext {
  nextWordIndex: number
  setNextWordIndex: React.Dispatch<React.SetStateAction<number>>
  isSystemReady: boolean
  mouthReadingStarted: boolean
  startMouthReading: () => void
  stopMouthReading: () => void
  isLookingAway: boolean
  startListening: () => void
  stopListening: () => void
}

const MouthReadingContext = createContext<IMouthReadingContext | undefined>(undefined)

interface MouthReadingProviderProps {
  children: ReactNode
}

export const MouthReadingProvider: React.FC<MouthReadingProviderProps> = ({ children }) => {
  const [mouthReadingStarted, setMouthReadingStarted] = useState(false)
  const [nextWordIndex, setNextWordIndex] = useState(0)
  const [isLookingAway, setIsLookingAway] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [detector, setDetector] = useState<faceLandmarksDetection.FaceLandmarksDetector | null>(null)
  const isProcessing = useRef(false)
  const baseChangeThreshold = 3 // Base threshold for shape change
  const baseOpenThreshold = 8 // Base threshold for mouth opening
  const baseJawThreshold = 2 // Base threshold for jaw movement
  const baseTeethThreshold = 2 // Base threshold for teeth visibility changes
  const baseRoundnessThreshold = 0.3 // Base threshold for lip roundness (o, u, q)
  const baseLipContactThreshold = 2 // Base threshold for lip contact (specific for 'F' sound)
  const lastChangeTime = useRef(0)
  const FACE_WIDTH_REFERENCE = 300 // Reference width for base thresholds
  const vadIsListening = useRef(false)

  const vadRef = useRef<VADRef>(null)

  // Add refs for tracking mouth changes
  const previousMouthShape = useRef({
    height: 0,
    width: 0,
    jawPosition: 0,
    teethVisibility: 0,
  })

  const isQuiet = useRef(true)
  const voiceDetected = useRef(false)

  const [initStatus, setInitStatus] = useState<InitStatus>({
    audio: false,
    video: false,
    faceDetector: false,
  })

  // Add warm-up state
  const isWarmedUp = useRef(false)
  const warmupStartTime = useRef<number | null>(null)
  const WARMUP_PERIOD = 1500 // 1.5 seconds warmup

  // Initialize TensorFlow and face detector
  useEffect(() => {
    const initDetector = async () => {
      try {
        console.log('Initializing TensorFlow...')
        await tf.setBackend('webgl')
        console.log('Backend set to:', tf.getBackend())

        const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh
        console.log('Creating detector with model:', model)

        const detector = await faceLandmarksDetection.createDetector(model, {
          runtime: 'tfjs',
          refineLandmarks: true,
          maxFaces: 1,
        })

        console.log('Detector created successfully')
        setDetector(detector)
        setInitStatus((prev) => ({ ...prev, faceDetector: true }))
      } catch (error) {
        console.error('Error initializing detector:', error)
      }
    }

    if (mouthReadingStarted && !detector) {
      initDetector()
    }

    // Add cleanup function
    return () => {
      if (detector) {
        detector.dispose()
        setDetector(null)
        setInitStatus((prev) => ({ ...prev, faceDetector: false }))
      }
    }
  }, [mouthReadingStarted])

  // Initialize camera
  useEffect(() => {
    const setupCamera = async () => {
      try {
        console.log('Setting up camera...')
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1080, height: 720, facingMode: 'user' },
        })

        if (videoRef.current) {
          videoRef.current.srcObject = stream
          await new Promise<void>((resolve) => {
            if (videoRef.current) {
              videoRef.current.onloadeddata = () => {
                console.log('Video loaded')
                resolve()
              }
            }
          })
          await videoRef.current.play()
          console.log('Video playing')
          setInitStatus((prev) => ({ ...prev, video: true }))
        }
      } catch (error) {
        console.error('Camera setup error:', error)
      }
    }

    if (mouthReadingStarted) {
      setupCamera()
    }

    return () => {
      if (videoRef.current?.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks()
        tracks.forEach((track) => track.stop())
        setInitStatus((prev) => ({ ...prev, video: false }))
      }
    }
  }, [mouthReadingStarted])

  // Add status logging
  useEffect(() => {
    console.log('Initialization status:', initStatus)
  }, [initStatus.audio, initStatus.faceDetector, initStatus.video])

  // Optional: Add a check for all systems ready
  const isSystemReady = Object.values(initStatus).every((status) => status) && isWarmedUp.current

  // Add confidence score calculation
  const calculateSpeechConfidence = (
    metrics: MouthMetrics
  ): {
    confidence: number
    factors: Factors
  } => {
    const factors: Factors = {
      mouthOpening: 0,
      jawMovement: 0,
      teethVisibility: 0,
      totalChange: 0,
      roundness: 0,
      lipContact: 0,
      voice: 0,
    }

    // Basic factors
    factors.mouthOpening = Math.min(metrics.mouth.opening / metrics.thresholds.open, 1) * 0.2
    factors.jawMovement = Math.min(metrics.mouth.jawChange / metrics.thresholds.jaw, 1) * 0.2
    factors.teethVisibility = Math.min(metrics.mouth.teethChange / metrics.thresholds.teeth, 1) * 0.2
    factors.totalChange = Math.min(metrics.mouth.change / metrics.thresholds.change, 1) * 0.2
    factors.roundness = Math.min(metrics.mouth.roundness / metrics.thresholds.roundness, 1) * 0.3

    // Lip contact factor (specific for 'F' sound)
    factors.lipContact = Math.min(metrics.mouth.upperLipToLowerTeeth / metrics.thresholds.lipContact, 1) * 0.3

    factors.voice = metrics.voice ? 0.1 : 0

    // Calculate base confidence
    const confidence = Object.values(factors).reduce((sum, factor) => sum + factor, 0)

    // Special cases for specific sounds
    if (factors.roundness > 0.2 && factors.teethVisibility > 0.15) {
      // Boost for rounded sounds (o, u, q)
      return {
        confidence: Math.min(confidence * 1.3, 1),
        factors,
      }
    } else if (factors.lipContact > 0.25 && factors.teethVisibility > 0.2) {
      // Boost for 'F' sound
      return {
        confidence: Math.min(confidence * 1.4, 1), // 40% boost for F-like shapes
        factors,
      }
    }

    return { confidence, factors }
  }

  const getScaledThresholds = (faceWidth: number) => {
    const scale = FACE_WIDTH_REFERENCE / faceWidth // Smaller face = larger scale
    return {
      open: baseOpenThreshold * scale,
      jaw: baseJawThreshold * scale,
      teeth: baseTeethThreshold * scale,
      change: baseChangeThreshold * scale,
      roundness: baseRoundnessThreshold, // Roundness is already relative
      lipContact: baseLipContactThreshold * scale,
    }
  }

  // Add face orientation analysis function
  const checkFaceOrientation = (face: faceLandmarksDetection.Face) => {
    try {
      // Get key points for orientation detection
      const leftEye = face.keypoints[33] // Left eye outer corner
      const rightEye = face.keypoints[263] // Right eye outer corner
      const nose = face.keypoints[1] // Nose tip
      const leftCheek = face.keypoints[234] // Left cheek
      const rightCheek = face.keypoints[454] // Right cheek

      // Calculate face width for scale-independent thresholds
      const faceWidth = Math.abs(leftCheek.x - rightCheek.x)

      // Calculate horizontal head rotation using eye positions
      const eyeLineRotation = Math.abs(Math.atan2(rightEye.y - leftEye.y, rightEye.x - leftEye.x))

      // Calculate nose deviation from center
      const faceCenter = (leftEye.x + rightEye.x) / 2
      const noseDeviation = Math.abs(nose.x - faceCenter) / faceWidth

      // Thresholds for determining if looking away
      const ROTATION_THRESHOLD = 0.3 // About 17 degrees
      const NOSE_DEVIATION_THRESHOLD = 0.15 // 15% of face width

      // Check if face is too rotated or nose is too far from center
      const isRotatedAway = eyeLineRotation > ROTATION_THRESHOLD
      const isNoseDeviated = noseDeviation > NOSE_DEVIATION_THRESHOLD

      return isRotatedAway || isNoseDeviated
    } catch (error) {
      console.error('Error checking face orientation:', error)
      return true // Assume looking away if there's an error
    }
  }

  const checkMouthMovement = async () => {
    if (!detector || !videoRef.current || isProcessing.current) return

    // Initialize warmup timer on first call
    if (!warmupStartTime.current) {
      warmupStartTime.current = Date.now()
      return
    }

    // Check if we're still in warm-up period
    if (!isWarmedUp.current) {
      if (Date.now() - warmupStartTime.current < WARMUP_PERIOD) {
        if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
          const predictions = await detector.estimateFaces(videoRef.current, {
            flipHorizontal: false,
            staticImageMode: false,
          })

          if (predictions.length > 0) {
            const face = predictions[0]
            previousMouthShape.current = analyzeMouthShape(face)
          }
        }
        return
      }
      isWarmedUp.current = true
      console.log('Warm-up complete, starting detection')
    }

    // Only process if voice was detected
    if (!voiceDetected.current || !vadIsListening.current) return

    isProcessing.current = true
    try {
      if (videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
        const predictions = await detector.estimateFaces(videoRef.current, {
          flipHorizontal: false,
          staticImageMode: false,
        })

        if (predictions.length > 0) {
          const face = predictions[0]

          // Check face orientation before processing mouth movements
          const lookingAway = checkFaceOrientation(face)
          setIsLookingAway(lookingAway)

          // Only process mouth movements if not looking away
          if (!lookingAway) {
            // Get face width from landmarks
            const faceWidth = Math.abs(face.keypoints[234].x - face.keypoints[454].x)
            const currentShape = analyzeMouthShape(face)

            const scaledThresholds = getScaledThresholds(faceWidth)
            const { confidence, factors } = calculateSpeechConfidence({
              mouth: {
                opening: currentShape.height,
                change: currentShape.width,
                jawChange: currentShape.jawPosition,
                teethChange: currentShape.teethVisibility,
                roundness: currentShape.roundness,
                upperLipToLowerTeeth: currentShape.upperLipToLowerTeeth,
              },
              voice: voiceDetected.current,
              isQuiet: isQuiet.current,
              thresholds: scaledThresholds,
            })

            previousMouthShape.current = currentShape

            console.log('Confidence:', confidence, factors)
            if (confidence > 0.5) {
              setNextWordIndex((prev) => Math.min(prev + 1))
              lastChangeTime.current = Date.now()
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in detection:', error)
    }
    isProcessing.current = false
    voiceDetected.current = false // Reset voice detection after processing
  }

  // Add mouth shape analysis function matching previousMouthShape interface
  const analyzeMouthShape = (face: faceLandmarksDetection.Face) => {
    try {
      // Get mouth landmarks
      const leftCorner = face.keypoints[78]
      const rightCorner = face.keypoints[308]
      const upperLipCenter = face.keypoints[13]
      const lowerLipCenter = face.keypoints[14]
      const upperTeethPoint = face.keypoints[87]
      const lowerTeethPoint = face.keypoints[178]

      // Get lower lip points for better contact detection
      const lowerLipTop = face.keypoints[14] // Center of lower lip
      const upperTeethBottom = face.keypoints[87] // Bottom of upper teeth

      // Basic measurements
      const height = Math.abs(upperLipCenter.y - lowerLipCenter.y)
      const width = Math.abs(leftCorner.x - rightCorner.x)
      const jawPosition = Math.abs(face.keypoints[152].y - face.keypoints[234].y)

      // Calculate roundness
      const neutralWidth = 50
      const widthChange = Math.abs(width - neutralWidth)
      const roundness = widthChange / neutralWidth

      // Enhanced teeth visibility calculation
      const teethVisibility = Math.abs(upperTeethPoint.y - lowerTeethPoint.y)

      // Calculate lip-to-teeth contact (specific for 'F' sound)
      const upperLipToLowerTeeth = Math.abs(upperTeethBottom.y - lowerLipTop.y)
      // Lower value indicates closer contact

      return {
        height,
        width,
        jawPosition,
        teethVisibility,
        roundness,
        upperLipToLowerTeeth,
      }
    } catch (error) {
      console.error('Error analyzing mouth shape:', error)
      return {
        height: 0,
        width: 0,
        jawPosition: 0,
        teethVisibility: 0,
        roundness: 0,
        upperLipToLowerTeeth: 0,
      }
    }
  }

  const onSpeechStart = useCallback(() => {
    voiceDetected.current = true
  }, [])

  const onSpeechEnd = useCallback(() => {
    voiceDetected.current = false
  }, [])

  // Update startMouthReading
  const startMouthReading = useCallback(() => {
    setMouthReadingStarted(true)
  }, [])

  // Add new effect to handle VAD start
  useEffect(() => {
    if (mouthReadingStarted && vadRef.current && !vadRef.current.listening) {
      console.log('Voice Activity Detection started')
      vadRef.current.start()
    }
  }, [mouthReadingStarted, vadRef.current])

  // Update stopMouthReading
  const stopMouthReading = useCallback(() => {
    if (vadRef.current) {
      vadRef.current.stop()
    }
    setMouthReadingStarted(false)

    if (detector) {
      detector.dispose()
      setDetector(null)
    }

    setInitStatus({ audio: false, video: false, faceDetector: false })
  }, [detector])

  const startListening = useCallback(() => {
    vadIsListening.current = true
  }, [])

  const stopListening = useCallback(() => {
    vadIsListening.current = false
  }, [])

  // Start continuous mouth movement detection
  useEffect(() => {
    if (mouthReadingStarted) {
      const interval = setInterval(checkMouthMovement, 50)
      return () => clearInterval(interval)
    }
  }, [detector, mouthReadingStarted])

  return (
    <MouthReadingContext.Provider
      value={{
        nextWordIndex,
        setNextWordIndex,
        isSystemReady,
        mouthReadingStarted,
        startMouthReading,
        stopMouthReading,
        isLookingAway,
        startListening,
        stopListening,
      }}
    >
      <>
        {mouthReadingStarted && (
          <>
            <VADComponent ref={vadRef} onSpeechStart={onSpeechStart} onSpeechEnd={onSpeechEnd} />
            <video
              ref={videoRef}
              className="fixed left-0 top-0 z-50 h-48 w-64 -translate-x-full -translate-y-full"
              width={640}
              height={480}
              autoPlay
              playsInline
              muted
              style={{
                top: '-9999px',
                left: '-9999px',
              }}
            />
          </>
        )}
        {children}
      </>
    </MouthReadingContext.Provider>
  )
}

export const useMouthReading = () => {
  const context = useContext(MouthReadingContext)
  if (context === undefined) {
    throw new Error('useMouthReading must be used within a MouthReadingProvider')
  }
  return context
}
