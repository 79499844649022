import { useMemo } from 'react'
import { Bar, BarChart, Line } from 'recharts'
import { CartesianGrid, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { ResponsiveContainer } from 'recharts'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { useAppConfig } from '@/context/AppConfig'
import { parseOralReadingFluency } from '@/lib/parsers/oral-reading-fluency'
import { cn } from '@/lib/utils'
import { OralReadingFluencyResult } from '@/types/assessments/oral-reading-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'

type OralReadingFluencyScoreProps = {
  filteredAssessments: LessonAssignment<'oral-reading-fluency'>[]
}

interface AggregatedWordStats {
  word: string
  count: number
}

interface ScoreWithDate {
  originalData?: OralReadingFluencyResult
  correctCount: number
  incorrectCount: number
  correctWords: string[]
  insertedWords: string[]
  expectedWords: string[]
  omittedWords: string[]
  mispronunciationWords: string[]
  commonlyMissedWords: {
    word: string
    accuracy: number
  }[]
  completedAt: string
  rawDate: Date
}

export default function OralReadingFluencyScore({ filteredAssessments }: OralReadingFluencyScoreProps) {
  const { currentConfig } = useAppConfig()
  const urlParams = new URLSearchParams(window.location.search)
  const configMode = urlParams.get('config') === 'true'

  const scores = useMemo(() => {
    return filteredAssessments
      ?.map((assignment) => ({
        ...parseOralReadingFluency(
          assignment.result,
          currentConfig.pronunciationScoreThreshold!,
          currentConfig.useAzureMispronunciationFlag!
        ),
        expectedWords: assignment.result.exercises[0].activities[0].results.words.map((word) => word.Word),
        completedAt: new Date(assignment.completedAt).toLocaleString(),
        rawDate: new Date(assignment.completedAt),
      }))
      .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime()) as ScoreWithDate[]
  }, [filteredAssessments, currentConfig.pronunciationScoreThreshold, currentConfig.useAzureMispronunciationFlag])

  const aggregatedMissedWords = useMemo(() => {
    if (!scores) return []

    // Create a map to track word statistics
    const wordStats = scores.reduce<Record<string, { count: number }>>((acc, analysis) => {
      analysis.commonlyMissedWords.forEach(({ word }) => {
        if (!acc[word]) {
          acc[word] = { count: 0 }
        }
        acc[word].count++
      })
      return acc
    }, {})

    // Convert to array and calculate averages
    return Object.entries(wordStats)
      .map(
        ([word, { count }]): AggregatedWordStats => ({
          word,
          count,
        })
      )
      .sort((a, b) => b.count - a.count) // Sort by most frequent first
  }, [scores])

  const averageScores = useMemo(() => {
    if (!filteredAssessments.length) return null

    const totalScores = filteredAssessments.reduce(
      (acc, assessment) => {
        // Get all scores from the assessment
        const scores = assessment.result.exercises['0'].activities['0'].results.scores

        // Add this assessment's total scores to the accumulator
        return {
          accuracy: acc.accuracy + scores.AccuracyScore,
          completeness: acc.completeness + scores.CompletenessScore,
          fluency: acc.fluency + scores.FluencyScore,
          pronunciation: acc.pronunciation + scores.PronScore,
          prosody: acc.prosody + scores.ProsodyScore,
        }
      },
      { accuracy: 0, completeness: 0, fluency: 0, pronunciation: 0, prosody: 0 }
    )

    // Calculate total number of scores across all assessments
    const totalScoreCount = filteredAssessments.length

    return {
      accuracy: (totalScores.accuracy / totalScoreCount).toFixed(2),
      completeness: (totalScores.completeness / totalScoreCount).toFixed(2),
      fluency: (totalScores.fluency / totalScoreCount).toFixed(2),
      pronunciation: (totalScores.pronunciation / totalScoreCount).toFixed(2),
      prosody: (totalScores.prosody / totalScoreCount).toFixed(2),
    }
  }, [filteredAssessments])

  return (
    <>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
        {filteredAssessments.length > 1 ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={scores}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="correctCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Correct Words']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="correctCount" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={scores}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="incorrectCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Incorrect Words']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="incorrectCount" stroke="#f87171" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Commonly Missed Words</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={aggregatedMissedWords}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="word" />
                      <YAxis allowDecimals={false} domain={[0, 'auto']} />
                      <Tooltip
                        formatter={(value) => [`${value} times`, 'Times Missed']}
                        labelFormatter={(_, data) => `Word ${data[0]?.payload?.word}` || ''}
                      />
                      <Bar dataKey="count" fill="#f87171" name="Times Missed" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Words</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-green-600">{scores[0]?.correctCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">Words answered correctly</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Words</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-red-600">{scores[0]?.incorrectCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">
                  Words mispronounced or omitted (insertions are not counted)
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Needs Improvement</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {scores[0]?.mispronunciationWords.map((letter, i) => (
                    <span
                      key={`${letter}-${i}`}
                      className="inline-flex size-8 w-auto items-center justify-center rounded-full bg-red-100 p-4 text-sm font-semibold text-red-600"
                    >
                      {letter}
                    </span>
                  ))}
                  {scores[0]?.mispronunciationWords.length === 0 && (
                    <p className="text-sm text-gray-600">No letters were mispronounced!</p>
                  )}
                </div>
              </CardContent>
            </Card>
            {scores[0]?.originalData && (
              <>
                <Card className="xl:col-span-3">
                  <CardHeader>
                    <CardTitle>
                      User results{' '}
                      <span className="pl-2 text-sm text-gray-600">
                        (Total: {scores[0]?.originalData.exercises[0].activities[0].results.words.length})
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-wrap gap-2">
                      {scores[0]?.originalData.exercises[0].activities[0].results.words.map((item, i) => {
                        const isMispronounced = item?.PronunciationAssessment?.AccuracyScore < 50
                        const isInserted = item?.PronunciationAssessment?.ErrorType === 'Insertion'
                        const isOmitted = item?.PronunciationAssessment?.ErrorType === 'Omission'

                        const hasError = isMispronounced || isInserted || isOmitted
                        const errorMessage = isInserted
                          ? 'Insertion'
                          : isOmitted
                          ? 'Omission'
                          : isMispronounced
                          ? 'Mispronunciation'
                          : ''

                        const errorColors = () => {
                          if (isMispronounced && !isInserted && !isOmitted) {
                            return 'bg-red-100 text-red-600'
                          }
                          if (isInserted) {
                            return 'bg-yellow-100 text-yellow-600'
                          }
                          if (isOmitted) {
                            return 'bg-gray-100 text-black'
                          }
                          return 'bg-gray-100 text-black'
                        }

                        return (
                          <div key={`${item.Word}-${i}`} className="group relative">
                            <span
                              style={{ width: 'auto' }}
                              className={cn(
                                'p-2 cursor-pointer inline-flex size-8 items-center justify-center rounded-full text-sm font-semibold',
                                hasError ? errorColors() : 'bg-green-100 text-black'
                              )}
                            >
                              {item.Word}
                            </span>

                            <div className="absolute bottom-full left-1/2 mb-2 hidden -translate-x-1/2 group-hover:block">
                              <div className="whitespace-nowrap rounded bg-black px-2 py-1 text-xs text-white">
                                {errorMessage}

                                <div className="text-xs">
                                  {/* Here claude */}
                                  {item?.PronunciationAssessment?.AccuracyScore && !isInserted ? (
                                    <span>Score: {item?.PronunciationAssessment?.AccuracyScore?.toFixed(1)}</span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="absolute left-1/2 size-0 -translate-x-1/2 border-x-4 border-t-4 border-transparent border-t-black" />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </CardContent>
                </Card>
                <Card className="xl:col-span-3">
                  <CardHeader>
                    <CardTitle>
                      Expected order
                      <span className="pl-2 text-sm text-gray-600">(Total: {scores[0]?.expectedWords.length})</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-wrap gap-2">
                      {scores[0]?.expectedWords.map((item, i) => (
                        <span
                          key={`${item}-${i}`}
                          style={{ width: 'auto' }}
                          className="inline-flex size-8 items-center justify-center rounded-full bg-gray-100 p-2 text-sm font-semibold text-black"
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </>
            )}
          </>
        )}

        <Card className="xl:col-span-3">
          <CardHeader>
            {filteredAssessments.length > 1 ? <CardTitle>Average Scores</CardTitle> : <CardTitle>Score</CardTitle>}
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4 xl:grid-cols-5">
              <div className="rounded-md bg-blue-50 p-2">
                <p className="text-sm text-gray-600">Accuracy</p>
                <p className="text-2xl font-bold text-blue-600">{averageScores?.accuracy || '0.00'}</p>
              </div>
              <div className="rounded-md bg-green-50 p-2">
                <p className="text-sm text-gray-600">Completeness</p>
                <p className="text-2xl font-bold text-green-600">{averageScores?.completeness || '0.00'}</p>
              </div>
              <div className="rounded-md bg-purple-50 p-2">
                <p className="text-sm text-gray-600">Fluency</p>
                <p className="text-2xl font-bold text-purple-600">{averageScores?.fluency || '0.00'}</p>
              </div>
              <div className="rounded-md bg-yellow-50 p-2">
                <p className="text-sm text-gray-600">Pronunciation</p>
                <p className="text-2xl font-bold text-yellow-600">{averageScores?.pronunciation || '0.00'}</p>
              </div>
              <div className="col-span-2 rounded-md bg-pink-50 p-2 xl:col-span-1">
                <p className="text-sm text-gray-600">Prosody</p>
                <p className="text-2xl font-bold text-pink-600">{averageScores?.prosody || '0.00'}</p>
              </div>
            </div>
          </CardContent>
        </Card>
        {configMode && scores[0]?.originalData && <pre>{JSON.stringify(scores[0]?.originalData, null, 2)}</pre>}
      </div>
    </>
  )
}
