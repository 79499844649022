import { SignIn as ClerkSignIn } from '@clerk/clerk-react'
import { Navigate } from 'react-router-dom'

import cubesTopLeft from '@/assets/cubes_top_left.png'
import ondulationBg from '@/assets/ondulationBg.svg'
import PageLayout from '@/components/PageLayout'
import { useAuth } from '@/hooks/useAuth'

function SignIn() {
  const queryString = window.location.search // Get the current query string
  const { userId } = useAuth()

  if (userId) {
    return <Navigate to={`/${queryString}`} replace />
  }

  return (
    <PageLayout>
      <div className="flex h-screen w-full flex-col items-center justify-center bg-purple-gradient">
        <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
        <div className="z-10">
          <ClerkSignIn signUpUrl={`/sign-up${queryString}`} />
        </div>
        <img src={ondulationBg} className="absolute bottom-0 left-0" />
      </div>
    </PageLayout>
  )
}

export default SignIn
