import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { X } from 'lucide-react'
import React, { useState } from 'react'

import { Button } from '@/components/ui/button'
import { getLessons, inviteStudent } from '@/utils/api'

interface InviteUserModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

export function InviteUserModal({ isOpen, onClose, onSuccess }: InviteUserModalProps) {
  const [email, setEmail] = useState('')
  const [selectedLessons, setSelectedLessons] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { data: lessons } = useQuery({
    queryKey: ['lessons'],
    queryFn: getLessons,
  })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)
    try {
      await inviteStudent(email, selectedLessons.length ? selectedLessons : undefined)
      onSuccess()
      onClose()
      setEmail('')
      setSelectedLessons([])
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error inviting user:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCheckboxChange = (lessonId: string) => {
    setSelectedLessons((prev) => {
      if (prev.includes(lessonId)) {
        return prev.filter((id) => id !== lessonId)
      }
      return [...prev, lessonId]
    })
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
        <div className="mb-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold">Invite New Student</h2>
          <Button variant="ghost" size="icon" onClick={onClose}>
            <X className="size-4" />
          </Button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full rounded-md border border-gray-300 p-2"
              placeholder="student@example.com"
            />
          </div>

          <div className="mb-6">
            <label className="mb-2 block text-sm font-medium text-gray-700">Pre-assign Assessments (Optional)</label>
            <div className="max-h-48 overflow-y-auto rounded-md border border-gray-300 p-2">
              {lessons?.data
                ?.filter((lesson) => lesson.active)
                .map((lesson) => (
                  <div key={lesson.id} className="mb-2 flex items-center">
                    <input
                      type="checkbox"
                      id={lesson.id}
                      checked={selectedLessons.includes(lesson.id)}
                      onChange={() => handleCheckboxChange(lesson.id)}
                      className="mr-2 size-4 rounded border-gray-300"
                    />
                    <label htmlFor={lesson.id} className="text-sm text-gray-700">
                      {lesson.title}
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Inviting...' : 'Send Invitation'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
