import {
  FirstSoundFluencyAnalysis,
  FirstSoundFluencyResult,
  Result,
  ResultsWithAudioKey,
} from '@/types/assessments/first-sound-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'

import { getPartialCompositeScores } from './parsers-utils'
import { PartialCompositeScore } from './types/composite-score'

export function getFirstSoundFluencyScores(
  assessmentResults: LessonAssignment<'first-sound-fluency'>[],
  pronunciationScoreThreshold: number
): PartialCompositeScore {
  return getPartialCompositeScores(assessmentResults, parseFirstSoundFluency, pronunciationScoreThreshold, false)
}

export function parseFirstSoundFluency(assessmentResult: FirstSoundFluencyResult): FirstSoundFluencyAnalysis {
  const analysis: FirstSoundFluencyAnalysis = {
    correctCount: 0,
    incorrectCount: 0,
    correctPhonemes: [],
    missedPhonemes: [],
    commonlyMissedPhonemes: [],
    totalScore: 0,
    referenceWord: '',
  }

  const exercise = assessmentResult.exercises['0']
  const activity = exercise.activities['0']

  // @ts-expect-error: firstSoundResponses is not a valid property on the Result type
  const results = activity?.results?.firstSoundResponses
    ? (activity?.results as ResultsWithAudioKey).firstSoundResponses
    : (activity?.results as Result[])

  results.forEach((result) => {
    if (result.api_error) {
      return
    }

    // isCorrect is the property used for overrided score
    const isCorrect = result.isCorrect ?? result.correct_match_in_top_1
    const phonemeData = {
      phoneme: result.reference_phoneme,
      matchQuality: result.reference_phoneme_match_quality * 100,
      topMatches: result.top_matches,
      audioKey: result.audio_object_key,
      referenceWord: result.reference_word,
      isCorrect,
      points: 0,
      isIsolatedPhoneme: result.isIsolatedPhoneme ?? result.is_isolated_phoneme,
    }

    // isIsolatedPhoneme is the property used for overrided score
    if (isCorrect) {
      const isIsolatedPhoneme = result.isIsolatedPhoneme ?? result.is_isolated_phoneme

      const points = isIsolatedPhoneme ? 2 : 1
      analysis.totalScore += points

      analysis.correctCount++
      analysis.correctPhonemes.push({
        ...phonemeData,
        isIsolatedPhoneme,
        points,
      })
    } else {
      analysis.incorrectCount++
      analysis.missedPhonemes.push(phonemeData)
      analysis.commonlyMissedPhonemes.push(phonemeData)
    }
  })

  analysis.commonlyMissedPhonemes.sort((a, b) => a.matchQuality - b.matchQuality)

  return analysis
}
