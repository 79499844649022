import { Activity, Exercise, Lesson, LessonAssignment, User } from '@alpha-school/reading-fluency-models'
import { createContext, useContext } from 'react'

export type LessonAssignmentWithLesson = LessonAssignment & {
  lesson: Lesson & {
    exercises: Array<
      Exercise & {
        activities: Activity[]
      }
    >
  }
}

export interface UserContextType {
  user: User | null
  isLoading: boolean
  error: Error | null
  refetchUserData: () => Promise<User | null>
  fetchUserAssignments: () => Promise<LessonAssignmentWithLesson[] | null>
  userAssignments: LessonAssignmentWithLesson[] | null
}

export const UserContext = createContext<UserContextType>({
  user: null,
  isLoading: true,
  error: null,
  refetchUserData: async () => null,
  fetchUserAssignments: async () => null,
  userAssignments: null,
})

export const useUser = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
