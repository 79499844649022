import { EditIcon } from 'lucide-react'
import { useState } from 'react'

import AudioPlayer from '@/components/Admin/Dashboards/AudioPlayer'
import { PhonemeScore } from '@/components/Admin/Dashboards/PhonemeCard/types'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { PhonemeData } from '@/types/assessments/first-sound-fluency'

type PhonemeCardProps = {
  phonemeData: PhonemeData
  handleSave: (phonemeData: PhonemeData, score: PhonemeScore) => void
}
export function PhonemeCard({ phonemeData, handleSave }: PhonemeCardProps) {
  const { phoneme, referenceWord, audioKey } = phonemeData
  const [score, setScore] = useState<PhonemeScore>(
    phonemeData.isCorrect && phonemeData.isIsolatedPhoneme
      ? 'CORRECT'
      : phonemeData.isCorrect && !phonemeData.isIsolatedPhoneme
      ? 'PARTIALLY_CORRECT'
      : 'INCORRECT'
  )

  const handleValueChange = (value: PhonemeScore) => {
    setScore(value)
  }

  function renderLabel(phonemeData: PhonemeData) {
    if (!phonemeData.isCorrect && !phonemeData.isIsolatedPhoneme) {
      return <span className="rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-700">Incorrect</span>
    }
    if (phonemeData.isCorrect && phonemeData.isIsolatedPhoneme) {
      return <span className="rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-700">Correct</span>
    }
    if (phonemeData.isCorrect && !phonemeData.isIsolatedPhoneme) {
      return (
        <span className="rounded-full bg-yellow-100 px-2 py-0.5 text-xs font-medium text-yellow-700">
          Partially Correct
        </span>
      )
    }
    return <span className="rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-700">Incorrect</span>
  }

  return (
    <div className="relative rounded-md bg-gray-50 p-4">
      <div className="mb-2 flex flex-col">
        <div className="flex items-center gap-2">
          <p className="font-semibold">Target Phoneme: {phoneme}</p>
          {renderLabel(phonemeData)}
        </div>
        {referenceWord && (
          <p className="text-sm text-gray-600">
            Reference Word: <b>{referenceWord}</b>
          </p>
        )}
        <Dialog>
          <DialogTrigger asChild>
            <EditIcon className="absolute right-4 top-4 size-4 cursor-pointer text-gray-600 hover:text-gray-800" />
          </DialogTrigger>
          <DialogContent
            className="max-w-xs"
            onInteractOutside={(e) => {
              e.preventDefault()
            }}
          >
            <DialogHeader>
              <DialogTitle>Edit Score</DialogTitle>
              <DialogDescription>Edit the score for this phoneme.</DialogDescription>
            </DialogHeader>

            <div className="flex flex-col gap-2">
              <p className="text-sm text-gray-600">
                Target Phoneme: <b>{phoneme}</b>
              </p>
              {referenceWord && (
                <p className="text-sm text-gray-600">
                  Reference Word: <b>{referenceWord}</b>
                </p>
              )}
              {phonemeData.matchQuality && (
                <p className="text-sm text-gray-600">
                  Match Quality: <b>{phonemeData.matchQuality}%</b>
                </p>
              )}
            </div>

            <SelectGroup>
              <Select value={score} onValueChange={handleValueChange}>
                <SelectLabel>Score</SelectLabel>
                <SelectTrigger>
                  <SelectValue placeholder="Select a value" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="INCORRECT">Incorrect</SelectItem>
                  <SelectItem value="PARTIALLY_CORRECT">Partially Correct</SelectItem>
                  <SelectItem value="CORRECT">Correct</SelectItem>
                </SelectContent>
              </Select>
            </SelectGroup>

            <DialogFooter>
              <DialogClose asChild>
                <Button variant="outline">Cancel</Button>
              </DialogClose>
              <DialogClose asChild>
                <Button variant="primary-alt" onClick={() => handleSave(phonemeData, score)}>
                  Save
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      {phonemeData.isCorrect && (
        <p className="text-sm text-gray-600">
          Match Quality: <b>{phonemeData.matchQuality ?? 0}%</b>
        </p>
      )}
      {audioKey && (
        <div className="font-bold">
          <div className="h-[20px]" />

          <div className="w-[230px]">
            <AudioPlayer s3Key={audioKey} />
          </div>
        </div>
      )}
      {/* <div className="space-y-1">
        {topMatches.map((match, index) => (
          <p key={index} className="text-sm text-gray-600">
            {match}
          </p>
        ))}
        {audioKey && (
          <div className="font-bold">
            <div className="h-[20px]" />

            <div className="w-[230px]">
              <AudioPlayer s3Key={audioKey} />
            </div>
          </div>
        )}
      </div> */}
    </div>
  )
}
