import * as Sentry from '@sentry/react'
import { useAvatar } from 'alpha-ai-avatar-sdk-react'
import { LoaderCircleIcon, PlayIcon } from 'lucide-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import cubesTopLeft from '@/assets/cubes_top_left.png'
import ondulationBg from '@/assets/ondulationBg.svg'
import { LocaleSelector } from '@/components/LocaleSelector/LocaleSelector'
import PageLayout from '@/components/PageLayout'
import Window from '@/components/Window'
import { useAppConfig } from '@/context/AppConfig'
import { useMasteryLesson } from '@/context/MasteryLessonContext'
import { useSpeech } from '@/context/SpeechContext'
import { useUser } from '@/hooks/useUser'
import { getSpeechToken } from '@/utils/api'
import { waitForMediaReady } from '@/utils/domManipulation'

type ErrorType = 'microphone' | 'avatar' | 'user' | 'speechToken' | null

function Home() {
  const { user: userData, userAssignments, isLoading: verifyingUser, refetchUserData } = useUser()
  const navigate = useNavigate()
  const { setInitialized, loadNextLessonLesson, currentLessonAssignmentId } = useMasteryLesson()
  const { speechToken, setSpeechToken } = useSpeech()
  const queryString = window.location.search
  const { connect, isConnected, stop } = useAvatar()
  const [showButtons, setShowButtons] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorType, setErrorType] = useState<ErrorType>(null)
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState(false)
  const [avatarReady, setAvatarReady] = useState(false)
  // Use refs to track latest values for the interval check
  const hasMicrophonePermissionRef = useRef(hasMicrophonePermission)
  const isConnectedRef = useRef(isConnected)
  const { currentConfig } = useAppConfig()

  useEffect(() => {
    stop()
    loadNextLessonLesson()
  }, [])

  useEffect(() => {
    if (currentLessonAssignmentId) return
    loadNextLessonLesson()
  }, [userData])

  // Show exercises based on user role after initializations
  useEffect(() => {
    const isAdmin = userData?.role === 'ADMIN'
    const isStudent = userData?.role === 'STUDENT'
    const isTeacher = userData?.role === 'TEACHER'

    if (!userData) {
      // Debug for testing purposes
      console.log('User is null')
    }

    if (avatarReady && !loading && !verifyingUser && userData && speechToken) {
      setInitialized(true)
      if (isStudent && userAssignments && userAssignments.length > 0) {
        navigate(`/mastery${queryString}`)
        return
      } else if (isAdmin || isTeacher) {
        setShowButtons(true)
      } else {
        navigate(`/all-done${queryString}`)
      }
    }
  }, [avatarReady, loading, verifyingUser, userData, speechToken])

  // Update refs when values change
  useEffect(() => {
    hasMicrophonePermissionRef.current = hasMicrophonePermission
  }, [hasMicrophonePermission])

  useEffect(() => {
    isConnectedRef.current = isConnected
  }, [isConnected])

  const checkMediaElements = useCallback(async () => {
    // console.log('Checking media elements...', {
    //   hasMicrophonePermission: hasMicrophonePermissionRef.current,
    //   isConnected: isConnectedRef.current,
    // })

    if (hasMicrophonePermissionRef.current && isConnectedRef.current) {
      const videoElement = document.querySelector("video[data-testid='avatar-video']")
      const audioElement = document.querySelector("audio[data-testid='avatar-audio']")

      if (!videoElement || !audioElement) return

      try {
        await waitForMediaReady(videoElement as HTMLVideoElement)
        await waitForMediaReady(audioElement as HTMLAudioElement)
        setTimeout(() => {
          setAvatarReady(true)
          setLoading(false)
        }, 1000)
      } catch (error) {
        Sentry.captureException(error)
        console.error('Media failed to load:', error)
        setLoading(false)
        setErrorType('avatar')
      }
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(checkMediaElements, 1000)
    return () => clearInterval(intervalId)
  }, [checkMediaElements])

  const init = async () => {
    setLoading(true)
    setErrorType(null)

    // Get updated user data
    try {
      await refetchUserData()
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error fetching user data:', error)
      setErrorType('user')
      setLoading(false)
      return
    }

    // Get speech token
    try {
      const { data } = await getSpeechToken()
      setSpeechToken(data)
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error fetching speech token on home page:', error)
      setErrorType('speechToken')
      setLoading(false)
      return
    }

    // Connect avatar
    if (!isConnected) {
      try {
        await connect(currentConfig.avatarId ?? parseInt(import.meta.env.VITE_AVATAR_ID))
      } catch (error) {
        Sentry.captureException(error)
        console.error('Avatar connection failed:', error)
        setErrorType('avatar')
        setLoading(false)
        return
      }
    }

    // Try to get microphone permission
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
      stream.getTracks().forEach((track) => track.stop())
      setHasMicrophonePermission(true)
    } catch (error) {
      Sentry.captureException(error)
      console.error('Microphone permission denied:', error)
      setErrorType('microphone')
      setLoading(false)
    }
  }

  const getErrorMessage = () => {
    switch (errorType) {
      case 'microphone':
        return 'Microphone access is required to use this application. Please enable microphone access in your browser settings and try again.'
      case 'avatar':
        return 'Unable to connect to the avatar service. Please check your internet connection and try again.'
      case 'user':
        return 'Unable to fetch user data. Please check your internet connection and try again.'
      case 'speechToken':
        return 'Unable to fetch speech token. Please check your internet connection and try again.'
      default:
        return ''
    }
  }

  const showLoading = verifyingUser || loading

  return (
    <PageLayout className="relative justify-center">
      <LocaleSelector />
      <div className="flex h-screen w-full flex-col items-center justify-center bg-purple-gradient">
        <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
        <Window className="min-w-[50%] items-center justify-center">
          <h1 className="mb-4 text-center text-5xl font-bold leading-[60px] text-white">
            Welcome To
            <br />
            Fluency Coach!
          </h1>

          {!showButtons ? (
            <div className="flex flex-col items-center">
              {userData?.role === 'TEACHER' && (
                <button
                  style={{ width: '252px' }}
                  onClick={() => navigate(`/admin/lesson-assignment${queryString}`)}
                  className="mt-4 w-fit rounded-full border-2 border-none bg-gradient-to-b from-[#00D0FF]/30 to-[#0067F5]/30 text-2xl font-bold text-white shadow-xl"
                >
                  Assessment
                </button>
              )}
              <button
                onClick={init}
                disabled={showLoading}
                className="mt-8 flex animate-scale-pulse items-center justify-center rounded-full border-b-8 border-none border-blue-600 bg-blue-500 p-8 text-white shadow-xl transition-transform hover:translate-y-1 active:translate-y-2 active:border-b-0"
              >
                {showLoading ? (
                  <LoaderCircleIcon className="z-10 size-16 animate-spin" />
                ) : (
                  <PlayIcon className="z-10 size-16" fill="white" />
                )}
                {!showLoading && (
                  <span className="absolute inline-flex size-full animate-ping rounded-full bg-blue-500 opacity-75"></span>
                )}
              </button>
              {errorType && (
                <div className="mt-8 rounded-[21px] border border-b-4 border-semi-white bg-white/10 px-8 py-4">
                  <p className="max-w-[400px] text-center text-xl text-white">{getErrorMessage()}</p>
                  <button
                    onClick={errorType === 'avatar' ? () => window.location.reload() : init}
                    className="mt-4 w-full rounded-full border-2 border-none bg-gradient-to-b from-[#00D0FF]/30 to-[#0067F5]/30 py-2 text-xl font-bold text-white shadow-xl"
                  >
                    {errorType === 'avatar' ? 'Reload Page' : 'Try Again'}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4">
              <div className="flex flex-row gap-4">
                <button
                  style={{ width: '252px' }}
                  onClick={() => navigate(`/mastery/letter-naming-fluency${queryString}`)}
                  className="mt-4 w-fit rounded-full border-2 border-none bg-gradient-to-b from-[#00D0FF]/30 to-[#0067F5]/30 text-2xl font-bold text-white shadow-xl"
                >
                  Letter
                  <br />
                  Naming
                </button>
                <button
                  style={{ width: '252px' }}
                  onClick={() => navigate(`/mastery/letter-sound-fluency${queryString}`)}
                  className="mt-4 w-fit rounded-full border-2 border-none bg-gradient-to-b from-[#00D0FF]/30 to-[#0067F5]/30 text-2xl font-bold text-white shadow-xl"
                >
                  Letter <br /> Sound
                </button>
                <button
                  style={{ width: '252px' }}
                  onClick={() => navigate(`/mastery/letter-sound-completions-fluency${queryString}`)}
                  className="mt-4 w-fit rounded-full border-2 border-none bg-gradient-to-b from-[#00D0FF]/30 to-[#0067F5]/30 text-2xl font-bold text-white shadow-xl"
                >
                  Letter <br /> Sound
                  <br />
                  Completions
                </button>
                <button
                  style={{ width: '252px' }}
                  onClick={() => navigate(`/mastery/letter-naming-correspondence${queryString}`)}
                  className="mt-4 min-h-[96px] w-fit rounded-full border-2 border-none bg-gradient-to-b from-[#00D0FF]/30 to-[#0067F5]/30 text-2xl font-bold text-white shadow-xl"
                >
                  Letter <br />
                  Correspondence
                </button>
              </div>
            </div>
          )}
        </Window>
      </div>
      <img src={ondulationBg} className="absolute bottom-0 left-0" />
    </PageLayout>
  )
}

export default Home
