import { useQuery } from '@tanstack/react-query'
import { useAvatar } from 'alpha-ai-avatar-sdk-react'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'

import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { RichAvatar } from '@/components/Avatar/RichAvatar'
import { LoadingIcon } from '@/components/Loading'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Switch } from '@/components/ui/switch'
import { Textarea } from '@/components/ui/textarea'
import { useAppConfig } from '@/context/AppConfig'
import { useToast } from '@/hooks/use-toast'
import { useConversation } from '@/hooks/useConversation'

import { excercisesContent, options } from './excercices'

interface Voice {
  id: number
  name: string
  thumbnail: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  Applications: any
  avatarVersions: any
}

const getVoices = async (): Promise<Voice[]> => {
  const res = await axios.get<Voice[]>('https://avatar.alpha.school/avatars', {
    headers: {
      'X-API-Key': import.meta.env.VITE_AVATAR_KEY,
      'Content-Type': 'application/json',
    },
  })
  return res.data
}

export function AvatarSettings() {
  const { currentConfig, saveConfig, currentConfigIndex } = useAppConfig()
  const [isSwitching, setIsSwitching] = useState(false)
  const [selectedVoice, setSelectedVoice] = useState<number | undefined>()
  const [useCustomText, setUseCustomText] = useState(false)
  const [customText, setCustomText] = useState('')
  const { switchAvatar, say, connect, isConnected, disconnect } = useAvatar()
  const [selectedOption, setSelectedOption] = useState<string | 'disabled'>('disabled')
  const { runNext } = useConversation({
    queue: [() => say('')],
  })
  const { toast } = useToast()

  const voicesQuery = useQuery({
    queryKey: ['avatar-list'],
    queryFn: async () => {
      const res = await getVoices()
      return res
    },
  })

  const handleVoiceChange = async (value: string) => {
    if (!voicesQuery.data) return
    const voice = voicesQuery.data.find((voice) => voice.id === Number(value))
    if (!voice) return

    setIsSwitching(true)
    try {
      await switchAvatar(voice.id)
      await connect(voice.id)
      setSelectedVoice(voice.id)
    } catch (error) {
      console.error('Error switching avatar:', error)
    } finally {
      setIsSwitching(false)
    }
  }

  useEffect(() => {
    if (currentConfig && !voicesQuery.isLoading && !voicesQuery.isFetching) {
      const voice = currentConfig?.avatarId ?? parseInt(import.meta.env.VITE_AVATAR_ID)
      setSelectedVoice(voice)

      if (voice !== selectedVoice) {
        handleVoiceChange(voice.toString())
      }
    }

    return () => {
      if (isConnected) {
        disconnect()
      }
    }
  }, [currentConfig, voicesQuery.isLoading, voicesQuery.isFetching])

  const voices = useMemo(() => voicesQuery.data?.filter((voice) => voice?.id), [voicesQuery.data])

  const speak = () => {
    if (useCustomText) {
      if (!customText.trim()) return
      say(customText)
      return
    }

    const q: (() => void)[] = []

    if (!selectedOption) return []

    const excerciseOptions = excercisesContent[selectedOption as keyof typeof excercisesContent]
    excerciseOptions.forEach((option) => {
      q.push(() => {
        say(option)
      })
    })

    runNext(q)
    return q
  }

  const handleOptionChange = (value: string) => {
    const option = value as keyof typeof excercisesContent
    setSelectedOption(option)
  }

  const handleStop = async () => {
    setIsSwitching(true)
    try {
      if (isConnected) {
        await disconnect()
      }
      if (selectedVoice) {
        await connect(selectedVoice)
      }
    } catch (error) {
      console.error('Error during stop operation:', error)
    } finally {
      setIsSwitching(false)
    }
  }

  const saveAvatarSettings = () => {
    saveConfig({ config: { ...currentConfig, avatarId: selectedVoice! }, index: currentConfigIndex })
    toast({
      title: 'Avatar settings saved successfully',
      variant: 'success',
    })
  }

  return (
    <>
      <AdminLayout title="Avatar Settings & Playground">
        <div className="flex h-full flex-col gap-8 p-8 pt-24">
          {voicesQuery.isLoading || voicesQuery.isFetching ? (
            <div className="flex h-full items-center justify-center">
              <LoadingIcon />
            </div>
          ) : (
            <>
              <div className="flex flex-row gap-8">
                <div className="flex">
                  <div className="flex flex-1 items-center justify-center pt-6">
                    <div className="flex size-[160px] items-center justify-center overflow-hidden rounded-full bg-[#f3f4f6]">
                      {isSwitching ? <LoadingIcon /> : <RichAvatar message="Hello, how are you?" />}
                    </div>
                  </div>
                </div>

                <Card className="flex-1">
                  <CardHeader>
                    <CardTitle>Avatar Settings</CardTitle>
                    <CardDescription>
                      When navigating through the avatar list, changes will apply to this session only. To make the
                      selection persist across sessions, click "Save".
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="flex flex-col gap-4">
                    <div className="flex-1">
                      <SelectGroup>
                        <Select onValueChange={(value) => handleVoiceChange(value)} value={selectedVoice?.toString()}>
                          <SelectLabel>Avatar</SelectLabel>
                          <SelectTrigger>
                            <SelectValue placeholder="Select an Avatar" />
                          </SelectTrigger>
                          <SelectContent>
                            {voices?.map((voice: Voice) => (
                              <SelectItem key={voice.id} value={voice.id.toString()}>
                                {voice.name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </SelectGroup>
                    </div>

                    <div className="flex flex-1 flex-row justify-end gap-4">
                      <Button
                        variant="primary-alt"
                        onClick={saveAvatarSettings}
                        disabled={!selectedVoice || isSwitching}
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <Card className="flex flex-col">
                <CardHeader>
                  <CardTitle>Avatar Playground</CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col gap-4">
                  <div className="flex items-center space-x-2">
                    <Switch id="custom-text" checked={useCustomText} onCheckedChange={setUseCustomText} />
                    <Label htmlFor="custom-text">Use custom text</Label>
                  </div>

                  {useCustomText ? (
                    <div className="flex flex-col gap-2">
                      <Label>Custom Text</Label>
                      <Textarea
                        placeholder="Enter text for the avatar to speak..."
                        value={customText}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCustomText(e.target.value)}
                        className="min-h-[100px]"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-row gap-4">
                      <div className="flex-1">
                        <SelectGroup>
                          <Select onValueChange={(value) => handleOptionChange(value)}>
                            <SelectLabel>Options</SelectLabel>
                            <SelectTrigger>
                              <SelectValue placeholder="Select an option" />
                            </SelectTrigger>
                            <SelectContent>
                              {options.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                  {option.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </SelectGroup>
                      </div>
                    </div>
                  )}

                  <div className="flex flex-1 flex-row justify-end gap-4">
                    <Button
                      variant="primary-alt"
                      onClick={handleStop}
                      disabled={!selectedVoice || (useCustomText ? !customText : selectedOption === 'disabled')}
                    >
                      Stop
                    </Button>

                    <Button
                      type="button"
                      variant="primary-alt"
                      onClick={speak}
                      disabled={!selectedVoice || (useCustomText ? !customText : selectedOption === 'disabled')}
                    >
                      Say
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      </AdminLayout>
    </>
  )
}
