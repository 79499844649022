import { useSignIn, useUser } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'
import { Navigate,useNavigate, useSearchParams } from 'react-router-dom'

import PageLayout from '@/components/PageLayout'
import Spinner from '@/components/Spinner'

function AcceptToken() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const signInToken = searchParams.get('token')
  const { signIn, setActive } = useSignIn()
  const { user } = useUser()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!signIn || !setActive || !signInToken || user || loading) {
      return
    }

    const createSignIn = async () => {
      setLoading(true)
      try {
        // Create the `SignIn` with the token
        const signInAttempt = await signIn.create({
          strategy: 'ticket',
          ticket: signInToken as string,
        })

        // If the sign-in was successful, set the session to active
        if (signInAttempt.status === 'complete') {
          setActive({
            session: signInAttempt.createdSessionId,
          })
        } else {
          // If the sign-in attempt is not complete, check why.
          // User may need to complete further steps.
          Sentry.captureException(signInAttempt)
          navigate('/sign-in')
        }
      } catch (err) {
        Sentry.captureException(err)
        navigate('/sign-in')
      } finally {
        setLoading(false)
      }
    }

    createSignIn()
  }, [signIn, setActive, signInToken, user, loading])

  if (!signInToken) {
    return <Navigate to="/sign-in" replace />
  }

  if (loading || !user) {
    return (
      <PageLayout>
        <div className="flex h-screen w-full flex-col items-center justify-center bg-purple-gradient">
          <div className="z-10">
            <div className="flex flex-col items-center justify-center">
              <div className="flex size-10 items-center justify-center rounded-full bg-white">
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    )
  }

  return <Navigate to="/" replace />
}

export default AcceptToken
