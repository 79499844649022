import { Lesson } from '@alpha-school/reading-fluency-models'
import * as Sentry from '@sentry/react'
import { useEffect, useState } from 'react'

import { assignLesson, getLessons, getStudents } from '@/utils/api'

interface Student {
  id: string
  email: string
  role: string
}

export function LessonAssignment() {
  const [students, setStudents] = useState<Student[]>([])
  const [lessons, setLessons] = useState<Lesson[]>([])
  const [selectedStudent, setSelectedStudent] = useState<string>('')
  const [selectedLesson, setSelectedLesson] = useState<string>('')
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' } | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [lessonsResponse, studentsResponse] = await Promise.all([getLessons(), getStudents()])

        setLessons(lessonsResponse.data)
        setStudents(studentsResponse.data)
      } catch (error) {
        Sentry.captureException(error)
        console.error('Error fetching data:', error)
        setMessage({ text: 'Error loading data', type: 'error' })
      }
    }

    fetchData()
  }, [])

  const handleAssignment = async () => {
    if (!selectedStudent || !selectedLesson) {
      setMessage({ text: 'Please select both a student and a assessment', type: 'error' })
      return
    }

    try {
      await assignLesson(selectedStudent, selectedLesson)
      setMessage({ text: 'Assigned successfully!', type: 'success' })
      // Reset selections
      setSelectedStudent('')
      setSelectedLesson('')
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error assigning:', error)
      setMessage({ text: 'Error assigning', type: 'error' })
    }
  }

  return (
    <div className="p-6">
      <h1 className="mb-6 text-2xl font-bold">Assessment</h1>

      {message && (
        <div
          className={`mb-4 rounded p-4 ${
            message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}
        >
          {message.text}
        </div>
      )}

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <h2 className="mb-4 text-xl font-semibold">Select Student</h2>
          <select
            className="w-full rounded border p-2"
            value={selectedStudent}
            onChange={(e) => setSelectedStudent(e.target.value)}
          >
            <option value="">Choose a student...</option>
            {students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.email}
              </option>
            ))}
          </select>
        </div>

        <div>
          <h2 className="mb-4 text-xl font-semibold">Select Assessment</h2>
          <select
            className="w-full rounded border p-2"
            value={selectedLesson}
            onChange={(e) => setSelectedLesson(e.target.value)}
          >
            <option value="">Choose a assessment...</option>
            {lessons.map((lesson) => (
              <option key={lesson.id} value={lesson.id}>
                {lesson.title}
              </option>
            ))}
          </select>
        </div>
      </div>

      <button
        className="mt-6 rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:bg-gray-400"
        onClick={handleAssignment}
        disabled={!selectedStudent || !selectedLesson}
      >
        Assign
      </button>
    </div>
  )
}
