import { Suspense } from 'react'

import Spinner from '@/components/Spinner'

function DynamicComponentLoader({ ComponentToRender, componentKey, componentProps, ...props }: any) {
  if (!ComponentToRender) {
    return (
      <div className="flex size-[64px] min-h-[64px] items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <Suspense
      fallback={
        <div className="flex size-[64px] min-h-[64px] items-center justify-center">
          <Spinner />
        </div>
      }
    >
      <ComponentToRender {...props} key={componentKey} {...componentProps} />
    </Suspense>
  )
}

export default DynamicComponentLoader
