import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { InfoIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { Letter } from '@/components/Admin/Dashboards/LetterNamingFluency/Letter'
import { ScoreWithDate } from '@/components/Admin/Dashboards/LetterNamingFluency/types/ScoreWithDate'
import { LoadingIcon } from '@/components/Loading/Loading'
import { LoadingWrapper } from '@/components/Loading/Loading'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tooltip as TooltipComponent, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useAppConfig } from '@/context/AppConfig'
import { useToast } from '@/hooks/use-toast'
import { parseLetterNamingFluency } from '@/lib/parsers/letter-naming-fluency'
import { cn } from '@/lib/utils'
import { LetterNamingFluencyResult } from '@/types/assessments/letter-naming-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'
import { updateLessonAssignment } from '@/utils/api'
import { correctLessonAssignmentResult } from '@/utils/api'

import AudioPlayer from './AudioPlayer'

type LetterNamingFLuencyScoreProps = {
  filteredAssessments: LessonAssignment<any>[]
}

export default function LetterNamingFLuencyScore({ filteredAssessments }: LetterNamingFLuencyScoreProps) {
  const { currentConfig } = useAppConfig()
  const urlParams = new URLSearchParams(window.location.search)
  const configMode = urlParams.get('config') === 'true'

  const { toast } = useToast()
  const queryClient = useQueryClient()
  const [isSaving, setIsSaving] = useState(false)
  const filteredAssessmentsWithResults = filteredAssessments.filter((assignment) => {
    return assignment?.result?.exercises?.['0']?.activities?.['0']
  })
  const scores = useMemo(() => {
    return filteredAssessmentsWithResults
      ?.map((assignment) => {
        const result = assignment.correctedResult ?? assignment.result
        return {
          ...parseLetterNamingFluency(
            result,
            currentConfig.pronunciationScoreThreshold!,
            currentConfig.useAzureMispronunciationFlag!,
            assignment?.lesson?.exercises?.[0]?.activities?.[0]?.props?.letters
          ),
          expectedLetters:
            result.exercises['0'].activities['0'].results?.expectedLetters || //exact match for the randomized letters used in the assessment
            assignment?.lesson?.exercises?.[0]?.activities?.[0]?.props?.letters, // fallback to the defined letters in the lesson
          completedAt: new Date(assignment.completedAt).toLocaleString(),
          rawDate: new Date(assignment.completedAt),
          audioKey: result.exercises['0'].activities['0'].results?.audioKey,
          caseType: assignment?.lesson?.exercises?.[0]?.activities?.[0]?.props?.caseType,
        }
      })
      .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime()) as ScoreWithDate[]
  }, [
    filteredAssessmentsWithResults,
    currentConfig.pronunciationScoreThreshold,
    currentConfig.useAzureMispronunciationFlag,
  ])

  const handleLetterSave = async (correctedResult: LetterNamingFluencyResult) => {
    setIsSaving(true)

    try {
      const payload = {
        status: 'COMPLETED',
        correctedResult,
      }

      await Promise.all([
        updateLessonAssignment({
          lessonAssignmentId: filteredAssessmentsWithResults[0].id,
          ...payload,
        }),
        correctLessonAssignmentResult(filteredAssessmentsWithResults[0].lesssonAssignmentResults[0].id, payload),
      ])

      toast({
        title: 'Letter score updated successfully',
        variant: 'success',
      })
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error updating lesson assessment', error)
      toast({
        title: 'Error updating letter score',
        variant: 'destructive',
      })
    } finally {
      setIsSaving(false)
      queryClient.invalidateQueries({
        queryKey: [
          'student-results',
          filteredAssessmentsWithResults[0].userId,
          filteredAssessmentsWithResults[0].lessonId,
        ],
      })
      queryClient.invalidateQueries({ queryKey: ['lesson-assignment-result', filteredAssessmentsWithResults[0].id] })
    }
  }

  const allLetters = scores[0]?.allLetters
  return (
    <>
      <div
        className={cn(
          'grid gap-8 grid-cols-1 md:grid-cols-2',
          filteredAssessmentsWithResults.length > 1 ? 'xl:grid-cols-2' : 'xl:grid-cols-3'
        )}
      >
        {filteredAssessmentsWithResults.length === 1 && scores[0]?.audioKey && (
          <Card className="xl:col-span-4">
            <CardHeader>
              <CardTitle>Assignment Recording</CardTitle>
            </CardHeader>
            <CardContent>
              <AudioPlayer s3Key={scores[0]?.audioKey} />
            </CardContent>
          </Card>
        )}

        {filteredAssessmentsWithResults.length > 1 ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={scores}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="correctCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Correct Letters']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="correctCount" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={scores}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="incorrectCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Incorrect Letters']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="incorrectCount" stroke="#f87171" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
            {/* <Card>
              <CardHeader>
                <CardTitle>Commonly Missed Letters</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={aggregatedMissedLetters}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="letter" />
                      <YAxis allowDecimals={false} domain={[0, 'auto']} />
                      <Tooltip
                        formatter={(value) => [`${value} times`, 'Times Missed']}
                        labelFormatter={(_, data) => `Letter ${data[0]?.payload?.letter}` || ''}
                      />
                      <Bar dataKey="count" fill="#f87171" name="Times Missed" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card> */}
          </>
        ) : (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Letters</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-green-600">{scores[0]?.correctCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">Letters answered correctly</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Letters</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-red-600">{scores[0]?.incorrectCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">
                  Letters mispronounced or omitted (insertions are not counted)
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Needs Improvement</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {scores[0]?.mispronunciationLetters.map((letter, i) => (
                    <span
                      key={`${letter}-${i}`}
                      className="inline-flex size-8 items-center justify-center rounded-full bg-red-100 text-sm font-semibold text-red-600"
                    >
                      {letter}
                    </span>
                  ))}
                  {scores[0]?.mispronunciationLetters.length === 0 && (
                    <p className="text-sm text-gray-600">No letters were mispronounced!</p>
                  )}
                </div>
              </CardContent>
            </Card>
            {scores[0]?.originalData && (
              <>
                <Card className="xl:col-span-4">
                  <CardHeader>
                    <CardTitle>
                      <span className="flex items-center">
                        User results{' '}
                        <TooltipComponent delayDuration={100}>
                          <TooltipTrigger asChild>
                            <InfoIcon className="ml-2 size-4 cursor-pointer" />
                          </TooltipTrigger>
                          <TooltipContent>Click the letter to edit</TooltipContent>
                        </TooltipComponent>{' '}
                        <span className="pl-2 text-sm text-gray-600">(Total: {allLetters.length})</span>
                      </span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-wrap gap-2">
                      {allLetters.map((item, i) => (
                        <Letter
                          key={`${item.Word}-${i}`}
                          letter={item}
                          index={i}
                          score={scores[0]}
                          assessment={filteredAssessments[0]}
                          handleSave={handleLetterSave}
                        />
                      ))}
                    </div>
                  </CardContent>
                </Card>
                <Card className="relative xl:col-span-4">
                  <CardHeader>
                    <CardTitle>
                      Expected order
                      <span className="pl-2 text-sm text-gray-600">(Total: {scores[0]?.expectedLetters.length})</span>
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-wrap gap-2">
                      {scores[0]?.expectedLetters.map((letter, i) => (
                        <span
                          key={`${letter}-${i}`}
                          className={cn(
                            'inline-flex size-8 items-center justify-center rounded-full bg-gray-100 text-sm font-semibold text-black',
                            scores[0]?.caseType === 'upper' && 'uppercase',
                            scores[0]?.caseType === 'lower' && 'lowercase'
                          )}
                        >
                          {letter}
                        </span>
                      ))}
                    </div>
                  </CardContent>
                  {isSaving && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white/50">
                      <LoadingWrapper>
                        <LoadingIcon />
                      </LoadingWrapper>
                    </div>
                  )}
                </Card>
              </>
            )}
          </>
        )}

        {configMode && scores[0]?.originalData && <pre>{JSON.stringify(scores[0]?.originalData, null, 2)}</pre>}
      </div>
    </>
  )
}
