import * as Sentry from '@sentry/react'
import * as sdk from 'microsoft-cognitiveservices-speech-sdk'
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'

import { useAppConfig } from '@/context/AppConfig'
import { useLesson } from '@/context/LessonContext'
import { useAuth } from '@/hooks/useAuth'
import { useTimer } from '@/hooks/useTimer'
import { SpeechTokenType } from '@/types/models'
import { getSpeechToken } from '@/utils/api'
import { SpeechAnalysis } from '@/utils/speechAnalysis'
import { cleanString, convertOrdinal, numberToWords, recognizerEdgeCases } from '@/utils/string'

const TEN_MINUTES_IN_MS = 10 * 60 * 1000
const SpeechContext = createContext<any | undefined>(undefined)

interface SpeechProviderProps {
  children: ReactNode
}

export const SpeechProvider: React.FC<SpeechProviderProps> = ({ children }) => {
  const { currentConfig } = useAppConfig()
  const minTimeBetweenRecognition = currentConfig?.minTimeBetweenRecognition
  const [isRecognizingLetters, setIsRecognizingLetters] = useState(false)
  const [currentPhrase, setCurrentPhrase] = useState<string>('')
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [recording, setRecording] = useState(false)
  const [hasInterventions, setHasInterventions] = useState(false)
  const [tempFlag, setTempFlag] = useState(false)
  const speechAnalysisRef = useRef<SpeechAnalysis | null>(null)
  const [speechToken, setSpeechToken] = useState<SpeechTokenType | null>(null)
  const { userId } = useAuth()
  const { lessonPhrase } = useLesson()

  const minTimeBetweenRecognitionRef = useRef(minTimeBetweenRecognition)
  const speechResultRef = useRef<any>([])
  const strongRecognizerResultsRef = useRef<any>([])
  const weakRecognizerResultsRef = useRef<any>({
    text: '',
    lastWord: '',
    history: [],
  })
  const isListeningRef = useRef(false)
  const timeSinceLastRecognitionRef = useRef(0)
  const speechTokenInitialized = useRef(false)
  const lastWeakRecognitionTimeRef = useRef(Date.now())
  const lessonPhraseWordsRef = useRef<any>([])
  const currentPhraseRef = useRef(currentPhrase)
  const onRecognizing = useRef<any>(null)
  const onRecognized = useRef<any>(null)
  const timer = useTimer()
  const [weakRecognizerUpdate, setWeakRecognizerUpdate] = useState(0)
  const [strongRecognizerUpdate, setStrongRecognizerUpdate] = useState(0)

  useEffect(() => {
    minTimeBetweenRecognitionRef.current = minTimeBetweenRecognition
  }, [minTimeBetweenRecognition])

  useEffect(() => {
    currentPhraseRef.current = currentPhrase
  }, [currentPhrase])

  useEffect(() => {
    if (speechToken) {
      speechTokenInitialized.current = true
    }
  }, [speechToken])

  const updateSpeechToken = async () => {
    const { data } = await getSpeechToken()

    setSpeechToken(data)
    speechAnalysisRef.current?.updateSpeechToken(data.token)
  }

  const scheduleNextTokenRefresh = () => {
    return setTimeout(() => {
      updateSpeechToken()
        .catch((err) => {
          Sentry.captureException(err)
          console.error('tokenRefresh error:', err)
        })
        .finally(() => {
          scheduleNextTokenRefresh()
        })
    }, TEN_MINUTES_IN_MS)
  }

  useEffect(() => {
    const timeoutId = scheduleNextTokenRefresh()
    return () => {
      clearTimeout(timeoutId)
    }
  }, [userId, speechToken])

  onRecognizing.current = (e: any) => {
    const currentTime = Date.now()
    timeSinceLastRecognitionRef.current = currentTime - lastWeakRecognitionTimeRef.current
    lastWeakRecognitionTimeRef.current = currentTime

    const result = JSON.parse(e.result.properties.getProperty(sdk.PropertyId.SpeechServiceResponse_JsonResult))
    const text = result.Text

    const cleanLessonPhrase = cleanString(lessonPhrase || currentPhraseRef.current)

    lessonPhraseWordsRef.current = cleanLessonPhrase.split(' ').map((word: string) => word.toLowerCase())

    if (!text) return

    const recognizedWords = text
      .toLowerCase()
      .split(isRecognizingLetters ? '' : ' ')
      .map((word: string) => convertOrdinal(numberToWords(recognizerEdgeCases(word))))

    weakRecognizerResultsRef.current = {
      text,
      lastWord: recognizedWords[recognizedWords.length - 1],
      history: [...weakRecognizerResultsRef.current.history, ...recognizedWords],
    }

    setWeakRecognizerUpdate((prev) => prev + 1)
  }

  onRecognized.current = (e: any) => {
    const result = JSON.parse(e.result.properties.getProperty(sdk.PropertyId.SpeechServiceResponse_JsonResult))

    const nb = result['NBest'][0]

    speechResultRef.current = speechAnalysisRef?.current?.currentSpeechResult
    strongRecognizerResultsRef.current = [...strongRecognizerResultsRef.current, ...nb.Words]
    setStrongRecognizerUpdate((prev) => prev + 1)
  }

  const handleStartRecording = async (phrase: string, oneShot?: boolean) => {
    if (!speechToken) {
      console.error('No speech token available')
      return
    }

    if (speechAnalysisRef.current) {
      speechAnalysisRef.current.close()
      speechAnalysisRef.current = null
    }

    lastWeakRecognitionTimeRef.current = Date.now()

    speechAnalysisRef.current = new SpeechAnalysis({
      referenceText: phrase,
      speechToken,
      onRecognizing: onRecognizing.current,
      onRecognized: onRecognized.current,
      onCancelled: () => console.log('Recognition cancelled'),
      onSessionStopped: () => console.log('Recognition session stopped'),
    })

    // Reset states
    speechResultRef.current = []

    try {
      if (!speechAnalysisRef.current) {
        throw new Error('Speech analysis not initialized')
      }

      if (oneShot) {
        console.log('Starting single-shot recognition')
        await speechAnalysisRef.current.recognizer?.recognizeOnceAsync()
      } else {
        console.log('Starting continuous recognition')
        await speechAnalysisRef.current.recognizer?.startContinuousRecognitionAsync()
      }
      isListeningRef.current = true
      setRecording(true)
      setTempFlag(true)
    } catch (err) {
      Sentry.captureException(err)
      console.error('Error accessing audio devices:', err)
    }
  }

  const handleStopRecording = () => {
    if (speechAnalysisRef?.current) {
      speechAnalysisRef?.current.close()
      isListeningRef.current = false
      setRecording(false)
    }
  }

  function reset() {
    setIsRecognizingLetters(false)
    setCurrentPhrase('')
    setAudioUrl(null)
    setRecording(false)
    setHasInterventions(false)
    setTempFlag(false)

    // Reset refs
    speechResultRef.current = []
    strongRecognizerResultsRef.current = []
    weakRecognizerResultsRef.current = {
      text: '',
      lastWord: '',
      history: [],
    }
    lessonPhraseWordsRef.current = []

    // Close any existing speech analysis
    if (speechAnalysisRef.current) {
      speechAnalysisRef.current.close()
    }

    speechAnalysisRef.current = null

    // Reset timer using the correct method name
    if (timer && timer.resetTimer) {
      timer.resetTimer()
    }

    // Reset the lastWeakRecognitionTimeRef
    lastWeakRecognitionTimeRef.current = Date.now()
  }

  const azureListenFor = async (phrase: string) => {
    console.log('Starting recognition for phrase:', phrase)
    setCurrentPhrase(phrase)

    // // Ensure we stop any existing recognition
    // if (speechAnalysisRef?.current) {
    //   await handleStopRecording()
    // }

    // // Small delay to ensure previous recognition is fully stopped
    // await new Promise((resolve) => setTimeout(resolve, 300))

    try {
      await handleStartRecording(phrase)
    } catch (error) {
      console.error('Error in azureListenFor:', error)
    }
  }

  return (
    <SpeechContext.Provider
      value={{
        speechToken,
        currentPhrase,
        audioUrl,
        recording,
        hasInterventions,
        setHasInterventions,
        weakRecognizerResultsRef,
        strongRecognizerResultsRef,
        tempFlag,
        setTempFlag,
        timer,
        isRecognizingLetters,
        setIsRecognizingLetters,
        reset,
        currentPhraseRef,
        azureListenFor,
        handleStopRecording,
        weakRecognizerUpdate,
        strongRecognizerUpdate,
        speechResultRef,
        timeSinceLastRecognitionRef,
        speechAnalysisRef,
        isListeningRef,
        setSpeechToken,
      }}
    >
      <>{children}</>
    </SpeechContext.Provider>
  )
}

export const useSpeech = () => {
  const context = useContext(SpeechContext)
  if (context === undefined) {
    throw new Error('useSpeech must be used within a SpeechProvider')
  }

  return context
}
