import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts'

import { Card } from '@/components/ui/card'
import { cn } from '@/lib/utils'

import { PeriodsBenchmark } from './types/periods'

/**
 * This is not a magic number. This is used to make sure the score dot is not cut off.
 */
const MAX_THRESHOLD_PADDING = 1.2

const BLUE = '#a9d7f4'
const GREEN = '#baefc7'
const YELLOW = '#fef0b1'
const RED = '#ffc0c0'

function PeriodChart({
  benchmark,
  title,
}: {
  period: keyof PeriodsBenchmark
  benchmark: PeriodsBenchmark[keyof PeriodsBenchmark]
  title: string
}) {
  const chartData = [
    {
      score: benchmark.score,
      max:
        (benchmark.thresholds.blue > benchmark.score ? benchmark.thresholds.blue : benchmark.score) *
        MAX_THRESHOLD_PADDING,
    },
  ]

  const tickValues = [
    benchmark.thresholds.red, // Red to Yellow transition
    benchmark.thresholds.yellow, // Yellow to Green transition
    benchmark.thresholds.green, // Green to Blue transition
    benchmark.thresholds.blue, // Blue starts
    (benchmark.thresholds.blue > benchmark.score ? benchmark.thresholds.blue : benchmark.score) * MAX_THRESHOLD_PADDING,
  ]

  return (
    <div className="flex-1">
      <h3 className="mb-2 text-center text-sm font-normal text-slate-800">{title}</h3>
      <div className="h-[300px]">
        <ResponsiveContainer
          width="100%"
          height="100%"
          className={cn(
            'overflow-hidden rounded-xl border border-border shadow-sm',
            !benchmark.date && 'opacity-60 pointer-events-none'
          )}
        >
          <LineChart
            data={chartData}
            margin={{ top: -1, right: 0, bottom: 0, left: 10 }}
            style={{
              fontFamily: 'Lexend, sans-serif',
            }}
          >
            <Tooltip
              labelFormatter={() => (benchmark.date ? `${benchmark.date.toLocaleString()}` : '')}
              contentStyle={{
                backgroundColor: 'white',
                border: '1px solid #e2e8f0',
                borderRadius: '8px',
                padding: '10px',
                fontFamily: 'Lexend, sans-serif',
                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
              }}
              itemStyle={{
                textTransform: 'capitalize',
                fontFamily: 'Lexend, sans-serif',
                fontWeight: 400,
                fontSize: 12,
                color: '#666',
              }}
              formatter={(value) => `${value} / ${chartData[0].max}`}
              labelStyle={{
                fontFamily: 'Lexend, sans-serif',
                fontWeight: 600,
                color: '#333',
                fontSize: 14,
              }}
              useTranslate3d={true}
            />

            {/* Blue band */}
            <rect
              x="0%"
              y="0%"
              width="100%"
              height={`${100 - (benchmark.thresholds.blue / chartData[0].max) * 100}%`}
              fill={BLUE}
            />

            {/* Green band */}
            <rect
              x="0%"
              y={`${100 - (benchmark.thresholds.blue / chartData[0].max) * 100}%`}
              width="100%"
              height={`${((benchmark.thresholds.blue - benchmark.thresholds.green) / chartData[0].max) * 100}%`}
              fill={GREEN}
            />

            {/* Yellow band */}
            <rect
              x="0%"
              y={`${100 - (benchmark.thresholds.green / chartData[0].max) * 100}%`}
              width="100%"
              height={`${((benchmark.thresholds.green - benchmark.thresholds.yellow) / chartData[0].max) * 100}%`}
              fill={YELLOW}
            />

            {/* Red band */}
            <rect
              x="0%"
              y={`${100 - (benchmark.thresholds.yellow / chartData[0].max) * 100}%`}
              width="100%"
              height={`${((benchmark.thresholds.yellow - benchmark.thresholds.red) / chartData[0].max) * 100}%`}
              fill={RED}
            />

            <YAxis
              tickLine={false}
              axisLine={false}
              domain={[0, chartData[0].max]}
              width={5}
              orientation="left"
              ticks={tickValues}
              tick={(props) => {
                return (
                  <text
                    x={props.x}
                    y={props.y}
                    dy="0.355em"
                    fontFamily="Lexend, sans-serif"
                    fontWeight={500}
                    fontSize={12}
                    textAnchor="start"
                    fill="black"
                    stroke="white"
                    strokeWidth="2"
                    fillOpacity={0.8}
                    strokeOpacity={0.8}
                    paintOrder="stroke"
                  >
                    {props.payload.value === chartData[0].max ? '' : props.payload.value}
                  </text>
                )
              }}
            />

            {/* Score dot */}
            {benchmark.date && (
              <Line
                type="monotone"
                dataKey="score"
                stroke="#000"
                strokeOpacity={0.5}
                dot={{ fill: '#FFF', fillOpacity: 0.5, r: 6 }}
                isAnimationActive={true}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export function GradeBenchmark({ benchmark }: { benchmark: PeriodsBenchmark }) {
  return (
    <Card className="flex-1 p-6">
      <div className="flex gap-2">
        <PeriodChart period="beginning" benchmark={benchmark.beginning} title="Beginning" />
        <PeriodChart period="middle" benchmark={benchmark.middle} title="Middle" />
        <PeriodChart period="end" benchmark={benchmark.end} title="End" />
      </div>

      <div className="mt-4 flex items-center justify-center gap-4">
        <div className="flex items-center gap-2">
          <div className="size-3 rounded-full border border-black/10" style={{ backgroundColor: BLUE }} />
          <span className="text-sm font-normal text-slate-800">Core Support</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="size-3 rounded-full border border-black/10" style={{ backgroundColor: GREEN }} />
          <span className="text-sm font-normal text-slate-800">Core Support</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="size-3 rounded-full border border-black/10" style={{ backgroundColor: YELLOW }} />
          <span className="text-sm font-normal text-slate-800">Strategic Support</span>
        </div>
        <div className="flex items-center gap-2">
          <div className="size-3 rounded-full border border-black/10" style={{ backgroundColor: RED }} />
          <span className="text-sm font-normal text-slate-800">Intensive Support</span>
        </div>
      </div>
    </Card>
  )
}
