/**
 * Returns a Promise that resolves when the media element (video/audio) is ready to play
 * @param mediaElement The video or audio element to check
 * @param timeout Optional timeout in milliseconds
 */
export const waitForMediaReady = (mediaElement: HTMLVideoElement | HTMLAudioElement, timeout = 5000): Promise<void> => {
  return new Promise((resolve, reject) => {
    // If already ready, resolve immediately
    if (mediaElement.readyState >= 4) {
      resolve()
      return
    }

    const timeoutId = setTimeout(() => {
      cleanup()
      reject(new Error('Media loading timeout'))
    }, timeout)

    const onCanPlay = () => {
      cleanup()
      resolve()
    }

    const onError = () => {
      cleanup()
      reject(new Error('Media loading failed'))
    }

    function cleanup() {
      clearTimeout(timeoutId)
      mediaElement.removeEventListener('canplaythrough', onCanPlay)
      mediaElement.removeEventListener('error', onError)
    }

    mediaElement.addEventListener('canplaythrough', onCanPlay)
    mediaElement.addEventListener('error', onError)
  })
}
