import lodash from 'lodash'
import * as sdk from 'microsoft-cognitiveservices-speech-sdk'

import { cleanString } from './string'

export class SpeechAnalysis {
  constructor({ referenceText, speechToken, onRecognizing, onRecognized, onCancelled, onSessionStopped }: any) {
    this.referenceText = cleanString(referenceText)

    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(speechToken.token, speechToken.region)

    speechConfig.speechRecognitionLanguage = localStorage.getItem('user-locale') ?? 'en-US'
    this.speechConfig = speechConfig

    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput()

    const pronunciationAssessmentConfig = new sdk.PronunciationAssessmentConfig(
      cleanString(referenceText),
      sdk.PronunciationAssessmentGradingSystem.HundredMark,
      sdk.PronunciationAssessmentGranularity.Phoneme,
      true
    )

    pronunciationAssessmentConfig.enableProsodyAssessment = true

    // pronunciationAssessmentConfig.phonemeAlphabet = 'IPA'
    pronunciationAssessmentConfig.phonemeAlphabet = 'sapi'

    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig)
    pronunciationAssessmentConfig.applyTo(recognizer)

    recognizer.sessionStopped = function () {
      recognizer.stopContinuousRecognitionAsync()
      recognizer.close()

      // self.calculateOverallPronunciationScore();
      onSessionStopped()
    }

    recognizer.canceled = function (_, e) {
      if (e.reason === sdk.CancellationReason.Error) {
        const str = '(cancel) Reason: ' + sdk.CancellationReason[e.reason] + ': ' + e.errorDetails
        console.log('Recognizer canceled', str)
      }
      recognizer.stopContinuousRecognitionAsync()
      onCancelled()
    }

    recognizer.recognizing = (_, e: any) => {
      onRecognizing(e)
      console.log('API recognizing results', e.result.text)
      this.recognizingText = e.result.text
      this.recognizedWordsBeforeAnalysis = e.result.text.split(' ').toLowerCase()
    }

    recognizer.recognized = (_, e) => {
      this.jo = JSON.parse(e.result.properties.getProperty(sdk.PropertyId.SpeechServiceResponse_JsonResult))
      const nb = this.jo['NBest'][0]
      console.log('API recognized results', nb)
      this.pronunciationAssessment = nb.PronunciationAssessment
      this.startOffset = nb.Words[0].Offset
      const localtext = lodash.map(nb.Words, (item) => item.Word.toLowerCase())
      this.currentText = this.currentText.concat(localtext)
      this.fluencyScores.push(nb.PronunciationAssessment.FluencyScore)
      this.prosodyScores.push(nb.PronunciationAssessment.ProsodyScore)
      const isSucceeded = this.jo.RecognitionStatus === 'Success'
      const nBestWords = this.jo.NBest[0].Words
      this.currentSpeechResult = nBestWords

      const durationList: any = []
      lodash.forEach(nBestWords, (word) => {
        if (!this) return
        this.recognizedWords.push(word)
        durationList.push(word.Duration)
      })
      this.durations.push(lodash.sum(durationList))

      if (isSucceeded && nBestWords) {
        this.allWords.push(...nBestWords)
      }
      this.lastWords = nBestWords
      // self.calculateOverallPronunciationScore();
      onRecognized(e)
    }

    this.recognizer = recognizer
  }

  speechConfig: any
  pronunciationAssessment: any
  recognizer: any
  recognizedWordsBeforeAnalysis: any = []
  recognizingText: string = ''
  scoreNumber: any = {
    accuracyScore: 0,
    fluencyScore: 0,
    compScore: 0,
    prosodyScore: 0,
  }
  allWords: any = []
  notStarted = true
  currentText: any = []
  startOffset = 0
  recognizedWords: any = []
  fluencyScores: any = []
  prosodyScores: any = []
  durations: any = []
  jo: any = {}
  language = 'en-US'
  lastWords: any = []
  lastWord: string = ''
  currentSpeechResult: any = []
  referenceText = ''

  updateSpeechToken(newSpeechToken: string) {
    this.speechConfig.setProperty(sdk.PropertyId.SpeechServiceAuthorization_Token, newSpeechToken)
  }

  reset() {
    this.scoreNumber = {
      accuracyScore: 0,
      fluencyScore: 0,
      compScore: 0,
      prosodyScore: 0,
    }
    this.allWords = []
    this.currentText = []
    this.startOffset = 0
    this.recognizedWords = []
    this.fluencyScores = []
    this.prosodyScores = []
    this.durations = []
    this.jo = {}
    this.lastWords = []
    this.lastWord = ''
  }

  close() {
    console.log('Closed mic')
    if (!this.recognizer) return
    this.recognizer.stopContinuousRecognitionAsync(() => {
      this.recognizer?.close()
      this.recognizer = null
    })
  }
}
