import { useUser } from '@clerk/clerk-react'
import axios from 'axios'
import React, { createContext, ReactNode, useRef, useState } from 'react'

import { useFileUpload } from '@/hooks/useFileUpload'
import { getFileChecksum } from '@/utils/upload'

const CHUNK_SIZE = 10 * 1024 * 1024 // 10MB in bytes
const UPLOAD_TIMEOUT = 1000 * 60 * 10 // 10 minutes

interface AudioContextType {
  audioUrl: string | null
  isRecording: boolean
  audioBlob: Blob | null
  s3Url: string | null
  mediaStream: MediaStream | null
  showVisualizer: boolean
  startRecording: (fileName: string) => Promise<void>
  stopRecording: () => Promise<{ s3Url: string; s3Key: string } | undefined>
  setAudioUrl: (url: string | null) => void
  setShowVisualizer: (show: boolean) => void
  uploadToS3: (file: File) => Promise<{ s3Url: string; s3Key: string } | undefined>
}

const AudioContext = createContext<AudioContextType | undefined>(undefined)

interface AudioProviderProps {
  children: ReactNode
}

function getSupportedMimeType() {
  const types = ['audio/mp4;codecs=mp4a.40.2', 'audio/webm', 'audio/webm;codecs=opus', 'audio/ogg;codecs=opus']

  for (const type of types) {
    if (MediaRecorder.isTypeSupported(type)) {
      return type
    }
  }

  return 'audio/webm' // fallback
}

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const { initiateUpload, completeUpload, uploadFileParts, generateSinglePresignedUrl } = useFileUpload()
  const { user } = useUser()
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [isRecording, setIsRecording] = useState(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const [s3Url, setS3Url] = useState<string | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const chunksRef = useRef<Blob[]>([])
  const fileNameRef = useRef<string>('')
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [showVisualizer, setShowVisualizer] = useState(true)

  async function uploadToS3(file: File) {
    const checksum = await getFileChecksum(file)
    const fileType = file.type
    const fileName = `${user?.id || 'unknown_user'}/${Date.now()}/${file.name}`

    try {
      if (file.size > CHUNK_SIZE) {
        const fileWithPath = new File([file], fileName, { type: file.type })

        const { uploadId } = await initiateUpload({
          fileName,
        })

        const uploadedParts = await uploadFileParts({
          file: fileWithPath,
          uploadId,
          updateProgress: () => {},
        })

        const response = await completeUpload({
          fileName,
          uploadId,
          parts: uploadedParts,
          checksum,
          fileType,
        })

        return {
          s3Url: response.data.s3Url,
          s3Key: response.data.s3Key,
        }
      } else {
        const { presignedUrl, s3Key, s3Url } = await generateSinglePresignedUrl({
          key: fileName,
          checksum,
          fileType,
        })

        let retryCount = 0
        const maxRetries = 3

        while (retryCount < maxRetries) {
          try {
            const response = await axios.put(presignedUrl, file, {
              headers: {
                'Content-Type': file.type,
              },
              timeout: UPLOAD_TIMEOUT,
              maxBodyLength: Infinity,
              onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
                console.log('Upload progress:', percentCompleted)
              },
            })

            if (response.status === 200) {
              return {
                s3Url,
                s3Key,
              }
            }
            throw new Error('Upload failed')
          } catch (error) {
            console.error('Upload error details:', {
              error,
            })

            // Check if request was canceled
            if (axios.isCancel(error)) {
              console.log('Request was canceled, reason:', error?.message)
            }

            retryCount++
            if (retryCount === maxRetries) {
              throw error
            }
            await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, retryCount)))
            console.log(`Retrying upload attempt ${retryCount} of ${maxRetries}`)
          }
        }
      }
    } catch (error) {
      console.error('Failed to upload file:', error)
      if (axios.isAxiosError(error)) {
        if (error.code === 'ECONNABORTED') {
          throw new Error('Upload timed out after multiple attempts. Please try again with a better connection.')
        }
        throw new Error(`Upload failed: ${error.message}`)
      }
      throw error
    }
  }

  const startRecording = async (fileName: string) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      })

      console.log('Stream created:', stream.active)
      setMediaStream(stream)

      const mimeType = getSupportedMimeType()
      console.log('Using MIME type:', mimeType)

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000,
      })

      mediaRecorderRef.current = mediaRecorder
      chunksRef.current = []
      fileNameRef.current = fileName

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data)
        }
      }

      mediaRecorder.onstop = async () => {
        const mimeType = mediaRecorder.mimeType || 'audio/webm'
        const blob = new Blob(chunksRef.current, { type: mimeType })
        const file = new File([blob], fileNameRef.current, { type: 'audio/mp4' })
        setAudioBlob(file)
        const url = URL.createObjectURL(file)
        setAudioUrl(url)

        try {
          await uploadToS3(file)
        } catch (error) {
          console.error('Failed to upload recording:', error)
        }
      }

      mediaRecorder.start()
      setIsRecording(true)
      setS3Url(null)
      setAudioBlob(null)
    } catch (error) {
      console.error('Error starting recording:', error)
      throw error
    }
  }

  const stopRecording = () => {
    return new Promise<{ s3Url: string; s3Key: string } | undefined>((resolvePromise) => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.onstop = async () => {
          const mimeType = mediaRecorderRef.current?.mimeType || 'audio/webm'
          const blob = new Blob(chunksRef.current, { type: mimeType })
          const file = new File([blob], fileNameRef.current, { type: 'audio/mp4' })
          setAudioBlob(blob)
          const url = URL.createObjectURL(blob)
          setAudioUrl(url)

          try {
            const result = await uploadToS3(file)
            setAudioUrl(null)
            setAudioBlob(null)
            setS3Url(null)
            chunksRef.current = []
            fileNameRef.current = ''

            resolvePromise({
              s3Url: result?.s3Url || '',
              s3Key: result?.s3Key || '',
            })
          } catch (error) {
            console.error('Failed to upload recording:', error)
            resolvePromise(undefined)
          }
        }

        // Stop all tracks in the stream
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => track.stop())
        }

        mediaRecorderRef.current.stop()
        setIsRecording(false)
        setMediaStream(null)
      } else {
        resolvePromise(undefined)
      }
    })
  }

  return (
    <AudioContext.Provider
      value={{
        audioUrl,
        isRecording,
        audioBlob,
        s3Url,
        mediaStream,
        showVisualizer,
        startRecording,
        stopRecording,
        setAudioUrl,
        setShowVisualizer,
        uploadToS3,
      }}
    >
      {children}
    </AudioContext.Provider>
  )
}

export const useAudioRecording = (): AudioContextType => {
  const context = React.useContext(AudioContext)
  if (context === undefined) {
    throw new Error('useAudioRecording must be used within an AudioProvider')
  }
  return context
}
