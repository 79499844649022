import { Role } from '@alpha-school/reading-fluency-models'
import { useAuth, useUser } from '@clerk/clerk-react'
import { Link, useLocation } from 'react-router-dom'

import cubesBottomRight from '@/assets/cubes_bottom_right.png'
import ondulationBg from '@/assets/ondulationBg.svg'
import { cn } from '@/lib/utils'

const AdminSidebar = () => {
  const location = useLocation()
  const urlParams = new URLSearchParams(window.location.search)
  const { user: clerkUser } = useUser()
  const { orgRole } = useAuth()
  const email = clerkUser?.emailAddresses[0]?.emailAddress

  const links = [
    { to: `/admin/dashboard?${urlParams.toString()}`, label: 'Dashboard' },
    { to: `/admin/students?${urlParams.toString()}`, label: 'Students' },
    { to: `/admin/invite?${urlParams.toString()}`, label: 'Invite' },
    ...(email?.endsWith('@ae.studio')
      ? [{ to: `/admin/assessments?${urlParams.toString()}`, label: 'Assessments' }]
      : []),
    ...(email?.endsWith('@ae.studio') && [Role.ADMIN, Role.FLUENCY_ADMIN].includes(orgRole as Role)
      ? [{ to: `/admin/avatar-settings?${urlParams.toString()}`, label: 'Avatar Settings' }]
      : []),
  ]

  return (
    <div className="sticky left-0 top-0 h-screen w-64 bg-purple-gradient pt-16 text-white">
      <div
        className="relative size-full border-t border-white/15 bg-cover bg-[40%] bg-no-repeat p-4 pt-8"
        style={{ backgroundImage: `url(${ondulationBg})` }}
      >
        <img src={cubesBottomRight} className="absolute bottom-0 right-0 z-0" />
        <div className="mb-8">
          <h1 className="text-xl font-bold">Admin Panel</h1>
        </div>

        <nav>
          <ul className="space-y-2">
            {links.map((link) => (
              <li key={link.to}>
                <Link
                  to={link.to}
                  className={cn(
                    'block rounded px-4 py-2 transition-colors hover:text-white',
                    location.pathname.startsWith(link.to.split('?')[0]) ? 'text-white' : 'text-gray-400'
                  )}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default AdminSidebar
