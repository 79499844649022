import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { BarChart, ExternalLink, Loader2Icon, Trash2 } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactSortable, Sortable } from 'react-sortablejs'

import CompositeScore from '@/components/Admin/Dashboards/CompositeScore/CompositeScore'
import HeaderDetails from '@/components/Admin/Dashboards/HeaderDetails'
import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { LoadingIcon } from '@/components/Loading/Loading'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useToast } from '@/hooks/use-toast'
import { kebabToCamelCase } from '@/lib/utils'
import { LessonAssignment } from '@/types/lesson-assignment'
import {
  assignLesson,
  deleteLessonAssignment,
  getLessonAssignments,
  getLessons,
  getStudentDetails,
  getStudentLessons,
  reorderAssignment,
} from '@/utils/api'

const lessonsBlackList = [
  'letter-sound-completions-fluency',
  'oral-reading-fluency',
  'phoneme-segmentation-fluency',
  'maze',
]

function StudentDetails() {
  const queryString = window.location.search
  const { id } = useParams<{ id: string }>()
  const { toast } = useToast()
  const [sortedList, setSortedList] = useState<LessonAssignment[]>([])
  const [isAssigning, setIsAssigning] = useState(false)
  const [selectedLesson, setSelectedLesson] = useState('')
  const queryClient = useQueryClient()
  const [isAssigningLoading, setIsAssigningLoading] = useState(false)

  const navigate = useNavigate()

  const {
    data: student,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['student', id],
    queryFn: async () => {
      const response = await getStudentDetails(id!)
      return response.data
    },
    staleTime: 1000000,
  })

  const { data: lessons } = useQuery({
    queryKey: ['lessons'],
    queryFn: async () => {
      const { data } = await getLessons()
      return data
    },
  })

  const lessonMap = useMemo(() => {
    if (!lessons) return new Map()
    return new Map((lessons ?? []).map((lesson) => [lesson.id, lesson.title]))
  }, [lessons])

  const {
    data: studentLessons,
    isLoading: isLoadingStudentLessons,
    isFetching: isFetchingStudentLessons,
  } = useQuery({
    queryKey: ['student-lessons', id],
    queryFn: async () => {
      const { data } = await getStudentLessons(id!)
      return data
    },
    enabled: !!id,
  })

  const prefetchLessonAssignments = useCallback(
    (lessonId: string) => {
      queryClient.prefetchQuery({
        queryKey: ['lesson-assignments', id, lessonId],
        queryFn: async () => {
          const { data } = await getLessonAssignments(id!, lessonId)
          return data
        },
      })
    },
    [queryClient, id]
  )

  const assignLessonMutation = useMutation({
    mutationFn: ({ userId, lessonId }: { userId: string; lessonId: string }) => {
      setIsAssigningLoading(true)
      return assignLesson(userId, lessonId)
    },
    onSettled: async () => {
      setIsAssigning(false)
      setSelectedLesson('')
      setIsAssigningLoading(false)
      await queryClient.refetchQueries({ queryKey: ['student-lessons', id] })
    },
  })

  const deleteLessonMutation = useMutation({
    mutationFn: (assignmentId: string) => deleteLessonAssignment(assignmentId),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['student-lessons', id] })
    },
  })

  const handleDeleteAssignment = useCallback(
    (assignmentId: string) => {
      if (window.confirm('Are you sure you want to delete this assignment?')) {
        deleteLessonMutation.mutate(assignmentId)
      }
    },
    [deleteLessonMutation]
  )

  const prepareAssignments = useCallback(
    (assignments: LessonAssignment[], completed: boolean) =>
      assignments.filter((assignment) => (completed ? assignment.completedAt : !assignment.completedAt)),
    []
  )

  const lessonsWithOngoingAssignments = useMemo(
    () =>
      prepareAssignments(studentLessons ?? [], false).sort(
        (a: LessonAssignment, b: LessonAssignment) => (a.position ?? 0) - (b.position ?? 0)
      ),
    [studentLessons, prepareAssignments]
  )

  const lessonsWithCompletedAssignments = useMemo(
    () => prepareAssignments(studentLessons ?? [], true),
    [studentLessons, prepareAssignments]
  )

  const reorderAssignmentMutation = useMutation({
    mutationFn: (payload: { orderedIds: string[]; studentId: string }) => reorderAssignment(payload),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['student-lessons', id] })
    },
  })

  const handleSort = (e: Sortable.SortableEvent) => {
    const newState = Array.from(e.to.children).map((el) => ({
      id: el.getAttribute('data-assignment-id'),
    })) as LessonAssignment[]

    if (!newState.length) return

    const orderedIds = newState.map((assignment) => assignment.id!)

    reorderAssignmentMutation.mutate(
      {
        orderedIds,
        studentId: id!,
      },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            duration: 1500,
            title: 'Assignments reordered',
            description: 'The assignments have been reordered successfully',
          })
        },
      }
    )
  }

  useEffect(() => {
    if (lessonsWithOngoingAssignments) {
      setSortedList(lessonsWithOngoingAssignments)
    }
  }, [lessonsWithOngoingAssignments])

  return (
    <AdminLayout
      title="Student Details"
      backUrl={`/admin/students${queryString}`}
      headerAction={
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="primary-alt" className="flex items-center gap-2" disabled={!student?.id}>
              <BarChart className="size-4" />
              See student benchmark
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-3xl">
            <DialogHeader>
              <DialogTitle>See student benchmark</DialogTitle>
            </DialogHeader>
            <DialogDescription>See the student's benchmark for each period.</DialogDescription>
            <CompositeScore student={student!} />
          </DialogContent>
        </Dialog>
      }
    >
      {!student && !isLoading && !isFetching ? (
        'Student not found'
      ) : (
        <>
          <div className="flex flex-row justify-between p-8 pt-24">
            <div className="text-gray-600">
              <HeaderDetails
                loading={isLoading || isFetching}
                name={student?.name}
                email={student?.email}
                grade={student?.grade}
                createdAt={student?.createdAt}
              />
            </div>

            <div className="flex items-end justify-end">
              <div className="flex items-center justify-between">
                <Button
                  variant="primary-alt"
                  onClick={() => setIsAssigning(true)}
                  disabled={assignLessonMutation.isPending || isLoading || isFetching}
                >
                  Assign Assessment
                </Button>
              </div>
            </div>
          </div>

          <div className="px-8 pb-4">
            {isAssigning && (
              <Card className="mt-8">
                <CardHeader>
                  <CardTitle>Assign Assessment</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex gap-4">
                    <select
                      value={selectedLesson}
                      onChange={(e) => setSelectedLesson(e.target.value)}
                      className="flex-1 rounded border px-3 py-2"
                    >
                      <option value="">Select a assignment...</option>
                      {lessons
                        ?.filter((lesson) => !lessonsBlackList.includes(lesson.id) && lesson.active)
                        ?.map((lesson) => (
                          <option key={lesson.id} value={lesson.id}>
                            {lesson.title}
                          </option>
                        ))}
                    </select>
                    <button
                      onClick={() => {
                        if (selectedLesson && id) {
                          assignLessonMutation.mutate({ userId: id, lessonId: selectedLesson })
                        }
                      }}
                      disabled={!selectedLesson || assignLessonMutation.isPending}
                      className="flex items-center gap-2 rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600 disabled:bg-gray-400"
                    >
                      {assignLessonMutation.isPending ? 'Assigning...' : 'Confirm'}
                      {isAssigningLoading && <Loader2Icon className="size-4 animate-spin" />}
                    </button>
                    <button
                      onClick={() => {
                        setIsAssigning(false)
                        setSelectedLesson('')
                      }}
                      className="rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
                    >
                      Cancel
                    </button>
                  </div>
                </CardContent>
              </Card>
            )}

            {/* Ongoing Assessments */}
            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Ongoing Assessments</CardTitle>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Assessment Name</TableHead>
                      <TableHead>Assigned At</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>

                  <ReactSortable
                    list={sortedList}
                    setList={setSortedList}
                    onUpdate={handleSort}
                    animation={150}
                    handle=".drag-handle"
                    tag="tbody"
                    ghostClass="ghost-sortable"
                    dragClass="cursor-grabbing"
                  >
                    {isLoadingStudentLessons || isFetchingStudentLessons ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="flex h-24 items-center justify-center">
                            <LoadingIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : sortedList.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="flex h-24 items-center justify-center text-sm text-gray-500">
                            No ongoing assessments found
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      sortedList.map((assignment, index) => (
                        <TableRow
                          key={`${assignment.lessonId}-${assignment.id}`}
                          className="hover:bg-gray-50"
                          data-assignment-id={assignment.id}
                        >
                          <TableCell>
                            <span className="mr-4 text-gray-400">{index + 1}.</span>
                            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
                            <span className="drag-handle mr-4 cursor-grab">⋮⋮</span>
                            {lessonMap.get(assignment.lessonId)}
                          </TableCell>
                          <TableCell>{new Date(assignment.startedAt).toLocaleString()}</TableCell>
                          <TableCell className="text-right">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleDeleteAssignment(assignment.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="size-4" />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </ReactSortable>
                </Table>
              </CardContent>
            </Card>

            {/* Completed Assessments */}
            <Card className="mt-8">
              <CardHeader>
                <CardTitle>Completed Assessments</CardTitle>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Assessment Name</TableHead>
                      <TableHead>Last Completed Date</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isLoadingStudentLessons || isFetchingStudentLessons ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="flex h-24 items-center justify-center">
                            <LoadingIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : lessonsWithCompletedAssignments?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <div className="flex h-24 items-center justify-center text-sm text-gray-500">
                            No completed assessments found
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      lessonsWithCompletedAssignments?.map((lesson) => (
                        <TableRow
                          key={lesson.lessonId}
                          className="hover:bg-gray-50"
                          onMouseEnter={() => prefetchLessonAssignments(lesson.lessonId)}
                        >
                          <TableCell className="">{lessonMap.get(lesson.lessonId)}</TableCell>
                          <TableCell className="">{new Date(lesson.completedAt).toLocaleString()}</TableCell>
                          <TableCell className="text-right">
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => {
                                navigate(`/admin/students/${id}/${kebabToCamelCase(lesson.lessonId)}${queryString}`)
                              }}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <ExternalLink className="mr-2 size-4" />
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
        </>
      )}
    </AdminLayout>
  )
}

export default StudentDetails
