import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { createContext, useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useConfetti } from '@/context/ConfettiContext/ConfettiContext'
import { useSpeech } from '@/context/SpeechContext'
import { useTimer } from '@/hooks/useTimer'
import { useUser } from '@/hooks/useUser'
import { createLessonAssignmentResult, updateLessonAssignment } from '@/utils/api'

const MasteryLessonContext = createContext<any | undefined>(undefined)

export const MasteryLessonProvider: React.FC<any> = ({ children }) => {
  const { user, userAssignments, fetchUserAssignments } = useUser()
  const { reset: resetSpeechContext } = useSpeech()
  const navigate = useNavigate()
  const timer = useTimer()
  const { fireConfetti } = useConfetti()

  const [initialized, setInitialized] = useState(false)
  const [data, setData] = useState<any>(null)
  const [currentLessonAssignmentId, setCurrentLessonAssignmentId] = useState<any>(null)
  const [currentLessonId, setCurrentLessonId] = useState<any>(null)
  const [currentExercise, setCurrentExercise] = useState(0)
  const [currentActivity, setCurrentActivity] = useState(0)
  const [exerciseType, setExerciseType] = useState('')
  const [activityType, setActivityType] = useState('')
  const [avatarState, setAvatarState] = useState<string>('')
  const [lessonCompleted, setLessonCompleted] = useState(false)
  const [resultsJson, setResultsJson] = useState<any>(null)
  const rawResults = useRef<any>(null)
  const [showNextLessonButton, setShowNextLessonButton] = useState(false)
  const [loadingNextLesson, setLoadingNextLesson] = useState(false)
  const queryClient = useQueryClient()
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [showProgress, setShowProgress] = useState(false)

  async function loadNextLessonLesson() {
    setLoadingNextLesson(true)
    const updatedUserAssignments = await fetchUserAssignments()
    if (!updatedUserAssignments || updatedUserAssignments?.length === 0) {
      initialized && navigate('/all-done', { state: { origin: 'lesson' } })
      return
    }

    const currentAssignment = updatedUserAssignments[0]
    const lessonData = currentAssignment.lesson

    setShowNextLessonButton(false)

    setData(lessonData)
    setCurrentLessonId(lessonData.id)
    setCurrentLessonAssignmentId(currentAssignment?.id)
    setCurrentExercise(0)
    setCurrentActivity(0)
    setExerciseType(lessonData.exercises[0].type)
    setActivityType(lessonData.exercises[0].activities[0].type)
    setLoadingNextLesson(false)
  }

  async function completeLesson() {
    try {
      await updateLessonAssignment({
        lessonAssignmentId: currentLessonAssignmentId,
        status: 'COMPLETED',
        result: rawResults.current,
      })
      resetSpeechContext()
      setResultsJson(null)
      rawResults.current = null
      if (userAssignments && userAssignments?.length <= 1) {
        navigate('/all-done', { state: { origin: 'lesson' } })
        return
      }
      setShowNextLessonButton(true)
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error updating lesson assignment', error)
    }
  }

  function moveToNextExercise() {
    setCurrentExercise(currentExercise + 1)
    setCurrentActivity(0)
    setActivityType(data.exercises[currentExercise + 1].activities[0].type)
    setExerciseType(data.exercises[currentExercise + 1].type)
  }

  async function captureMessage(message: string) {
    Sentry.captureMessage(`user:${user?.id} - ${message}`, {
      level: 'info',
      extra: {
        currentLessonAssignmentId,
        currentLessonId,
        currentExercise,
        currentActivity,
        user,
      },
    })
  }

  async function next() {
    fireConfetti()
    captureMessage('Next triggered')
    if (currentActivity < data?.exercises?.[currentExercise]?.activities?.length - 1) {
      captureMessage('Setting next activity')
      setCurrentActivity(currentActivity + 1)
      setActivityType(data?.exercises?.[currentExercise]?.activities?.[currentActivity + 1]?.type)
    } else if (currentExercise < data?.exercises?.length - 1) {
      captureMessage('Moving to next exercise')
      moveToNextExercise()
    } else {
      captureMessage('Completing lesson')
      await completeLesson()
    }
    queryClient.invalidateQueries({ queryKey: ['student', user?.id] })
    queryClient.invalidateQueries({ queryKey: ['student-lessons', user?.id] })
    queryClient.invalidateQueries({ queryKey: ['lesson-assignments', user?.id, currentLessonId] })
    queryClient.invalidateQueries({ queryKey: ['student-results', user?.id, currentLessonId] })
  }

  function getActivityResults() {
    return rawResults.current?.exercises?.[currentExercise]?.activities?.[currentActivity]?.results
  }

  function getExerciseResults() {
    return rawResults.current?.exercises?.[currentExercise]?.results
  }

  function setActivityResults(results: any) {
    rawResults.current = {
      ...rawResults.current,
      exercises: {
        ...(rawResults.current?.exercises || {}),
        [currentExercise]: {
          ...(rawResults.current?.exercises?.[currentExercise] || {}),
          activities: {
            ...(rawResults.current?.exercises?.[currentExercise]?.activities || {}),
            [currentActivity]: {
              results,
            },
          },
        },
      },
    }
  }

  function saveLessonAssignmentResult(results: any) {
    createLessonAssignmentResult({
      userId: user!.id,
      lessonAssignmentId: currentLessonAssignmentId,
      exerciseId: data.exercises[currentExercise].id,
      activityId: data.exercises[currentExercise].activities[currentActivity].id,
      status: 'COMPLETED',
      result: results,
      locale: localStorage.getItem('user-locale') ?? 'en-US',
    }).catch((err) => {
      Sentry.captureException(err)
      console.error('Error creating lesson assignment result', err)
    })
  }

  function setExerciseResults(results: any) {
    rawResults.current = {
      ...rawResults.current,
      exercises: {
        ...(rawResults.current?.exercises || {}),
        [currentExercise]: {
          ...(rawResults.current?.exercises?.[currentExercise] || {}),
          results,
        },
      },
    }
  }

  async function skipExercise() {
    const currentExerciseActivities = data.exercises[currentExercise].activities

    for (let i = currentActivity; i < currentExerciseActivities.length; i++) {
      const skippedResult = {
        skipped: true,
        timestamp: new Date().toISOString(),
      }

      setActivityResults(skippedResult)
      saveLessonAssignmentResult(skippedResult)
    }

    if (currentExercise < data.exercises.length - 1) {
      moveToNextExercise()
    } else {
      await completeLesson()
    }
  }

  return (
    <MasteryLessonContext.Provider
      value={{
        currentExercise,
        setCurrentExercise,
        currentActivity,
        setCurrentActivity,
        exerciseType,
        setExerciseType,
        activityType,
        setActivityType,
        next,
        avatarState,
        setAvatarState,
        lessonCompleted,
        setLessonCompleted,
        resultsJson,
        setResultsJson,
        setRawResults: (value: any) => (rawResults.current = value),
        setActivityResults,
        setExerciseResults,
        rawResults: rawResults,
        currentLessonId,
        currentLessonAssignmentId,
        saveLessonAssignmentResult,
        data,
        timer,
        getActivityResults,
        getExerciseResults,
        skipExercise,
        initialized,
        setInitialized,
        loadNextLessonLesson,
        showNextLessonButton,
        loadingNextLesson,
        setShowNextLessonButton,
        progressPercentage,
        setProgressPercentage,
        showProgress,
        setShowProgress,
      }}
    >
      {children}
    </MasteryLessonContext.Provider>
  )
}

export const useMasteryLesson = () => {
  const context = useContext(MasteryLessonContext)
  if (context === undefined) {
    throw new Error('useMasteryLesson must be used within an MasteryLessonProvider')
  }
  return context
}
