import { OralReadingFluencyAnalysis, OralReadingFluencyResult } from '@/types/assessments/oral-reading-fluency'

export function parseOralReadingFluency(
  assessmentResult: OralReadingFluencyResult,
  _pronunciationScoreThreshold: number,
  _useAzureMispronunciationFlag: boolean
): OralReadingFluencyAnalysis {
  const analysis: OralReadingFluencyAnalysis = {
    originalData: assessmentResult,
    correctCount: 0,
    incorrectCount: 0,
    correctWords: [],
    insertedWords: [],
    omittedWords: [],
    mispronunciationWords: [],
    commonlyMissedWords: [],
    totalScore: 0,
  }

  // Get the first exercise and activity (assuming alphabet exercise)
  const exercise = assessmentResult.exercises['0']
  const activity = exercise.activities['0']

  activity.results.words.forEach((word) => {
    const isMispronunciationErrorByScore = word.PronunciationAssessment.AccuracyScore < 50
    const isInsertionError = word.PronunciationAssessment.ErrorType === 'Insertion'
    const isOmissionError = word.PronunciationAssessment.ErrorType === 'Omission'

    const isCorrect = !isMispronunciationErrorByScore && !isInsertionError && !isOmissionError

    if (isCorrect) {
      analysis.correctCount++
      analysis.correctWords.push(word.Word)
    }

    if (isMispronunciationErrorByScore && !isInsertionError && !isOmissionError) {
      analysis.incorrectCount++
      analysis.mispronunciationWords.push(word.Word)
      analysis.commonlyMissedWords.push({
        word: word.Word,
        accuracy: word.PronunciationAssessment.AccuracyScore,
      })
    }

    if (isInsertionError) {
      analysis.insertedWords.push(word.Word)
    }

    if (isOmissionError) {
      analysis.incorrectCount++
      analysis.omittedWords.push(word.Word)
    }
  })

  // Sort commonly missed letters by accuracy (ascending)
  analysis.commonlyMissedWords.sort((a, b) => a.accuracy - b.accuracy)

  return analysis
}
