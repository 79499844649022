/* eslint-disable tailwindcss/no-custom-classname */
import { useAvatar } from 'alpha-ai-avatar-sdk-react'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { RichAvatar } from '@/components/Avatar/RichAvatar'

interface Position {
  top: number
  left: number
}

interface AvatarWrapperContextType {
  position: Position
  setPosition: (position: Position) => void
  isVisible: boolean
  isGlobalAvatarPlaceholder: boolean
  findPlaceholder: () => void
  sayAndWait: (text: string, duration: number) => Promise<void>
}

const AvatarWrapperContext = createContext<AvatarWrapperContextType | null>(null)

interface Props {
  children: ReactNode
}

export function AvatarWrapperProvider({ children }: Props) {
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 })
  const [isVisible, setIsVisible] = useState(false)
  const [isGlobalAvatarPlaceholder, setIsGlobalAvatarPlaceholder] = useState(true)
  const location = useLocation()
  const { say } = useAvatar()

  async function sayAndWait(text: string, duration: number) {
    say(text)
    await new Promise((resolve) => setTimeout(resolve, duration))
  }

  const findPlaceholder = useCallback(() => {
    const overrideAvatarPlaceholder = document.querySelector('.override-avatar-placeholder')

    let placeholder

    if (overrideAvatarPlaceholder) {
      placeholder = overrideAvatarPlaceholder
      setIsGlobalAvatarPlaceholder(false)
    } else {
      placeholder = document.querySelector('.global-avatar-placeholder')
      setIsGlobalAvatarPlaceholder(true)
    }

    if (placeholder) {
      const placeholderRect = placeholder.getBoundingClientRect()
      const avatar = document.querySelector('.avatar-wrapper')
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      if (avatar) {
        const avatarRect = avatar.getBoundingClientRect()
        const top = placeholderRect.top + scrollTop + (placeholderRect.height - avatarRect.height) / 2
        const left = placeholderRect.left + (placeholderRect.width - avatarRect.width) / 2
        setPosition({ top, left })
      } else {
        const estimatedAvatarSize = { width: 200, height: 200 }
        const top = placeholderRect.top + scrollTop + (placeholderRect.height - estimatedAvatarSize.height) / 2
        const left = placeholderRect.left + (placeholderRect.width - estimatedAvatarSize.width) / 2
        setPosition({ top, left })
      }
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [])

  useEffect(() => {
    findPlaceholder()
    const initialCheck = setInterval(() => {
      findPlaceholder()
    }, 100)

    // Clear initial check after 10 seconds
    setTimeout(() => {
      clearInterval(initialCheck)
    }, 10000)

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          setTimeout(() => {
            findPlaceholder()
          }, 100)
        }
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ['class'],
    })

    return () => {
      clearInterval(initialCheck)
      observer.disconnect()
    }
  }, [location.pathname, findPlaceholder])

  useEffect(() => {
    let resizeTimeout: NodeJS.Timeout

    const handleResize = () => {
      clearTimeout(resizeTimeout)
      resizeTimeout = setTimeout(() => {
        findPlaceholder()
      }, 100)
    }

    window.addEventListener('resize', handleResize)
    window.addEventListener('scroll', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('scroll', handleResize)
      clearTimeout(resizeTimeout)
    }
  }, [findPlaceholder])

  return (
    <AvatarWrapperContext.Provider
      value={{ sayAndWait, findPlaceholder, position, setPosition, isVisible, isGlobalAvatarPlaceholder }}
    >
      <div className="relative flex size-full items-center justify-center">
        <div
          className="avatar-wrapper"
          style={{
            position: 'absolute',
            top: position.top,
            left: position.left,
            display: isVisible ? 'flex' : 'none',
            zIndex: 51,
            transition: 'all 0.3s ease-in-out',
            opacity: isVisible ? 1 : 0,
            pointerEvents: isVisible ? 'auto' : 'none',
          }}
        >
          <RichAvatar message="" />
        </div>
        {children}
      </div>
    </AvatarWrapperContext.Provider>
  )
}

export function useAvatarWrapper() {
  const context = useContext(AvatarWrapperContext)
  if (!context) {
    throw new Error('useAvatarWrapper must be used within an AvatarWrapperProvider')
  }
  return context
}
