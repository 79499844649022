import { useState } from 'react'

import { ScoreLetter } from '@/components/Admin/Dashboards/LetterNamingFluency/types/ScoreLetter'
import { ScoreWithDate } from '@/components/Admin/Dashboards/LetterNamingFluency/types/ScoreWithDate'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { ErrorType } from '@/types/assessments/azure'
import { LetterNamingFluencyResult } from '@/types/assessments/letter-naming-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'
import { LNF_ACCURACY_SCORE_THRESHOLD } from '@/utils/constants'

export const Letter = ({
  letter,
  index,
  score,
  assessment,
  handleSave,
}: {
  letter: ScoreLetter
  index: number
  score: ScoreWithDate
  assessment: LessonAssignment<'letter-naming-fluency'>
  handleSave: (result: LetterNamingFluencyResult) => void
}) => {
  const [spokenLetter, setSpokenLetter] = useState<string>(letter.Word)
  const [letterScore, setLetterScore] = useState<ErrorType>(
    assessment.correctedResult?.exercises[0].activities[0].results.wordsBeforeProcessing[index].PronunciationAssessment
      .ErrorType ?? 'None'
  )

  const handleLetterSave = () => {
    const result = assessment.correctedResult ?? assessment.result

    const { wordsBeforeProcessing } = result.exercises[0].activities[0].results

    wordsBeforeProcessing[index].PronunciationAssessment.ErrorType = letterScore
    wordsBeforeProcessing[index].Word = spokenLetter
    wordsBeforeProcessing[index].PronunciationAssessment.AccuracyScore =
      letterScore === 'None' ? 100 : LNF_ACCURACY_SCORE_THRESHOLD - 1

    result.exercises[0].activities[0].results.wordsBeforeProcessing = wordsBeforeProcessing

    handleSave(result)
  }

  const errorColors = () => {
    if (letter.isMispronounced) {
      return 'bg-yellow-100 text-black-600'
    }
    if (!letter.isCorrect) {
      return 'bg-red-100 text-black-600'
    }
    return 'bg-green-100 text-black-600'
  }

  const translateError = (error: string) => {
    if (error === 'INSERT') {
      return 'Inserted'
    }
    if (error === 'DELETE') {
      return 'Skipped'
    }
    if (error === 'SUBSTITUTE') {
      return 'Wrong Letter'
    }
    return error
  }

  const renderTooltip = (letter: ScoreLetter) => {
    if (letter.isMispronounced) {
      return (
        <div className="text-xs">
          <span>Mispronounced</span>
          <br />
          <span>Score: {letter.pronunciation.AccuracyScore}%</span>
        </div>
      )
    }
    if (!letter.isCorrect) {
      return (
        <div className="text-xs">
          <span>{translateError(letter.ErrorType)}</span>
        </div>
      )
    }
    return <span>Score: {letter.pronunciation.AccuracyScore}%</span>
  }

  const handleSpokenLetterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpokenLetter(e.target.value.replace(/[^a-zA-Z]/g, ''))
  }

  // Transform item.Word to match the case of the corresponding expected letter (mixed case)
  const expectedLetter = score?.expectedLetters[index] || ''
  const transformedWord =
    expectedLetter === expectedLetter.toUpperCase() ? letter.Word.toUpperCase() : letter.Word.toLowerCase()

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="group relative">
          <span
            className={cn(
              'cursor-pointer inline-flex size-8 items-center justify-center rounded-full text-sm font-semibold',
              errorColors(),
              score?.caseType === 'upper' && 'uppercase',
              score?.caseType === 'lower' && 'lowercase'
            )}
          >
            {letter.ErrorType !== 'DELETE' ? transformedWord : ''}
          </span>
          <div className="absolute bottom-full left-1/2 mb-2 hidden -translate-x-1/2 group-hover:block">
            <div className="whitespace-nowrap rounded bg-black px-2 py-1 text-xs text-white">
              {renderTooltip(letter)}
            </div>
            <div className="absolute left-1/2 size-0 -translate-x-1/2 border-x-4 border-t-4 border-transparent border-t-black" />
          </div>
        </div>
      </DialogTrigger>
      <DialogContent
        className="max-w-xs"
        onInteractOutside={(e) => {
          e.preventDefault()
        }}
      >
        <DialogHeader>
          <DialogTitle>Edit Score</DialogTitle>
          <DialogDescription>Edit the score for this letter ({letter.Word}).</DialogDescription>
        </DialogHeader>

        <Label>Spoken Letter</Label>
        <Input value={spokenLetter} minLength={1} maxLength={1} onChange={handleSpokenLetterChange} />

        {/* <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-600">
            Target Word: <b>{phoneme}</b>
          </p>
          {referenceWord && (
            <p className="text-sm text-gray-600">
              Reference Word: <b>{referenceWord}</b>
            </p>
          )}
          {phonemeData.matchQuality && (
            <p className="text-sm text-gray-600">
              Match Quality: <b>{phonemeData.matchQuality}%</b>
            </p>
          )}
        </div> */}

        <SelectGroup>
          <Select value={letterScore} onValueChange={(value) => setLetterScore(value as ErrorType)}>
            <SelectLabel>Was it pronounced correctly?</SelectLabel>
            <SelectTrigger>
              <SelectValue placeholder="Select a value" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Mispronunciation">No</SelectItem>
              <SelectItem value="None">Yes</SelectItem>
            </SelectContent>
          </Select>
        </SelectGroup>

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="primary-alt" onClick={handleLetterSave}>
              Save
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
