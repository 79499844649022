import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Search, X } from 'lucide-react'
import { useState } from 'react'

import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import StudentsTable from '@/pages/Admin/Students/StudentsTable'
import { getStudents } from '@/utils/api'

function Students() {
  const [searchTerm, setSearchTerm] = useState('')

  const queryClient = useQueryClient()

  const {
    data: studentsResponse,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['students-list'],
    queryFn: getStudents,
  })

  const handleRefetch = (studentId?: string) => {
    queryClient.invalidateQueries({ queryKey: ['student', studentId] })
    refetch()
  }

  return (
    <AdminLayout title="Students">
      <div className="p-8 pt-24">
        <Card>
          <CardHeader>
            <div className="flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-2 top-2.5 size-4 text-muted-foreground" />
                <Input
                  placeholder="Search students..."
                  value={searchTerm}
                  disabled={isLoading}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="px-8"
                />
                {searchTerm && (
                  <button
                    onClick={() => setSearchTerm('')}
                    className="absolute right-0 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    type="button"
                  >
                    <X className="size-4" />
                  </button>
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent className="relative">
            <StudentsTable
              studentsResponse={studentsResponse?.data ?? []}
              refetch={handleRefetch}
              searchTerm={searchTerm}
              isLoading={isLoading}
            />
          </CardContent>
        </Card>
      </div>
    </AdminLayout>
  )
}

export default Students
