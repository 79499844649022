import { createContext, useContext, useState } from 'react'

const AuthContext = createContext<any | undefined>(undefined)

export const LessonProvider: React.FC<any> = ({ children }) => {
  const [lessonPhrase, setLessonPhrase] = useState<string | null>('')
  const [lessonStep, setLessonStep] = useState<number>(0)
  const [remainingLessonPhrase, setRemainingLessonPhrase] = useState<string | null>(lessonPhrase)
  const [blockUserSpeech, setBlockUserSpeech] = useState<boolean>(false)

  return (
    <AuthContext.Provider
      value={{
        lessonPhrase,
        setLessonPhrase,
        remainingLessonPhrase,
        setRemainingLessonPhrase,
        lessonStep,
        setLessonStep,
        blockUserSpeech,
        setBlockUserSpeech,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useLesson = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useLesson must be used within an LessonProvider')
  }
  return context
}
