import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function kebabToCamelCase(input: string): string {
  return input.replace(/-([a-z])/g, (_, char) => char.toUpperCase())
}

export function camelToKebabCase(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
