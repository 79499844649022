export async function processWithConcurrencyLimit(promises: any[], limit: number): Promise<void[]> {
  const executing = new Set<Promise<void>>()

  const enqueue = async (): Promise<void> => {
    if (promises.length === 0) {
      return Promise.resolve()
    }
    const task = promises.shift()!
    const promise = task()

    promise.then(() => executing.delete(promise))
    executing.add(promise)

    let r: Promise<any> = Promise.resolve()
    if (executing.size >= limit) {
      r = Promise.race(executing)
    }

    await r.then(() => enqueue())
  }

  await enqueue()
  return Promise.all(Array.from(executing))
}

export async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
