import { Lesson } from '@alpha-school/reading-fluency-models'
import { DialogDescription } from '@radix-ui/react-dialog'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CheckCircle, Pencil, PlusIcon, Trash2, X, XCircle } from 'lucide-react'
import { useState } from 'react'

import Sidebar from '@/components/Admin/Sidebar'
import { LoadingIcon, LoadingWrapper } from '@/components/Loading/Loading'
import PageLayout from '@/components/PageLayout/PageLayout'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Switch } from '@/components/ui/switch'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { createLesson, deleteLesson, getLessons, updateLesson } from '@/utils/api'

type Exercise = {
  id?: string
  type:
    | 'COLUMNS_EXERCISE'
    | 'PICTURES_EXERCISE'
    | 'FIRST_SOUND_FLUENCY_EXERCISE'
    | 'LETTER_SOUND_FLUENCY_EXERCISE'
    | 'LETTER_NAMING_FLUENCY_EXERCISE'
    | 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE'
    | 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_EXERCISE'
    | 'FIRST_SOUND_FLUENCY_NO_TIMER_EXERCISE'
    | 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_EXERCISE'
    | 'LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_EXERCISE'
    | 'LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_EXERCISE'
    | 'LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE'
    | 'ORAL_READING_FLUENCY_EXERCISE'
    | 'MAZE_EXERCISE'
    | 'PHONEME_SEGMENTATION_EXERCISE'
  props: any
  activities: Activity[]
}

type Activity = {
  id?: string
  type:
    | 'REPEAT_AFTER_TEACHER_ACTIVITY'
    | 'POINT_AND_SAY_ACTIVITY'
    | 'SPELL_WORD_ACTIVITY'
    | 'ANSWER_THE_QUESTION_ACTIVITY'
    | 'TILT_HEAD_ACTIVITY'
    | 'DETECT_PHONEMES'
    | 'FIRST_SOUND_FLUENCY_ACTIVITY'
    | 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_ACTIVITY'
    | 'LETTER_SOUND_FLUENCY_ACTIVITY'
    | 'LETTER_NAMING_FLUENCY_ACTIVITY'
    | 'FIRST_SOUND_FLUENCY_NO_TIMER_ACTIVITY'
    | 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY'
    | 'LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_ACTIVITY'
    | 'LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_ACTIVITY'
    | 'LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_ACTIVITY'
    | 'LETTER_SOUND_FLUENCY_COMPLETIONS_ACTIVITY'
    | 'ORAL_READING_FLUENCY_ACTIVITY'
    | 'MAZE_ACTIVITY'
    | 'PHONEME_SEGMENTATION_ACTIVITY'
  props: any
}

const EXERCISE_TYPES = [
  'COLUMNS_EXERCISE',
  'PICTURES_EXERCISE',
  'FIRST_SOUND_FLUENCY_EXERCISE',
  'LETTER_SOUND_FLUENCY_EXERCISE',
  'LETTER_NAMING_FLUENCY_EXERCISE',
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_EXERCISE',
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_EXERCISE',
  'FIRST_SOUND_FLUENCY_NO_TIMER_EXERCISE',
  'LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_EXERCISE',
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_EXERCISE',
  'LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_EXERCISE',
  'LETTER_SOUND_FLUENCY_COMPLETIONS_EXERCISE',
  'ORAL_READING_FLUENCY_EXERCISE',
  'MAZE_EXERCISE',
  'PHONEME_SEGMENTATION_EXERCISE',
] as const

const ACTIVITY_TYPES = [
  'REPEAT_AFTER_TEACHER_ACTIVITY',
  'POINT_AND_SAY_ACTIVITY',
  'SPELL_WORD_ACTIVITY',
  'ANSWER_THE_QUESTION_ACTIVITY',
  'TILT_HEAD_ACTIVITY',
  'DETECT_PHONEMES',
  'FIRST_SOUND_FLUENCY_ACTIVITY',
  'LETTER_SOUND_FLUENCY_ACTIVITY',
  'LETTER_NAMING_FLUENCY_ACTIVITY',
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_ACTIVITY',
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_WITH_STEPS_ACTIVITY',
  'LETTER_NAMING_FLUENCY_CORRESPONDENCE_NO_HIGHLIGHT_ACTIVITY',
  'LETTER_NAMING_FLUENCY_NO_HIGHLIGHT_ACTIVITY',
  'LETTER_NAMING_FLUENCY_NEW_HIGHLIGHT_ACTIVITY',
  'LETTER_SOUND_FLUENCY_COMPLETIONS_ACTIVITY',
  'ORAL_READING_FLUENCY_ACTIVITY',
  'MAZE_ACTIVITY',
  'PHONEME_SEGMENTATION_ACTIVITY',
] as const

function Lessons() {
  const [showForm, setShowForm] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editingLesson, setEditingLesson] = useState<Lesson | null>(null)
  const [title, setTitle] = useState('')
  const [active, setActive] = useState(true)
  const [description, setDescription] = useState('')
  const [exercises, setExercises] = useState<Exercise[]>([])

  const { data: lessons, isLoading: isLoadingLessons } = useQuery({
    queryKey: ['lessons-list'],
    queryFn: getLessons,
  })

  const queryClient = useQueryClient()

  const createLessonMutation = useMutation({
    mutationFn: createLesson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lessons-list'] })
      setTitle('')
      setDescription('')
      setActive(true)
      setExercises([])
      setShowForm(false)
    },
    onError: (error) => {
      Sentry.captureException(error)
      console.error('Error creating lesson:', error)
      alert('Failed to create assessment. Please try again.')
    },
  })

  const deleteLessonMutation = useMutation({
    mutationFn: async (id: string) => {
      await deleteLesson(id)
      return true
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lessons-list'] })
    },
    onError: (error) => {
      Sentry.captureException(error)
      console.error('Error deleting lesson:', error)
      alert('Failed to delete assessment. Please try again.')
    },
  })

  const updateLessonMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: any }) => {
      const response = await updateLesson(id, data)
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lessons-list'] })
      setTitle('')
      setDescription('')
      setActive(true)
      setExercises([])
      setShowForm(false)
      setIsEditing(false)
      setEditingLesson(null)
    },
    onError: (error) => {
      Sentry.captureException(error)
      console.error('Error updating lesson:', error)
      alert('Failed to update assessment. Please try again.')
    },
  })

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this lesson? This action cannot be undone.')) {
      try {
        await deleteLessonMutation.mutateAsync(id)
      } catch (error) {
        Sentry.captureException(error)
        console.error('Error deleting lesson:', error)
      }
    }
  }

  const handleAddExercise = () => {
    setExercises([
      ...exercises,
      {
        type: 'COLUMNS_EXERCISE',
        props: {},
        activities: [],
      },
    ])
  }

  const handleAddActivity = (exerciseIndex: number) => {
    const newExercises = [...exercises]
    newExercises[exerciseIndex].activities.push({
      type: 'REPEAT_AFTER_TEACHER_ACTIVITY',
      props: {},
    })
    setExercises(newExercises)
  }

  const validateJson = (jsonString: string): boolean => {
    try {
      JSON.parse(jsonString)
      return true
    } catch (e) {
      return false
    }
  }

  const handleExercisePropsChange = (exerciseIndex: number, propsString: string) => {
    if (!validateJson(propsString)) {
      return // Don't update if JSON is invalid
    }
    const newExercises = [...exercises]
    newExercises[exerciseIndex].props = JSON.parse(propsString)
    setExercises(newExercises)
  }

  const handleActivityPropsChange = (exerciseIndex: number, activityIndex: number, propsString: string) => {
    if (!validateJson(propsString)) {
      return // Don't update if JSON is invalid
    }
    const newExercises = [...exercises]
    newExercises[exerciseIndex].activities[activityIndex].props = JSON.parse(propsString)
    setExercises(newExercises)
  }

  const handleEdit = (lesson: Lesson) => {
    console.log('Original lesson data:', lesson)
    setEditingLesson(lesson)
    setTitle(lesson.title)
    setDescription(lesson.description || '')
    setActive(lesson.active)
    setExercises(
      lesson.exercises.map((exercise) => ({
        ...exercise,
        id: exercise.id,
        activities: exercise.activities.map((activity) => ({
          ...activity,
          id: activity.id,
        })),
      }))
    )
    setIsEditing(true)
    setShowForm(true)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const data = {
      title,
      description,
      active,
      exercises: exercises.map((exercise) => ({
        id: exercise.id,
        type: exercise.type,
        props: exercise.props,
        activities: exercise.activities.map((activity) => ({
          id: activity.id,
          type: activity.type,
          props: activity.props,
        })),
      })),
    }

    console.log('Submitting data:', data)

    try {
      if (isEditing && editingLesson) {
        await updateLessonMutation.mutateAsync({
          id: editingLesson.id,
          data,
        })
      } else {
        await createLessonMutation.mutateAsync(data)
      }
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error submitting form:', error)
    }
  }

  const handleDeleteExercise = (exerciseIndex: number) => {
    if (window.confirm('Are you sure you want to delete this exercise?')) {
      const newExercises = [...exercises]
      newExercises.splice(exerciseIndex, 1)
      setExercises(newExercises)
    }
  }

  if (isLoadingLessons) {
    return (
      <PageLayout className="flex">
        <div className="flex h-screen w-full">
          <Sidebar />
          <LoadingWrapper>
            <LoadingIcon />
          </LoadingWrapper>
        </div>
      </PageLayout>
    )
  }

  return (
    <PageLayout className="flex">
      <div className="flex w-full">
        <Sidebar />
        <div className="flex-1 bg-gray-100 p-8">
          <div className="fixed right-0 top-0 z-10 flex h-[65px] w-[calc(100%-256px)] items-center justify-between border-b bg-white px-8 py-4">
            <div className="flex items-center gap-4">
              <h1 className="text-2xl font-bold">Assessments</h1>
            </div>
            <Button
              variant="primary-alt"
              className="flex items-center gap-2"
              onClick={() => setShowForm(true)}
              type="button"
            >
              <PlusIcon className="size-4" />
              Create new assessment
            </Button>
          </div>

          <Dialog open={showForm} onOpenChange={setShowForm}>
            <DialogContent className="max-w-3xl">
              <DialogHeader>
                <DialogTitle>{isEditing ? 'Edit Assessment' : 'Create New Assessment'}</DialogTitle>
              </DialogHeader>
              <DialogDescription>See the student's benchmark for each period.</DialogDescription>
              <form
                id="lesson-form"
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit(e)
                }}
                className="max-h-[calc(100vh-16rem)] space-y-4 overflow-y-auto p-6"
              >
                <div>
                  <label className="mb-1 block text-sm font-medium">Active</label>
                  <Switch checked={active} onCheckedChange={setActive} />
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium">Title</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full rounded border p-2"
                    required
                  />
                </div>
                <div>
                  <label className="mb-1 block text-sm font-medium">Description</label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full rounded border p-2"
                    rows={4}
                  />
                </div>
                <div>
                  <label className="mb-2 block text-sm font-medium">Exercises</label>
                  {exercises.map((exercise, exerciseIndex) => (
                    <div key={exerciseIndex} className="relative mb-4 rounded border p-4">
                      <button
                        type="button"
                        onClick={() => handleDeleteExercise(exerciseIndex)}
                        className="absolute right-2 top-2 rounded-full p-1 transition-colors hover:bg-red-50"
                        title="Delete exercise"
                      >
                        <X className="size-4 text-red-600" />
                      </button>

                      <div className="mb-2">
                        <label className="mb-1 block text-sm font-medium">Exercise Type</label>
                        <select
                          value={exercise.type}
                          onChange={(e) => {
                            const newExercises = [...exercises]
                            newExercises[exerciseIndex].type = e.target.value as Exercise['type']
                            setExercises(newExercises)
                          }}
                          className="w-full rounded border p-2"
                        >
                          {EXERCISE_TYPES.map((type) => (
                            <option key={type} value={type}>
                              {type
                                .split('_')
                                .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                                .join(' ')}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="mb-2">
                        <label className="mb-1 block text-sm font-medium">Exercise Props (JSON)</label>
                        <textarea
                          value={JSON.stringify(exercise.props, null, 2)}
                          onChange={(e) => handleExercisePropsChange(exerciseIndex, e.target.value)}
                          className="w-full rounded border p-2 font-mono text-sm"
                          rows={4}
                          placeholder="{}"
                        />
                      </div>

                      <div className="mb-2">
                        <label className="mb-1 block text-sm font-medium">Activities</label>
                        {exercise.activities.map((activity, activityIndex) => (
                          <div key={activityIndex} className="mb-2 rounded border p-2">
                            <select
                              value={activity.type}
                              onChange={(e) => {
                                const newExercises = [...exercises]
                                newExercises[exerciseIndex].activities[activityIndex].type = e.target
                                  .value as Activity['type']
                                setExercises(newExercises)
                              }}
                              className="mb-2 w-full rounded border p-2"
                            >
                              {ACTIVITY_TYPES.map((type) => (
                                <option key={type} value={type}>
                                  {type
                                    .split('_')
                                    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
                                    .join(' ')}
                                </option>
                              ))}
                            </select>

                            <label className="mb-1 block text-sm font-medium">Activity Props (JSON)</label>
                            <textarea
                              value={JSON.stringify(activity.props, null, 2)}
                              onChange={(e) => handleActivityPropsChange(exerciseIndex, activityIndex, e.target.value)}
                              className="w-full rounded border p-2 font-mono text-sm"
                              rows={4}
                              placeholder="{}"
                            />
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={() => handleAddActivity(exerciseIndex)}
                          className="mt-2 rounded bg-green-500 px-3 py-1 text-sm text-white"
                        >
                          Add Activity
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={handleAddExercise}
                    className="mt-2 rounded bg-purple-500 px-4 py-2 text-white"
                  >
                    Add Exercise
                  </button>
                </div>
              </form>
              <DialogFooter>
                <Button
                  type="button"
                  form="lesson-form"
                  variant="outline"
                  onClick={() => {
                    setShowForm(false)
                    setIsEditing(false)
                    setEditingLesson(null)
                    setTitle('')
                    setDescription('')
                    setExercises([])
                  }}
                  disabled={createLessonMutation.isPending || updateLessonMutation.isPending}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary-alt"
                  disabled={createLessonMutation.isPending || updateLessonMutation.isPending}
                  form="lesson-form"
                >
                  {isEditing
                    ? updateLessonMutation.isPending
                      ? 'Updating...'
                      : 'Update Assessment'
                    : createLessonMutation.isPending
                    ? 'Creating...'
                    : 'Create Assessment'}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          <div className="pt-16">
            <Card className="pt-6">
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Title</TableHead>
                      <TableHead>Description</TableHead>
                      <TableHead>Active</TableHead>
                      <TableHead>Created At</TableHead>
                      <TableHead>Exercises</TableHead>
                      <TableHead className="text-right">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {isLoadingLessons ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className="flex h-24 items-center justify-center">
                            <LoadingIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : lessons?.data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <div className="flex h-24 items-center justify-center text-sm text-gray-500">
                            No assessments found
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      lessons?.data?.map((lesson: Lesson) => (
                        <TableRow key={lesson.id} className="hover:bg-gray-50">
                          <TableCell className="whitespace-normal px-6 py-4 font-medium">{lesson.title}</TableCell>
                          <TableCell className="whitespace-normal px-6 py-4">{lesson.description}</TableCell>
                          <TableCell className="whitespace-normal px-6 py-4">
                            {lesson.active ? (
                              <CheckCircle className="size-4 text-green-600" />
                            ) : (
                              <XCircle className="size-4 text-red-600" />
                            )}
                          </TableCell>
                          <TableCell className="whitespace-normal px-6 py-4">
                            {new Date(lesson.createdAt as string).toLocaleString()}
                          </TableCell>
                          <TableCell className="whitespace-normal px-6 py-4">{lesson.exercises?.length || 0}</TableCell>
                          <TableCell className="whitespace-normal px-6 py-4 text-right">
                            <div className="flex justify-end gap-2">
                              <button
                                onClick={() => handleEdit(lesson)}
                                className="rounded-full p-1 transition-colors hover:bg-gray-100"
                                title="Edit assessment"
                              >
                                <Pencil className="size-4 text-blue-600" />
                              </button>
                              <button
                                onClick={() => handleDelete(lesson.id)}
                                className="hidden rounded-full p-1 transition-colors hover:bg-gray-100"
                                disabled={deleteLessonMutation.isPending}
                                title="Delete assessment"
                              >
                                {deleteLessonMutation.isPending && deleteLessonMutation.variables === lesson.id ? (
                                  <div className="size-4 animate-spin rounded-full border-b-2 border-red-600" />
                                ) : (
                                  <Trash2 className="size-4 text-red-600" />
                                )}
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Lessons
