import axios from 'axios'
import { useState } from 'react'

import { api } from '@/utils/api'
import { processWithConcurrencyLimit } from '@/utils/promise'
import { calculatePartSize, splitFile } from '@/utils/upload'

interface UploadPartParams {
  file: File
  partNumber: number
  uploadId: string
  parts: any[]
  onUploadProgress?: any
}

interface UploadFilePartsProps {
  file: File
  uploadId: string
  updateProgress: (progress: number) => void
}

export function useFileUpload() {
  const [files, setFiles] = useState<File[]>([])

  async function initiateUpload({ fileName }: any) {
    try {
      const response = await api.post(
        `/multipart-upload/initiate`,
        {
          key: fileName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('Initiate upload response:', response.data)
      return response.data
    } catch (error) {
      console.error('Failed to initiate upload:', error)
    }
  }

  async function completeUpload({
    uploadId,
    fileName,
    parts,
    checksum,
    fileType,
  }: {
    uploadId: string
    fileName: string
    parts: any[]
    checksum: string
    fileType: string
  }) {
    try {
      const response = await api.post(`/multipart-upload/complete-upload`, {
        key: fileName,
        uploadId,
        parts,
        checksum,
        fileType,
      })
      console.log('Complete upload response:', response.data)
      if (response.data) {
        return response.data
      }
      throw new Error('Failed to complete multipart upload')
    } catch (error) {
      console.error('Failed to complete upload:', error)
      throw error
    }
  }

  async function listParts({ uploadId, fileName }: any) {
    try {
      const response = await api.get(`/multipart-upload/list-parts?uploadId=${uploadId}&key=${fileName}`)

      return response.data
    } catch (error) {
      console.error('Failed to list parts:', error)
    }
  }

  async function generateSinglePresignedUrl({
    key,
    checksum,
    fileType,
  }: {
    key: string
    checksum: string
    fileType: string
  }) {
    try {
      const options = {
        method: 'POST',
        url: `/generate-presigned-url`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { key, checksum, fileType },
        timeout: 30000,
      }

      const response = await api.request(options)

      if (!response.data?.presignedUrl) {
        throw new Error('No presigned URL received from server')
      }

      return response.data
    } catch (error) {
      console.error('Error generating presigned URL:', error)
      if (axios.isAxiosError(error)) {
        if (error.code === 'ECONNABORTED') {
          throw new Error('Request timed out while getting upload URL')
        }
        throw new Error(`Failed to get upload URL: ${error.message}`)
      }
      throw error
    }
  }

  async function uploadPart(filePart: Blob, presignedUrl: string) {
    try {
      await axios.put(presignedUrl, filePart, {
        headers: {
          'Content-Type': 'binary/octet-stream',
        },
      })
    } catch (error) {
      console.error('Error uploading part:', error)
    }
  }

  async function uploadSinglePart({ file, partNumber, uploadId, parts }: UploadPartParams) {
    try {
      const response = await api.post(`/multipart-upload/generate-single-part-presigned-url`, {
        key: file.name,
        uploadId,
        partNumber: partNumber,
      })

      const { presignedUrl } = response.data
      if (!presignedUrl) {
        throw new Error('No presigned URL received for part upload')
      }

      await uploadPart(parts[partNumber - 1], presignedUrl)
    } catch (error) {
      console.error(`Error uploading part ${partNumber}:`, error)
      throw error
    }
  }

  async function uploadFileParts({ file, uploadId, updateProgress }: UploadFilePartsProps) {
    const PART_SIZE = calculatePartSize(file.size)
    const parts = splitFile(file, PART_SIZE)
    let partsUploaded = 0

    const promises = parts.map((_: any, index: number) => {
      return async () => {
        await uploadSinglePart({
          file,
          partNumber: index + 1,
          uploadId,
          parts,
        })

        partsUploaded++
        const progress = (partsUploaded / parts.length) * 100
        updateProgress(Math.min(progress, 100))
      }
    })

    await processWithConcurrencyLimit(promises, parseInt(import.meta.env.VITE_UPLOAD_MAX_MULTIPART_PARTS_CONCURRENCY))

    // Get the actual parts with ETags from S3
    const uploadedParts = await listParts({ uploadId, fileName: file.name })
    if (!uploadedParts) {
      throw new Error('Failed to list uploaded parts')
    }

    return uploadedParts
  }

  return {
    files,
    setFiles,
    initiateUpload,
    completeUpload,
    listParts,
    uploadFileParts,
    generateSinglePresignedUrl,
  }
}
