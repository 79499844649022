import { Grade, User } from '@alpha-school/reading-fluency-models'
import { useQueryClient } from '@tanstack/react-query'
import { ExternalLink } from 'lucide-react'
import { useState } from 'react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadingIcon } from '@/components/Loading/Loading'
import TablePaginator from '@/components/TablePaginator/TablePaginator'
import { Button } from '@/components/ui/button'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { GradesColors } from '@/types/student'
import { getStudentDetails, updateStudent } from '@/utils/api'

interface StudentsTableProps {
  studentsResponse: User[]
  refetch: (studentId?: string) => void
  searchTerm: string
  isLoading: boolean
}

const ITEMS_PER_PAGE = 10

export default function StudentsTable({ studentsResponse, refetch, searchTerm, isLoading }: StudentsTableProps) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const queryString = window.location.search
  const [currentPage, setCurrentPage] = useState(1)
  const [isUpdatingGrade, setIsUpdatingGrade] = useState(false)

  const filteredStudents = useMemo(() => {
    if (!studentsResponse) return []

    return studentsResponse.filter((student) =>
      Object.values(student).some((value) => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    )
  }, [studentsResponse, searchTerm])

  const totalPages = Math.ceil((filteredStudents?.length || 0) / ITEMS_PER_PAGE)

  const currentStudents = useMemo(() => {
    const start = (currentPage - 1) * ITEMS_PER_PAGE
    return filteredStudents.slice(start, start + ITEMS_PER_PAGE)
  }, [filteredStudents, currentPage])

  const handleStudentClick = (studentId: string) => {
    navigate(`/admin/students/${studentId}${queryString}`)
  }

  const handleGradeChange = async (studentId: string, newGrade: Grade) => {
    setIsUpdatingGrade(true)
    await updateStudent(studentId, newGrade)
    await refetch(studentId)
    setIsUpdatingGrade(false)
  }

  const prefetchStudentDetails = (studentId: string) => {
    queryClient.prefetchQuery({
      queryKey: ['student', studentId],
      queryFn: async () => {
        const { data } = await getStudentDetails(studentId)
        return data
      },
      staleTime: 1000 * 60 * 5,
    })
  }

  return (
    <>
      {isUpdatingGrade && (
        <div className="absolute inset-0 z-50 bg-white/50">
          <div className="flex size-full items-center justify-center">
            <LoadingIcon />
          </div>
        </div>
      )}
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Grade</TableHead>
            <TableHead className="text-right">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <div className="flex h-24 items-center justify-center">
                  <LoadingIcon />
                </div>
              </TableCell>
            </TableRow>
          ) : currentStudents.length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                <div className="flex h-24 items-center justify-center text-sm text-gray-500">No students found</div>
              </TableCell>
            </TableRow>
          ) : (
            currentStudents.map((student) => (
              <TableRow
                key={student.id}
                className="cursor-pointer hover:bg-gray-50"
                onMouseEnter={() => prefetchStudentDetails(student.id)}
              >
                <TableCell>{student.name}</TableCell>
                <TableCell>{student.email}</TableCell>
                <TableCell>
                  {student.grade && (
                    <span
                      className={cn(
                        `inline-flex rounded-full px-2 text-xs font-semibold leading-5`,
                        GradesColors[student.grade as keyof typeof GradesColors]
                      )}
                    >
                      {Grade[student.grade as keyof typeof Grade]}
                    </span>
                  )}
                </TableCell>
                <TableCell className="text-right">
                  <div className="flex items-center justify-end gap-2">
                    {student.role === 'STUDENT' && (
                      <select
                        className="cursor-pointer rounded-md border border-gray-300 px-3 py-1 text-sm hover:border-purple-500 focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500"
                        value={student.grade || ''}
                        onChange={(e) => handleGradeChange(student.id, e.target.value as Grade)}
                      >
                        <option value="" disabled>
                          Select Grade
                        </option>
                        {Object.keys(Grade).map((grade) => (
                          <option key={grade} value={grade}>
                            {Grade[grade as keyof typeof Grade]}
                          </option>
                        ))}
                      </select>
                    )}

                    <Button variant="ghost" size="sm" onClick={() => handleStudentClick(student.id)}>
                      <ExternalLink className="mr-2 size-4" />
                      Details
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell colSpan={4} className="bg-white">
              <TablePaginator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                itemsPerPage={ITEMS_PER_PAGE}
                totalItems={filteredStudents.length}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}
