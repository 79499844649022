import * as Sentry from '@sentry/react'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
// Add type declarations for the Web Speech API
declare global {
  interface Window {
    webkitSpeechRecognition: any
    SpeechRecognition: any
    webkitSpeechGrammarList: any
    SpeechGrammarList: any
  }
}

const GRAMMAR = `#JSGF V1.0; grammar alphabet; public <letter> = a | b | c | d | e | f | g | h | i | j | k | l | m | n | o | p | q | r | s | t | u | v | w | x | y | z | A | B | C | D | E | F | G | H | I | J | K | L | M | N | O | P | Q | R | S | T | U | V | W | X | Y | Z;`

interface SpeechRecognitionContextType {
  transcript: string
  interimTranscript: string
  isListening: boolean
  startListening: () => void
  stopListening: () => void
  hasRecognitionSupport: boolean
}

const SpeechRecognitionContext = createContext<SpeechRecognitionContextType | null>(null)

interface Props {
  children: ReactNode
}

export function SpeechRecognitionProvider({ children }: Props) {
  const [transcript, setTranscript] = useState('')
  const [interimTranscript, setInterimTranscript] = useState('')
  const [isListening, setIsListening] = useState(false)

  // Check if browser supports speech recognition
  const hasRecognitionSupport = 'webkitSpeechRecognition' in window || 'SpeechRecognition' in window

  // Initialize speech recognition
  const recognition = hasRecognitionSupport ? new (window.webkitSpeechRecognition || window.SpeechRecognition)() : null

  useEffect(() => {
    if (!recognition) return

    // Try to set up grammar list if supported
    try {
      if (window.webkitSpeechGrammarList || window.SpeechGrammarList) {
        const speechRecognitionList = new (window.webkitSpeechGrammarList || window.SpeechGrammarList)()
        speechRecognitionList.addFromString(GRAMMAR, 1)
        recognition.grammars = speechRecognitionList
      }
    } catch (error) {
      console.log('SpeechGrammarList not supported in this browser')
    }

    // Configure recognition
    recognition.continuous = true
    recognition.interimResults = true
    recognition.lang = 'en-US'

    recognition.onstart = (event: any) => {
      console.log('onstart', event)
    }

    recognition.onend = (event: any) => {
      console.log('onend', event)
    }

    recognition.onspeechend = (event: any) => {
      console.log('onspeechend', event)
    }

    recognition.onsoundstart = (event: any) => {
      console.log('soundstart', event)
    }

    recognition.onsoundend = (event: any) => {
      console.log('soundend', event)
    }

    recognition.onresult = (event: any) => {
      const current = event.resultIndex
      const result = event.results[current]
      console.log('result', result)

      if (result.isFinal) {
        const finalTranscript = result[0].transcript
        console.log('Final transcript:', finalTranscript)
        setTranscript(finalTranscript)
        setInterimTranscript('')
      } else {
        const currentInterim = result[0].transcript
        console.log('Interim transcript:', currentInterim)
        setInterimTranscript(currentInterim)
      }
    }

    recognition.onerror = (event: { error: string }) => {
      Sentry.captureException(event.error)
      console.error('Speech recognition error:', event.error)
      setIsListening(false)
    }

    recognition.onnomatch = (event: any) => {
      console.log('nomatch', event)
    }

    recognition.onend = () => {
      setIsListening(false)
      setInterimTranscript('')
    }
  }, [recognition])

  const startListening = () => {
    if (!recognition) return
    recognition.start()
    setIsListening(true)
    setInterimTranscript('')
  }

  const stopListening = () => {
    if (!recognition) return
    recognition.stop()
    setIsListening(false)
    setInterimTranscript('')
  }

  return (
    <SpeechRecognitionContext.Provider
      value={{
        transcript,
        interimTranscript,
        isListening,
        startListening,
        stopListening,
        hasRecognitionSupport,
      }}
    >
      {children}
    </SpeechRecognitionContext.Provider>
  )
}

export function useSpeechRecognition() {
  const context = useContext(SpeechRecognitionContext)
  if (!context) {
    throw new Error('useSpeechRecognition must be used within a SpeechRecognitionProvider')
  }
  return context
}
