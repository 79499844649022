import { Grade } from '@alpha-school/reading-fluency-models'

import { Skeleton } from '@/components/ui/skeleton'
import { cn } from '@/lib/utils'
import { GradesColors } from '@/types/student'

export default function HeaderDetails({
  loading,
  name,
  email,
  grade,
  createdAt,
  assessment,
  locale,
  completedAt,
}: {
  loading: boolean
  name?: string
  email?: string
  grade?: string
  createdAt?: string
  assessment?: string
  locale?: string
  completedAt?: string
}) {
  if (loading) {
    return (
      <div className="flex flex-col gap-2">
        <Skeleton className="h-4 w-48" />
        <Skeleton className="h-4 w-24" />
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-40" />
      </div>
    )
  }

  return (
    <>
      {assessment && <p>Assessment: {assessment}</p>}
      {name && <p>Name: {name}</p>}
      {email && <p>Email: {email}</p>}
      {grade && (
        <p>
          Grade:{' '}
          <span
            className={cn(
              `inline-flex rounded-full px-2 text-xs font-semibold leading-5`,
              GradesColors[grade as keyof typeof GradesColors]
            )}
          >
            {Grade[grade as keyof typeof Grade]}
          </span>
        </p>
      )}
      {createdAt && <p>Member since: {new Date(createdAt).toLocaleString()}</p>}
      {completedAt && <p>Completed at: {new Date(completedAt).toLocaleString()}</p>}
      {locale && <p>Locale: {locale}</p>}
    </>
  )
}
