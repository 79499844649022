import { useEffect, useRef, useState } from 'react'

interface AudioVisualizerProps {
  isRecording: boolean
  stream?: MediaStream
  show?: boolean
}

const AudioVisualizer = ({ isRecording, stream, show = true }: AudioVisualizerProps) => {
  const [level, setLevel] = useState<number>(0)
  const animationFrameId = useRef<number>()
  const analyserRef = useRef<AnalyserNode | null>(null)

  useEffect(() => {
    if (!stream || !isRecording) {
      setLevel(0)
      return
    }

    const audioContext = new AudioContext()
    const source = audioContext.createMediaStreamSource(stream)
    const analyser = audioContext.createAnalyser()
    analyserRef.current = analyser

    analyser.fftSize = 256
    analyser.minDecibels = -70
    analyser.maxDecibels = -10
    analyser.smoothingTimeConstant = 0.7

    source.connect(analyser)

    const updateLevel = () => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(dataArray)

      // Find the peak value instead of average
      const peak = Math.max(...Array.from(dataArray))
      // Added noise floor threshold and increased dampening
      const noiseFloor = 20 // Ignore very quiet sounds
      const normalizedValue = peak < noiseFloor ? 0 : Math.min(1, (peak / 255) * 2 * 0.7)
      setLevel(normalizedValue)

      animationFrameId.current = requestAnimationFrame(updateLevel)
    }

    updateLevel()

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current)
      }
      source.disconnect()
      audioContext.close()
    }
  }, [stream, isRecording])

  // Create thresholds for each bar with adjusted thresholds
  const bars = Array.from({ length: 7 }).map((_, index) => {
    // Slightly more aggressive exponential curve
    const threshold = Math.pow((index + 1) / 7, 1.4)
    const isActive = level >= threshold
    return (
      <div
        key={index}
        className="duration-50 h-6 w-1 transition-colors"
        style={{
          backgroundColor: `rgba(255, 255, 0, ${isActive ? 1 : 0.2})`,
          boxShadow: isActive ? '0 0 4px rgba(255, 255, 0, 1)' : 'none',
        }}
      />
    )
  })

  if (!show) return null

  return <div className="flex h-8 items-center gap-1">{bars}</div>
}

export default AudioVisualizer
