import { Avatar, useAvatar } from 'alpha-ai-avatar-sdk-react'

import alice from '@/assets/alice.png'
import aliceSquare from '@/assets/alice-square.jpeg'
import { cn } from '@/lib/utils'

export const RichAvatar: React.FC<{
  message: string
  useMouthZoom?: boolean
  size?: string
  freeze?: boolean
  onCamera?: boolean
  avatarState?: string
  extraStyle?: React.CSSProperties
}> = ({ useMouthZoom = false, size = '160', freeze, onCamera, avatarState, extraStyle }) => {
  const { isConnected } = useAvatar()

  function handleSay() {
    // if (isAvatarSpeaking) {
    //   return stop()
    // }
    // Feature removed for now, they don't want the click making the avatar speaking
    // say(message, { prosody: { rate: "-10%" } });
  }
  const sizeInPixels = `${size}px`
  const avatarStyle = useMouthZoom
    ? { height: '450px', width: '450px', minWidth: '450px', minHeight: '450px' }
    : {
        height: sizeInPixels,
        width: sizeInPixels,
        minWidth: '120px',
        minHeight: '120px',
      }

  const className = `relative overflow-hidden`

  const style = {
    borderRadius: onCamera ? '21px' : '50%',
    width: sizeInPixels,
    height: sizeInPixels,
    minHeight: '120px',
    minWidth: '120px',
    ...extraStyle,
  }

  return (
    <div style={style} className={className}>
      <img style={style} className={cn('absolute', freeze ? 'z-50' : 'z-0')} src={onCamera ? aliceSquare : alice} />
      {isConnected && !freeze && (
        <div
          className={cn(`absolute z-10 flex flex-col items-center gap-4`, useMouthZoom && '-left-[93%] -top-[150%]')}
          style={avatarStyle}
        >
          <Avatar style={avatarStyle} onClick={handleSay} />

          {avatarState && (
            <div className="absolute bottom-0 left-0 flex w-full items-center justify-center bg-black/50 text-lg text-white">
              <span className="ml-2 text-center">{avatarState}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
