import {
  LetterSoundCompletionsFluencyAnalysis,
  LetterSoundCompletionsFluencyResult,
} from '@/types/assessments/letter-sound-completions-fluency'

export function parseLetterSoundCompletionsFluency(
  assessmentResult: LetterSoundCompletionsFluencyResult
): LetterSoundCompletionsFluencyAnalysis {
  const analysis: LetterSoundCompletionsFluencyAnalysis = {
    correctCount: 0,
    incorrectCount: 0,
    correctPhonemes: [],
    missedPhonemes: [],
    commonlyMissedPhonemes: [],
    totalScore: 0,
  }

  // Get the first exercise and activity
  const exercise = assessmentResult.exercises['0']
  const activity = exercise.activities['0']

  activity.results.forEach((result) => {
    const isCorrect = result.correct_match_in_top_1
    const phonemeData = {
      phoneme: result.reference_phoneme,
      matchQuality: result.reference_phoneme_match_quality * 100, // Convert to percentage
      topMatches: result.top_matches,
    }

    if (isCorrect) {
      analysis.correctCount++
      analysis.correctPhonemes.push(phonemeData)
    } else {
      analysis.incorrectCount++
      analysis.missedPhonemes.push(phonemeData)
      analysis.commonlyMissedPhonemes.push(phonemeData)
    }
  })

  // Sort commonly missed phonemes by match quality (ascending)
  analysis.commonlyMissedPhonemes.sort((a, b) => a.matchQuality - b.matchQuality)

  return analysis
}
