import { LetterNamingFluencyAnalysis, LetterNamingFluencyResult } from '@/types/assessments/letter-naming-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'
import calcSequenceResults from '@/utils/calcSequenceResults'
import { LNF_ACCURACY_SCORE_THRESHOLD } from '@/utils/constants'

import { getPartialCompositeScores } from './parsers-utils'
import { PartialCompositeScore } from './types/composite-score'

export function getLetterNamingFluencyScores(
  assessmentResults: LessonAssignment<'letter-naming-fluency'>[],
  pronunciationScoreThreshold: number,
  useAzureMispronunciationFlag: boolean
): PartialCompositeScore {
  return getPartialCompositeScores(
    assessmentResults,
    parseLetterNamingFluency,
    pronunciationScoreThreshold,
    useAzureMispronunciationFlag
  )
}

export function parseLetterNamingFluency(
  assessmentResult: LetterNamingFluencyResult,
  _pronunciationScoreThreshold: number,
  _useAzureMispronunciationFlag: boolean,
  expectedLetters: string[] | undefined
): LetterNamingFluencyAnalysis {
  const analysis: LetterNamingFluencyAnalysis = {
    allLetters: [],
    expectedLetters: [],
    correctCount: 0,
    incorrectCount: 0,
    correctLetters: [],
    mispronunciationLetters: [],
    insertedLetters: [],
    omittedLetters: [],
    commonlyMissedLetters: [],
    totalScore: 0, // this is used for the composite score
    originalData: assessmentResult,
  }

  // Get the first exercise and activity (assuming alphabet exercise)
  const exercise = assessmentResult.exercises['0']
  const activity = exercise.activities['0']

  const actualExpectedLetters: any[] = (expectedLetters || activity.results?.expectedLetters) ?? []
  const wordsBeforeProcessing = activity.results?.wordsBeforeProcessing

  const wordsWithoutSkips = wordsBeforeProcessing.filter(
    (word: any) => word?.PronunciationAssessment?.ErrorType !== 'Skip'
  )

  const words = calcSequenceResults({
    source: wordsWithoutSkips.map((word: any) => ({
      word: word.Word.toLowerCase(),
      PronunciationAssessment: word.PronunciationAssessment,
    })),
    target: actualExpectedLetters.map((letter) => letter.toLowerCase()),
  })

  const totalWordsSpoken = words.length
  const previousCorrectCount = words.reduce((acc: number, curr: any) => {
    const isCorrect = curr.operation === 'MATCH'
    return isCorrect ? acc + 1 : acc
  }, 0)

  const previousIncorrectCount = words.reduce((acc: number, curr: any) => {
    const isIncorrect = curr.operation !== 'MATCH'
    return isIncorrect ? acc + 1 : acc
  }, 0)

  let correctedResults: any[] = []
  const slicedExpectedLetters = [...actualExpectedLetters]

  let previousResult = words
  for (let i = totalWordsSpoken - 1; i >= 0; i--) {
    slicedExpectedLetters.pop()

    const result = calcSequenceResults({
      source: wordsWithoutSkips.map((word: any) => ({
        word: word.Word.toLowerCase(),
        PronunciationAssessment: word.PronunciationAssessment,
      })),
      target: slicedExpectedLetters.map((letter) => letter.toLowerCase()),
    })

    const correctCount = result.reduce((acc: number, curr: any) => {
      const isCorrect = curr.operation === 'MATCH'
      return isCorrect ? acc + 1 : acc
    }, 0)

    const incorrectCount = result.reduce((acc: number, curr: any) => {
      const isIncorrect = curr.operation !== 'MATCH'
      return isIncorrect ? acc + 1 : acc
    }, 0)

    if (correctCount < previousCorrectCount || incorrectCount > previousIncorrectCount) {
      correctedResults = previousResult
      break
    }
    previousResult = result
  }

  const finalWords = correctedResults?.length > 0 ? correctedResults : words
  finalWords.forEach((word: any) => {
    const isMatch = word.operation === 'MATCH'
    const isCorrect = isMatch

    analysis.allLetters.push({
      Word: word.saidLetter || word.letter,
      isCorrect: isCorrect && word?.pronunciation?.AccuracyScore >= LNF_ACCURACY_SCORE_THRESHOLD,
      score: word?.pronunciation?.AccuracyScore,
      ErrorType: word.operation !== 'MATCH' ? word.operation : 'None',
      pronunciation: word?.pronunciation,
      isMispronounced: word?.pronunciation?.AccuracyScore < LNF_ACCURACY_SCORE_THRESHOLD,
    })

    if (isCorrect) {
      analysis.correctLetters.push(word.Word)
    }
  })
  const { cleanedArray } = removeTrailingDeletions(analysis.allLetters)
  analysis.allLetters = cleanedArray

  analysis.mispronunciationLetters = cleanedArray
    .filter((letter) => letter.isMispronounced)
    .map((letter) => letter.Word)

  analysis.correctCount = cleanedArray.filter((letter) => letter.isCorrect).length
  analysis.incorrectCount = cleanedArray.filter((letter) => !letter.isCorrect).length
  analysis.commonlyMissedLetters = cleanedArray.filter((letter) => letter.isMispronounced)
  analysis.commonlyMissedLetters.sort((a, b) => a.accuracy - b.accuracy)
  analysis.totalScore = analysis.correctCount

  return analysis
}

function removeTrailingDeletions(array: any[]): { cleanedArray: any[]; removedCount: number } {
  // Create a copy of the array to avoid mutating the original
  const result = [...array]
  let removedCount = 0

  // Start from the end and remove deletion errors until we find a non-deletion item
  for (let i = result.length - 1; i >= 0; i--) {
    if (result[i].ErrorType === 'DELETE') {
      result.pop()
      removedCount++
    } else {
      // Stop when we find a non-deletion item
      break
    }
  }

  return {
    cleanedArray: result,
    removedCount,
  }
}
