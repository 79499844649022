import { useAvatar } from 'alpha-ai-avatar-sdk-react'
import { useEffect, useState } from 'react'

import { RichAvatar } from '@/components/Avatar/RichAvatar'
import { useConfetti } from '@/context/ConfettiContext/ConfettiContext'

import cubesTopLeft from '../../assets/cubes_top_left.png'
import ondulationBg from '../../assets/ondulationBg.svg'
import GoHomeButton from '../../components/GoHomeButton'
import PageLayout from '../../components/PageLayout'
import Spinner from '../../components/Spinner'
import Window from '../../components/Window'
import { useUser } from '../../hooks/useUser'

const avatarMessage = 'Congratulations! You are all finished!'

export const AllDone: React.FC = () => {
  const { user } = useUser()
  const { say, isConnected, connect } = useAvatar()
  const { fireConfetti } = useConfetti()

  const [isAvatarSpeaking, setIsAvatarSpeaking] = useState(false)

  useEffect(() => {
    if (!isConnected) {
      connect()
      return
    }
    fireConfetti()
    say(avatarMessage)
  }, [isConnected])

  const handleAvatarClick = () => {
    setIsAvatarSpeaking(true)
    if (isAvatarSpeaking) return
    fireConfetti()
    say(avatarMessage)
    setTimeout(() => {
      setIsAvatarSpeaking(false)
    }, 2000)
  }

  if (!user) {
    return (
      <PageLayout className="relative justify-center">
        <div className="flex h-screen w-full flex-col items-center justify-center bg-purple-gradient">
          <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
          <Window className="min-w-[50%] items-center justify-center">
            <div className="flex flex-col items-center">
              <Spinner className="size-16" />
            </div>
          </Window>
        </div>
        <img src={ondulationBg} className="absolute bottom-0 left-0" />
      </PageLayout>
    )
  }

  return (
    <PageLayout className="relative justify-center">
      <div className="flex h-screen w-full flex-col items-center justify-center bg-purple-gradient">
        <img src={cubesTopLeft} className="absolute left-0 top-0 z-0" />
        <Window className="min-w-[50%] items-center justify-center">
          <div className="flex flex-col items-center">
            <h1 className="mb-8 text-center text-5xl font-bold leading-[60px] text-white">Congratulations 🎉</h1>
            <button onClick={handleAvatarClick} className="rounded-full bg-transparent p-0 shadow-lg">
              <RichAvatar message={avatarMessage} size="240" />
            </button>
            <div className="mt-8">
              <GoHomeButton />
            </div>
          </div>
        </Window>
      </div>
      <img src={ondulationBg} className="absolute bottom-0 left-0" />
    </PageLayout>
  )
}
