import { User } from '@alpha-school/reading-fluency-models'
import { useUser } from '@clerk/clerk-react'
import React, { useCallback, useEffect, useState } from 'react'

import { LessonAssignmentWithLesson, UserContext, UserContextType } from '@/hooks/useUser'
import { getUserAssignments, getUserWithLessons, verifyUser } from '@/utils/api'

interface UserProviderProps {
  children: React.ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { user: clerkUser } = useUser()
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [userAssignments, setUserAssignments] = useState<LessonAssignmentWithLesson[] | null>(null)

  const fetchUserData = useCallback(async () => {
    if (!clerkUser || !clerkUser.emailAddresses.length) {
      setIsLoading(false)
      return
    }

    try {
      const verifyResponse = await verifyUser()

      let userData: User = verifyResponse.data

      // If the user is a student, fetch their lessons with content
      if (userData.role === 'STUDENT') {
        const userWithLessonsResponse = await getUserWithLessons()
        userData = userWithLessonsResponse.data
      }

      setUser(userData)
      setError(null)
      return userData
    } catch (err) {
      setError(err as Error)
      return null
    } finally {
      setIsLoading(false)
    }
  }, [clerkUser])

  const fetchUserAssignments = useCallback(async () => {
    if (!user) return null

    try {
      const response = await getUserAssignments()
      setUserAssignments(response.data)
      return response.data
    } catch (err) {
      setError(err as Error)
      return null
    }
  }, [user])

  useEffect(() => {
    fetchUserData()
  }, [fetchUserData])

  useEffect(() => {
    fetchUserAssignments()
  }, [fetchUserAssignments])

  const value = {
    user,
    isLoading,
    error,
    refetchUserData: fetchUserData,
    fetchUserAssignments,
    userAssignments,
  } as UserContextType

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
