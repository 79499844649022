import { useAvatar } from 'alpha-ai-avatar-sdk-react'
import React, { createContext, ReactNode, useCallback, useEffect } from 'react'

import { useAudioRecording } from '@/context/AudioRecordingContext'
import { useMouthReading } from '@/context/MouthReadingContext/MouthReadingContext'
import { navigationListener } from '@/context/NavigationListenerContext/navigationListener'
import { useSpeech } from '@/context/SpeechContext'
import { useSpeechRecognition } from '@/context/SpeechRecognitionContext'

const NavigationListenerContext = createContext<undefined>(undefined)

interface NavigationListenerProviderProps {
  children: ReactNode
}

export const NavigationListenerProvider: React.FC<NavigationListenerProviderProps> = ({ children }) => {
  const { stopRecording } = useAudioRecording()
  const { stopListening } = useSpeechRecognition()
  const { stopSpeaking, handleStopRecording, reset } = useSpeech()
  const { stop: stopAvatar, isConnected, isAvatarSpeaking, disconnect } = useAvatar()
  const { stopMouthReading, stopListening: stopVADListening } = useMouthReading()

  const handleDisconnect = useCallback(() => {
    if (isAvatarSpeaking) {
      stopAvatar()
    }
  }, [isConnected, isAvatarSpeaking, stopAvatar, disconnect])

  useEffect(() => {
    navigationListener.init()
    navigationListener.addCleanupHandler(stopRecording)
    navigationListener.addCleanupHandler(stopListening)
    navigationListener.addCleanupHandler(stopSpeaking)
    navigationListener.addCleanupHandler(handleStopRecording)
    navigationListener.addCleanupHandler(reset)
    navigationListener.addCleanupHandler(handleDisconnect)
    navigationListener.addCleanupHandler(stopMouthReading)
    navigationListener.addCleanupHandler(stopVADListening)
    return () => {
      navigationListener.removeCleanupHandler(stopRecording)
      navigationListener.removeCleanupHandler(stopListening)
      navigationListener.removeCleanupHandler(stopSpeaking)
      navigationListener.removeCleanupHandler(handleStopRecording)
      navigationListener.removeCleanupHandler(reset)
      navigationListener.removeCleanupHandler(handleDisconnect)
      navigationListener.removeCleanupHandler(stopMouthReading)
      navigationListener.removeCleanupHandler(stopVADListening)
    }
  }, [
    stopRecording,
    stopListening,
    stopSpeaking,
    handleStopRecording,
    reset,
    handleDisconnect,
    stopMouthReading,
    stopVADListening,
  ])

  return <NavigationListenerContext.Provider value={undefined}>{children}</NavigationListenerContext.Provider>
}
