function Window({
  children,
  className,
  noPadding,
  gradient,
}: {
  children: React.ReactNode
  className?: string
  noPadding?: boolean
  gradient?: string
}) {
  return (
    <div
      className={`${
        !noPadding ? 'px-4 pb-12 pt-16' : ''
      } relative z-10 flex flex-col rounded-[24px] border-x-2 border-b-[16px] border-t-2 border-[#3800D7] ${
        gradient ? gradient : 'bg-gradient-to-b from-[#6027FF] to-[#6D09FC]'
      } drop-shadow-2xl ${className}`}
    >
      {children}
    </div>
  )
}

export default Window
