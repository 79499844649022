import { LesssonAssignmentResult } from '@alpha-school/reading-fluency-models'

import { FirstSoundFluencyAnalysis, FirstSoundFluencyResult } from '@/types/assessments/first-sound-fluency'
import {
  LetterNamingCorrespondenceAnalysis,
  LetterNamingCorrespondenceResult,
} from '@/types/assessments/letter-naming-correspondence'
import { LetterNamingFluencyAnalysis, LetterNamingFluencyResult } from '@/types/assessments/letter-naming-fluency'
import {
  LetterSoundCompletionsFluencyAnalysis,
  LetterSoundCompletionsFluencyResult,
} from '@/types/assessments/letter-sound-completions-fluency'
import { MazeAnalysis, MazeResult } from '@/types/assessments/maze'
import { OralReadingFluencyAnalysis, OralReadingFluencyResult } from '@/types/assessments/oral-reading-fluency'

import {
  PhonemeSegmentationFluencyAnalysis,
  PhonemeSegmentationFluencyResult,
} from './assessments/phoneme-segmentation-fluency'

export enum LessonIds {
  'letter-naming-correspondence' = 'Letter Naming Correspondence',
  'letter-naming-correspondence-no-highlight' = 'Letter Naming Correspondence (No Highlight)',
  'letter-naming-fluency' = 'Letter Naming Fluency',
  'letter-naming-new-highlight' = 'Letter Naming (New Highlight)',
  'letter-naming-no-highlight' = 'Letter Naming (No Highlight)',
  'first-sound-fluency' = 'First Sound Fluency',
  'first-sound-fluency-no-timer' = 'First Sound Fluency (No Timer)',
  'letter-sound-completions-fluency' = 'Letter Sound Completions Fluency',
  'oral-reading-fluency' = 'Oral Reading Fluency',
  'maze' = 'Maze',
  'phoneme-segmentation-fluency' = 'Phoneme Segmentation Fluency',
  'letter-naming-fluency-with-steps' = 'Letter Naming Fluency (With Steps)',
  'letter-naming-correspondence-with-steps' = 'Letter Naming Correspondence Fluency (With Steps)',
}

// Define available lesson IDs
export type LessonId =
  | 'letter-naming-correspondence'
  | 'letter-naming-correspondence-no-highlight'
  | 'letter-naming-correspondence-with-steps'
  | 'letter-naming-fluency-with-steps'
  | 'letter-naming-fluency'
  | 'letter-naming-no-highlight'
  | 'letter-naming-new-highlight'
  | 'first-sound-fluency'
  | 'first-sound-fluency-no-timer'
  | 'letter-sound-completions-fluency'
  | 'oral-reading-fluency'
  | 'maze'
  | 'phoneme-segmentation-fluency'

// Define a type map for lesson results
export type LessonResultMap = {
  'letter-naming-correspondence': LetterNamingCorrespondenceResult
  'letter-naming-correspondence-no-highlight': LetterNamingCorrespondenceResult
  'letter-naming-correspondence-with-steps': LetterNamingCorrespondenceResult
  'letter-naming-fluency-with-steps': LetterNamingFluencyResult
  'letter-naming-fluency': LetterNamingFluencyResult
  'letter-naming-no-highlight': LetterNamingFluencyResult
  'letter-naming-new-highlight': LetterNamingFluencyResult
  'first-sound-fluency': FirstSoundFluencyResult
  'first-sound-fluency-no-timer': FirstSoundFluencyResult
  'letter-sound-completions-fluency': LetterSoundCompletionsFluencyResult
  'oral-reading-fluency': OralReadingFluencyResult
  maze: MazeResult
  'phoneme-segmentation-fluency': PhonemeSegmentationFluencyResult
}

export type AnalysisResultMap = {
  'phoneme-segmentation-fluency': PhonemeSegmentationFluencyAnalysis
  'first-sound-fluency': FirstSoundFluencyAnalysis
  'first-sound-fluency-no-timer': FirstSoundFluencyAnalysis
  'letter-naming-fluency': LetterNamingFluencyAnalysis
  'letter-naming-new-highlight': LetterNamingFluencyAnalysis
  'letter-naming-correspondence': LetterNamingCorrespondenceAnalysis
  'letter-sound-completions-fluency': LetterSoundCompletionsFluencyAnalysis
  'oral-reading-fluency': OralReadingFluencyAnalysis
  maze: MazeAnalysis
  'letter-naming-correspondence-no-highlight': LetterNamingCorrespondenceAnalysis
  'letter-naming-no-highlight': LetterNamingFluencyAnalysis
  'letter-naming-fluency-with-steps': LetterNamingFluencyAnalysis
  'letter-naming-correspondence-with-steps': LetterNamingCorrespondenceAnalysis
}

export type StrippedDownFluencyLessonAssignmentResult = Omit<
  LesssonAssignmentResult,
  'result' | 'lessonId' | 'startedAt' | 'completedAt' | 'correctedResult'
>

interface BaseLessonAssignment extends StrippedDownFluencyLessonAssignmentResult {
  id: string
  userId: string
  position: number
  lesson: {
    title: string
    description?: string
    exercises: {
      props: any
      activities: {
        props: any
      }[]
    }[]
  }
  startedAt: string
  completedAt: string
}

// Make LessonAssignment generic
export interface LessonAssignment<T extends LessonId = LessonId> extends BaseLessonAssignment {
  lessonId: T
  result: LessonResultMap[T]
  lesssonAssignmentResults: LesssonAssignmentResult[]
  correctedResult?: LessonResultMap[T]
}
