import { useState } from 'react'

import { LOCALE_OPTIONS, LocaleOptionType } from '@/types/locale-option-type'

export const LocaleSelector = () => {
  const [currentLocale, setLocale] = useState(localStorage.getItem('user-locale') ?? 'en-US')
  const [isOpen, setOpen] = useState(false)
  const toggleDropdown = () => {
    setOpen(!isOpen)
  }
  const onLocaleChange = (option: LocaleOptionType) => {
    setLocale(option.value)
    setOpen(false)
    localStorage.setItem('user-locale', option.value)
  }

  return (
    <>
      <div
        className="absolute left-0 top-0 z-10 h-screen w-screen"
        onClick={() => {
          setOpen(false)
        }}
      />

      <div className="absolute right-10 top-10 z-20">
        <div className="flex flex-col items-end">
          <button
            id="dropdownRadioButton"
            data-dropdown-toggle="dropdownDefaultRadio"
            className="inline-flex items-center rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
            onClick={toggleDropdown}
          >
            {`${LOCALE_OPTIONS.find((option) => option.value === currentLocale)!.label} `}
            <svg
              className="ms-3 size-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>

          <div
            id="dropdownDefaultRadio"
            className={`z-10 ${
              isOpen ? '' : 'hidden'
            } divide-y divide-gray-100 rounded-lg bg-white shadow dark:divide-gray-600 dark:bg-gray-700`}
          >
            <ul
              className="space-y-3 p-3 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownRadioButton"
            >
              {LOCALE_OPTIONS.map((option) => (
                <li key={option.value}>
                  <div className="flex items-center">
                    <input
                      checked={option.value === currentLocale}
                      onChange={() => {
                        onLocaleChange(option)
                      }}
                      id={`locale-option-${option.value}`}
                      type="radio"
                      value={option.value}
                      name="locale-selection"
                      className="size-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                    />
                    <label
                      htmlFor={`locale-option-${option.value}`}
                      className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {option.label}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
