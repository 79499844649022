import { useUser } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'

export const SentryUserInjector: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useUser()

  Sentry.setUser({
    id: user?.id,
    email: user?.emailAddresses[0]?.emailAddress,
  })

  return children
}
