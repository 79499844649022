import { wrapPhoneme } from '../../utils/avatarMessage'

export const options = [
  {
    id: 'phrases',
    name: 'Phrases',
  },
  {
    id: 'letters',
    name: 'Letters',
  },
  {
    id: 'phonemes',
    name: 'Phonemes',
  },
]

export const excercisesContent = {
  phrases: [
    `Remember to tap the word and tell me the first sound that you hear in the word.`,
    `Now, let's start. Tap and say the first sound for each word I say. I'll let you know when we're done. Ready?`,
    `Stop. Great job completing this assessment! Wait just a few moments while we save your results.`,
    'See how I name these letters.',
    `Start here. feel free to follow along with your mouse on the screen. Ready, begin.`,
    `Great job!`,
  ],
  letters: [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ],
  phonemes: [
    `Listen to me say this word: goat! The first sound you hear in the word "goat" is.`,
    wrapPhoneme('gʌ', 'ipa'),
    `Listen.`,
    wrapPhoneme('gʌ', 'ipa'),
    `goat. Now tap the word, and say the first sound you hear in the word`,
    `Listen to me say this word: "ball." The first sound you hear in the word "ball" is.`,
    wrapPhoneme('bʌ', 'ipa'),
    `Listen.`,
    wrapPhoneme('bʌ', 'ipa'),
    `ball. Now tap the word, and say the first sound you hear in the word`,
    `Listen to me say this word: "cat." The first sound you hear in the word "cat" is.`,
    wrapPhoneme('kʌ', 'ipa'),
    `Listen.`,
    wrapPhoneme('kʌ', 'ipa'),
    `cat. Now tap the word, and say the first sound you hear in the word`,
  ],
}
