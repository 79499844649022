import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { InfoIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { PhonemeCard } from '@/components/Admin/Dashboards/PhonemeCard/PhonemeCard'
import { PhonemeScore } from '@/components/Admin/Dashboards/PhonemeCard/types/phoneme-score'
import { LoadingIcon, LoadingWrapper } from '@/components/Loading/Loading'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel'
import { Tooltip as TooltipComponent, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { useToast } from '@/hooks/use-toast'
import { parseFirstSoundFluency } from '@/lib/parsers/first-sound-fluency'
import { cn } from '@/lib/utils'
import { PhonemeData, ResultsWithAudioKey } from '@/types/assessments/first-sound-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'
import { correctLessonAssignmentResult, updateLessonAssignment } from '@/utils/api'

import AudioPlayer from './AudioPlayer'
interface FirstSoundFluencyScoreProps {
  filteredAssessments: LessonAssignment<'first-sound-fluency'>[]
}

interface ScoreWithDate {
  correctCount: number
  incorrectCount: number
  correctPhonemes: PhonemeData[]
  missedPhonemes: PhonemeData[]
  commonlyMissedPhonemes: PhonemeData[]
  completedAt: string
  rawDate: Date
  audioKey?: string
}

type PhonemeStats = {
  correctCount: number
  incorrectCount: number
  totalCorrectMatchQuality: number
  totalIncorrectMatchQuality: number
  instances: {
    isCorrect: boolean
    matchQuality: number
  }[]
} & Partial<PhonemeData>

const initializePhonemeStats = (phonemeData: PhonemeData): PhonemeStats => ({
  correctCount: 0,
  incorrectCount: 0,
  totalCorrectMatchQuality: 0,
  totalIncorrectMatchQuality: 0,
  topMatches: phonemeData.topMatches,
  audioKey: phonemeData.audioKey,
  referenceWord: phonemeData.referenceWord,
  isIsolatedPhoneme: phonemeData.isIsolatedPhoneme,
  isCorrect: phonemeData.isCorrect,
  instances: [],
})

const updatePhonemeStats = (stats: PhonemeStats, phonemeData: PhonemeData, isCorrect: boolean): PhonemeStats => {
  if (isCorrect) {
    stats.correctCount++
    stats.totalCorrectMatchQuality += phonemeData.matchQuality
  } else {
    stats.incorrectCount++
    stats.totalIncorrectMatchQuality += phonemeData.matchQuality
  }
  stats.instances.push({ isCorrect, matchQuality: phonemeData.matchQuality })
  return stats
}

export default function FirstSoundFluencyScore({ filteredAssessments }: FirstSoundFluencyScoreProps) {
  const [isSaving, setIsSaving] = useState(false)
  const { toast } = useToast()
  const queryClient = useQueryClient()

  const individualAssessmentScore = useMemo(() => {
    if (filteredAssessments.length === 1) {
      return parseFirstSoundFluency(filteredAssessments?.[0].result)
    } else {
      return null
    }
  }, [filteredAssessments])

  const results = useMemo(() => {
    return filteredAssessments
      ?.map((assignment) => {
        const result = assignment.correctedResult ?? assignment.result
        const activity = result.exercises?.[0]?.activities?.[0]
        const audioKey = activity?.results && 'audioKey' in activity.results ? activity.results.audioKey : undefined

        return {
          ...parseFirstSoundFluency(result),
          completedAt: new Date(assignment.completedAt).toLocaleString(),
          rawDate: new Date(assignment.completedAt),
          audioKey,
        }
      })
      .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime()) as ScoreWithDate[]
  }, [filteredAssessments])

  const phonemeStats = useMemo(() => {
    return results.reduce<Record<string, PhonemeStats>>((acc, analysis) => {
      // Process correct phonemes
      analysis.correctPhonemes.forEach((phonemeData) => {
        if (!acc[phonemeData.phoneme]) {
          acc[phonemeData.phoneme] = initializePhonemeStats(phonemeData)
        }
        acc[phonemeData.phoneme] = updatePhonemeStats(acc[phonemeData.phoneme], phonemeData, true)
      })

      // Process incorrect phonemes
      analysis.missedPhonemes.forEach((phonemeData) => {
        if (!acc[phonemeData.phoneme]) {
          acc[phonemeData.phoneme] = initializePhonemeStats(phonemeData)
        }
        acc[phonemeData.phoneme] = updatePhonemeStats(acc[phonemeData.phoneme], phonemeData, false)
      })

      return acc
    }, {})
  }, [results])

  const aggregatedPhonemes = useMemo(() => {
    if (!results) return []

    return Object.entries(phonemeStats)
      .map(([phoneme, stats]) => ({
        phoneme,
        totalCount: stats.correctCount + stats.incorrectCount,
        correctCount: stats.correctCount,
        incorrectCount: stats.incorrectCount,
        averageCorrectMatchQuality: stats.correctCount > 0 ? stats.totalCorrectMatchQuality / stats.correctCount : 0,
        averageIncorrectMatchQuality:
          stats.incorrectCount > 0 ? stats.totalIncorrectMatchQuality / stats.incorrectCount : 0,
        topMatches: stats.topMatches,
        audioKey: stats.audioKey,
        referenceWord: stats.referenceWord,
        isIsolatedPhoneme: stats.isIsolatedPhoneme,
        isCorrect: stats.correctCount > 0,
        isIncorrect: stats.incorrectCount > 0,
      }))
      .sort((a, b) => b.totalCount - a.totalCount)
  }, [results, phonemeStats])

  const handlePhonemeSave = async (phonemeData: PhonemeData, score: PhonemeScore) => {
    const results = filteredAssessments[0].result.exercises[0].activities[0].results as ResultsWithAudioKey
    const responses = results.firstSoundResponses
    const responseIndex = responses.findIndex((response) => response.reference_word === phonemeData.referenceWord)

    if (responseIndex !== -1) {
      responses[responseIndex].isCorrect = score === 'CORRECT' || score === 'PARTIALLY_CORRECT'
      responses[responseIndex].isIsolatedPhoneme = score === 'CORRECT'
    }

    setIsSaving(true)

    try {
      const payload = {
        status: 'COMPLETED',
        correctedResult: filteredAssessments[0].result,
      }

      await Promise.all([
        updateLessonAssignment({
          lessonAssignmentId: filteredAssessments[0].id,
          ...payload,
        }),
        correctLessonAssignmentResult(filteredAssessments[0].lesssonAssignmentResults[0].id, payload),
      ])

      toast({
        title: 'Phoneme score updated successfully',
        variant: 'success',
      })
    } catch (error) {
      Sentry.captureException(error)
      console.error('Error updating lesson assignment', error)
      toast({
        title: 'Error updating phoneme score',
        variant: 'destructive',
      })
    } finally {
      setIsSaving(false)
      queryClient.invalidateQueries({
        queryKey: ['student-results', filteredAssessments[0].userId, filteredAssessments[0].lessonId],
      })
      queryClient.invalidateQueries({ queryKey: ['lesson-assignment-result', filteredAssessments[0].id] })
    }
  }

  return (
    <>
      <div
        className={cn(
          'grid grid-cols-1 gap-8 md:grid-cols-2',
          filteredAssessments.length > 1 ? 'xl:grid-cols-3' : 'xl:grid-cols-4'
        )}
      >
        {filteredAssessments.length === 1 && results?.[0]?.audioKey && (
          <Card className="xl:col-span-4">
            <CardHeader>
              <CardTitle>Assignment Recording</CardTitle>
            </CardHeader>
            <CardContent>
              <AudioPlayer s3Key={results?.[0]?.audioKey} />
            </CardContent>
          </Card>
        )}
        {filteredAssessments.length > 1 ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={results}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="correctCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Correct Phonemes']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="correctCount" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={results}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="incorrectCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Incorrect Phonemes']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="incorrectCount" stroke="#f87171" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Commonly Missed Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={aggregatedPhonemes
                        .filter((phoneme) => phoneme.incorrectCount > 0)
                        .sort((a, b) => b.incorrectCount - a.incorrectCount)}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="phoneme" />
                      <YAxis allowDecimals={false} domain={[0, 'auto']} />
                      <Tooltip
                        formatter={(value) => [`${value} times`, 'Times Missed']}
                        labelFormatter={(_, data) => `Phoneme ${data[0]?.payload?.phoneme}` || ''}
                      />
                      <Bar dataKey="incorrectCount" fill="#f87171" name="Times Missed" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Assessment Score</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-blue-600">{individualAssessmentScore?.totalScore || '0.00'}</p>

                <p className="mt-2 flex items-center gap-x-2 text-sm text-gray-600">
                  Score details{' '}
                  <TooltipComponent delayDuration={100}>
                    <TooltipTrigger asChild>
                      <InfoIcon className="size-4 cursor-pointer" />
                    </TooltipTrigger>
                    <TooltipContent>
                      The student receives 2 points for correctly
                      <br />
                      identifying the initial phoneme in isolation
                      <br />
                      and 1 point for identifying the correct initial
                      <br />
                      sounds (consonant blend, consonant plus
                      <br />
                      vowel, or consonant blend plus vowel).
                    </TooltipContent>
                  </TooltipComponent>
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Correct Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-green-600">{results?.[0]?.correctCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">Phonemes identified correctly</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-red-600">{results[0]?.incorrectCount || 0}</p>
                <p className="mt-2 flex items-center gap-x-2 text-sm text-gray-600">
                  Phonemes identified incorrectly{' '}
                  <TooltipComponent delayDuration={100}>
                    <TooltipTrigger asChild>
                      <InfoIcon className="size-4 cursor-pointer" />
                    </TooltipTrigger>
                    <TooltipContent>
                      Repeated incorrect phonemes are aggregated
                      <br />
                      into a single incorrect phoneme.
                      <br />
                      You can see the number of times a specific
                      <br />
                      phoneme was missed by looking at the badge
                      <br />
                      next to the incorrect phoneme in the list below.
                    </TooltipContent>
                  </TooltipComponent>
                </p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Missed Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {aggregatedPhonemes
                    .filter((phoneme) => phoneme.isIncorrect)
                    .map(({ phoneme, averageIncorrectMatchQuality }) => (
                      <div key={phoneme} className="flex items-center gap-2 rounded-md bg-red-100 px-3 py-1 text-sm">
                        <span className="font-semibold text-red-600">{phoneme}</span>
                        <span className="text-xs text-red-500">{Math.round(averageIncorrectMatchQuality)}%</span>
                      </div>
                    ))}
                  {aggregatedPhonemes.filter((phoneme) => !phoneme.isCorrect).length === 0 && (
                    <p className="text-sm text-gray-600">No phonemes were missed!</p>
                  )}
                </div>
              </CardContent>
            </Card>

            <Card className={cn(filteredAssessments.length > 1 ? 'xl:col-span-3' : 'xl:col-span-4', 'relative')}>
              <CardHeader>
                <CardTitle>Top Matches for Missed Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                {results[0]?.missedPhonemes.length > 0 ? (
                  <div className="relative">
                    <Carousel
                      opts={{
                        align: 'start',
                      }}
                      className="w-full px-12"
                    >
                      <CarouselContent className="-ml-4">
                        {results[0]?.missedPhonemes.map((phonemeData, index) => (
                          <CarouselItem
                            key={`${phonemeData.phoneme}-${index}`}
                            className="basis-full pl-4 md:basis-1/2 lg:basis-1/3"
                          >
                            <PhonemeCard phonemeData={phonemeData} handleSave={handlePhonemeSave} />
                          </CarouselItem>
                        ))}
                      </CarouselContent>
                      {results[0]?.missedPhonemes.length > 3 && (
                        <>
                          <CarouselPrevious className="absolute -left-0 top-1/2 -translate-y-1/2" />
                          <CarouselNext className="absolute -right-0 top-1/2 -translate-y-1/2" />
                        </>
                      )}
                    </Carousel>
                  </div>
                ) : (
                  <p className="text-sm text-gray-600">No missed phonemes to display!</p>
                )}
              </CardContent>
              {isSaving && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/50">
                  <LoadingWrapper>
                    <LoadingIcon />
                  </LoadingWrapper>
                </div>
              )}
            </Card>

            <Card className={cn(filteredAssessments.length > 1 ? 'xl:col-span-3' : 'xl:col-span-4', 'relative')}>
              <CardHeader>
                <CardTitle>Top Matches for Correct Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                {results[0]?.correctPhonemes.length > 0 ? (
                  <div className="relative">
                    <Carousel
                      opts={{
                        align: 'start',
                      }}
                      className="w-full px-12"
                    >
                      <CarouselContent className="-ml-4">
                        {results[0]?.correctPhonemes.map((phonemeData, index) => (
                          <CarouselItem
                            key={`${phonemeData.phoneme}-${index}`}
                            className="basis-full pl-4 md:basis-1/2 lg:basis-1/3"
                          >
                            <PhonemeCard phonemeData={phonemeData} handleSave={handlePhonemeSave} />
                          </CarouselItem>
                        ))}
                      </CarouselContent>
                      {results[0]?.correctPhonemes.length > 3 && (
                        <>
                          <CarouselPrevious className="absolute -left-0 top-1/2 -translate-y-1/2" />
                          <CarouselNext className="absolute -right-0 top-1/2 -translate-y-1/2" />
                        </>
                      )}
                    </Carousel>
                  </div>
                ) : (
                  <p className="text-sm text-gray-600">No correct phonemes to display!</p>
                )}
              </CardContent>
              {isSaving && (
                <div className="absolute inset-0 flex items-center justify-center bg-white/50">
                  <LoadingWrapper>
                    <LoadingIcon />
                  </LoadingWrapper>
                </div>
              )}
            </Card>
          </>
        )}
      </div>
    </>
  )
}
