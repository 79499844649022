import React, { createContext, useCallback, useContext, useRef } from 'react'
import ReactCanvasConfetti from 'react-canvas-confetti'
import {
  TCanvasConfettiAnimationOptions,
  TCanvasConfettiInstance,
  TOnInitComponentFn,
} from 'react-canvas-confetti/dist/types'

interface ConfettiContextType {
  fireConfetti: (options?: TCanvasConfettiAnimationOptions) => void
}

export const ConfettiContext = createContext<ConfettiContextType>({
  fireConfetti: () => {},
})

export const useConfetti = () => useContext(ConfettiContext)

export const ConfettiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const confettiInstanceRef = useRef<TCanvasConfettiInstance | null>(null)

  const getInstance: TOnInitComponentFn = useCallback(async ({ confetti }) => {
    confettiInstanceRef.current = confetti
  }, [])

  const fireConfetti = useCallback(() => {
    confettiInstanceRef.current?.({
      particleCount: 500,
      angle: 90,
      spread: 360,
      startVelocity: 45,
      decay: 0.9,
      scalar: 1,
      gravity: 1,
      drift: 0,
      ticks: 600,
      origin: { x: 0.5, y: 0.5 },
      zIndex: -1,
      disableForReducedMotion: false,
    })
  }, [])

  const style: React.CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 999,
  }

  return (
    <ConfettiContext.Provider value={{ fireConfetti }}>
      {children}
      <ReactCanvasConfetti onInit={getInstance} style={style} />
    </ConfettiContext.Provider>
  )
}
