export const wrapPhoneme = (phoneme: string, alphabet?: string) => {
  return `<phoneme alphabet="${alphabet ? alphabet : 'ipa'}" ph="${phoneme}"> ${phoneme} </phoneme>`
}

export const breakInBetween = (parts: string[], delayInMs: number) => {
  return parts.join(` <break time="${delayInMs}ms" /> `)
}

export const formatSpellOut = (parts: string[]) => {
  return breakInBetween(parts, 250)
}
