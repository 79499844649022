import { useQuery } from '@tanstack/react-query'

import { LoadingIcon } from '@/components/Loading'
import { StudentDetailsResponse } from '@/types/student'
import { getStudentResults } from '@/utils/api'

import { KindergartenBenchmark } from './KindergartenBenchmark'

export default function CompositeScore({ student }: { student: StudentDetailsResponse }) {
  const {
    data: studentResults,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['student-results', student.id],
    queryFn: async () => {
      const { data } = await getStudentResults(student.id)
      return data[0]
    },
    enabled: !!student.id,
  })

  if (isLoading || isFetching)
    return (
      <div className="flex size-full h-[480px] items-center justify-center">
        <LoadingIcon />
      </div>
    )

  if (student.grade === 'KINDERGARTEN') {
    return <KindergartenBenchmark student={studentResults!} />
  }

  return <div>Upcoming</div>
}
