export interface LocaleOptionType {
  label: string
  value: string
}

export const LOCALE_OPTIONS: LocaleOptionType[] = [
  { label: 'English (United States)', value: 'en-US' },
  { label: 'English (United Kingdom)', value: 'en-GB' },
  { label: 'English (Australia)', value: 'en-AU' },
  { label: 'English (Canada)', value: 'en-CA' },
  { label: 'English (India)', value: 'en-IN' },
]
