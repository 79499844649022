export function splitFile(file: File, partSize: number) {
  const parts = []
  for (let i = 0; i < file.size; i += partSize) {
    const part = file.slice(i, i + partSize)
    parts.push(part)
  }
  return parts
}

export function calculatePartSize(fileSizeInBytes: number) {
  const defaultPartSize = parseInt(import.meta.env.VITE_UPLOAD_MULTIPART_PART_SIZE) * 1024 * 1024

  const maxParts = 999
  const minimumPartSizeBytes = Math.ceil(fileSizeInBytes / maxParts)

  if (defaultPartSize > minimumPartSizeBytes) {
    return defaultPartSize
  }

  return minimumPartSizeBytes
}

export async function getFileChecksum(file: File): Promise<string> {
  try {
    const arrayBuffer = await file.arrayBuffer()

    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer)

    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')

    return hashHex
  } catch (error) {
    console.log('Error getting file checksum.', error)
    return ''
  }
}

export function generateFakeAudioFile(sizeInMB: number, fileName: string = 'test-audio.mp4'): File {
  // Generate an ArrayBuffer of the specified size
  const sizeInBytes = sizeInMB * 1024 * 1024
  const buffer = new ArrayBuffer(sizeInBytes)

  // Fill the buffer with random data
  const view = new Uint8Array(buffer)
  for (let i = 0; i < sizeInBytes; i++) {
    view[i] = Math.floor(Math.random() * 256)
  }

  // Create a Blob and then a File from the buffer
  const blob = new Blob([buffer], { type: 'audio/mp4' })
  return new File([blob], fileName, { type: 'audio/mp4' })
}
