import './styles.css'
import './index.css'

import * as Sentry from '@sentry/react'
import { PostHogProvider } from 'posthog-js/react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App.tsx'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0,
})

const options = {
  api_host: import.meta.env.VITE_POSTHOG_HOST,
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY} options={options}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </PostHogProvider>
  // </React.StrictMode>
)
