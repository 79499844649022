import { PlayIcon } from 'lucide-react'
import { useEffect } from 'react'

import bubbleSvg from '@/assets/bubble.png'
import cubesBottomRight from '@/assets/cubes_bottom_right.png'
import ondulationBgPurple from '@/assets/ondulationBgPurple.svg'
import DynamicComponentLoader from '@/components/DynamicComponentLoader'
import PageLayout from '@/components/PageLayout'
import Spinner from '@/components/Spinner'
import TopBar from '@/components/TopBar/TopBar'
import Window from '@/components/Window'
import { useAvatarWrapper } from '@/context/AvatarWrapperContext'
import { useMasteryLesson } from '@/context/MasteryLessonContext'
import exerciseComponentsMap from '@/utils/exerciseMap'
function MasteryLesson({ withCamera }: { withCamera?: boolean }) {
  const { initialized, loadNextLessonLesson, loadingNextLesson } = useMasteryLesson()
  const { findPlaceholder, isGlobalAvatarPlaceholder } = useAvatarWrapper()

  useEffect(() => {
    if (!initialized) {
      window.location.href = '/'
    }
  }, [initialized])

  const { exerciseType, currentExercise, currentLessonAssignmentId, data, showNextLessonButton } = useMasteryLesson()

  useEffect(() => {
    findPlaceholder()
  }, [isGlobalAvatarPlaceholder, currentLessonAssignmentId, currentExercise])

  if (!exerciseType) return
  const exercise = data.exercises[currentExercise]

  if (!initialized || !currentLessonAssignmentId) return

  return (
    <>
      {isGlobalAvatarPlaceholder && (
        <div className="fixed bottom-[30px] left-[20%] z-50  flex h-[200px] w-[280px] items-center justify-center">
          <div className="relative flex items-center justify-center">
            {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
            <div className="global-avatar-placeholder absolute left-1/2 top-1/2 size-px -translate-x-1/2 -translate-y-1/2" />
            <img src={bubbleSvg} alt="bubble" className="h-[200px] w-[280px]" />
          </div>
        </div>
      )}
      <div className="absolute top-0 z-[51] flex w-full items-center justify-center ">
        {!withCamera && (
          <div className="w-[690px]">
            <TopBar showTitle={!showNextLessonButton} title={exercise?.props?.title} />
          </div>
        )}
      </div>
      <div
        style={{
          position: 'relative',
          backgroundColor: '#6d09fc',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right bottom',
          backgroundImage: `url(${cubesBottomRight}), url(${ondulationBgPurple})`,
        }}
      >
        <PageLayout className="relative mt-10 pt-32">
          <div className="relative h-full pb-[280px]">
            {showNextLessonButton ? (
              <Window
                noPadding
                className="min-w-[690px] items-center justify-center border-semi-white bg-white-gradient py-[40px]"
              >
                <div className="flex flex-col items-center justify-center">
                  <p className="mb-4 text-3xl">Next Assessment</p>
                  <button
                    onClick={loadNextLessonLesson}
                    className={
                      'mt-8 flex animate-scale-pulse items-center justify-center rounded-full border-b-8 border-none border-blue-600 bg-blue-500 p-8 text-white shadow-xl transition-transform hover:translate-y-1 active:translate-y-2 active:border-b-0'
                    }
                  >
                    {loadingNextLesson ? (
                      <div className="size-[64px] min-h-[64px]">
                        <Spinner className="z-10 size-16 animate-spin" />
                      </div>
                    ) : (
                      <PlayIcon className="z-10 size-16" fill="white" />
                    )}
                    <span className="absolute inline-flex size-full animate-ping rounded-full bg-blue-500 opacity-75"></span>
                  </button>
                </div>
              </Window>
            ) : (
              <DynamicComponentLoader
                style={{
                  display: showNextLessonButton ? 'none' : 'block',
                }}
                key={`exercise-${currentExercise}-${currentLessonAssignmentId}`}
                componentProps={exercise.props}
                ComponentToRender={exerciseComponentsMap[exerciseType]}
              />
            )}
          </div>
        </PageLayout>
      </div>
    </>
  )
}

export default MasteryLesson
