type CleanupHandler = () => void

class NavigationListener {
  private cleanupHandlers: Set<CleanupHandler> = new Set()
  private initialized: boolean = false

  init() {
    if (this.initialized) return
    this.initialized = true

    // Handle browser back/forward buttons
    window.addEventListener('popstate', () => {
      this.runCleanup()
    })

    // Intercept link clicks
    document.addEventListener('click', (e) => {
      const target = e.target as HTMLElement
      const link = target.closest('a')

      if (link && link.href && !link.target && link.origin === window.location.origin) {
        this.runCleanup()
      }
    })

    // Intercept programmatic navigation
    const originalPushState = window.history.pushState
    const originalReplaceState = window.history.replaceState

    window.history.pushState = (...args) => {
      this.runCleanup()
      originalPushState.apply(window.history, args)
    }

    window.history.replaceState = (...args) => {
      this.runCleanup()
      originalReplaceState.apply(window.history, args)
    }
  }

  addCleanupHandler(handler: CleanupHandler) {
    this.cleanupHandlers.add(handler)
    return () => this.removeCleanupHandler(handler)
  }

  removeCleanupHandler(handler: CleanupHandler) {
    this.cleanupHandlers.delete(handler)
  }

  private runCleanup() {
    this.cleanupHandlers.forEach((handler) => {
      if (handler) {
        try {
          handler()
        } catch (error) {
          console.error('Error in cleanup handler:', error)
        }
      }
    })
  }
}

export const navigationListener = new NavigationListener()
