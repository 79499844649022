import { AnalysisResultMap, LessonAssignment, LessonId, LessonResultMap } from '@/types/lesson-assignment'

import { PartialCompositeScore } from './types/composite-score'

export function getTrimesterDate(trimester: number) {
  const currentYear = new Date().getFullYear()
  const month = (trimester - 1) * 4
  return new Date(currentYear, month, 1)
}

export function getPeriodByTrimester(assessmentResults: LessonAssignment[], trimester: number) {
  const nextYear = new Date(new Date().getFullYear() + 1, 0, 1)
  const startDate = getTrimesterDate(trimester)
  const endDate = trimester < 3 ? getTrimesterDate(trimester + 1) : nextYear

  return assessmentResults
    .filter((assessment) => {
      const assessmentDate = new Date(assessment.completedAt)
      return assessmentDate >= startDate && assessmentDate < endDate
    })
    .sort((a, b) => new Date(a.completedAt).getTime() - new Date(b.completedAt).getTime())
}

export function getPartialCompositeScores<T extends LessonId = LessonId>(
  assessmentResults: LessonAssignment<T>[],
  parser: (
    result: LessonResultMap[T],
    pronunciationScoreThreshold: number,
    useAzureMispronunciationFlag: boolean,
    expectedLetters?: string[]
  ) => AnalysisResultMap[T],
  pronunciationScoreThreshold: number,
  useAzureMispronunciationFlag: boolean
): PartialCompositeScore {
  const [beginnerPeriod] = getPeriodByTrimester(assessmentResults, 1)
  const [middlePeriod] = getPeriodByTrimester(assessmentResults, 2)
  const [endPeriod] = getPeriodByTrimester(assessmentResults, 3)

  const beginner = beginnerPeriod
    ? parser(beginnerPeriod.result as LessonResultMap[T], pronunciationScoreThreshold, useAzureMispronunciationFlag)
    : null
  const middle = middlePeriod
    ? parser(middlePeriod.result as LessonResultMap[T], pronunciationScoreThreshold, useAzureMispronunciationFlag)
    : null
  const end = endPeriod
    ? parser(endPeriod.result as LessonResultMap[T], pronunciationScoreThreshold, useAzureMispronunciationFlag)
    : null

  return {
    beginner: {
      score: beginner?.totalScore ?? 0,
      date: beginnerPeriod?.completedAt ? new Date(beginnerPeriod.completedAt) : null,
    },
    middle: {
      score: middle?.totalScore ?? 0,
      date: middlePeriod?.completedAt ? new Date(middlePeriod.completedAt) : null,
    },
    end: {
      score: end?.totalScore ?? 0,
      date: endPeriod?.completedAt ? new Date(endPeriod.completedAt) : null,
    },
  }
}
