import { MazeAnalysis, MazeResult } from '@/types/assessments/maze'

export function parseMaze(assessmentResult: MazeResult): MazeAnalysis {
  const analysis: MazeAnalysis = {
    totalWords: 0,
    correctCount: 0,
    incorrectCount: 0,
    accuracyPercentage: 0,
    timeInSeconds: 0,
    wordsPerMinute: 0,
    commonMistakes: [], // Patterns of incorrect word choices
    correctSequences: 0, // Consecutive correct answers
    longestCorrectSequence: 0,
    totalScore: 0,
  }

  // Get the first exercise and activity
  const exercise = assessmentResult.exercises['0']
  const activity = exercise.activities['0']
  const { wordsSelection, durationInSec } = activity.results

  analysis.timeInSeconds = durationInSec
  analysis.totalWords = wordsSelection.length

  let currentSequence = 0
  const previousMistakes: { correct: string; selected: string; count: number }[] = []

  wordsSelection.forEach((word) => {
    if (word.isCorrect) {
      analysis.correctCount++
      currentSequence++
      analysis.longestCorrectSequence = Math.max(analysis.longestCorrectSequence, currentSequence)
    } else {
      analysis.incorrectCount++
      currentSequence = 0

      // Track mistake patterns
      const existingMistake = previousMistakes.find(
        (m) => m.correct === word.correctWord && m.selected === word.selectedWord
      )

      if (existingMistake) {
        existingMistake.count++
      } else {
        previousMistakes.push({
          correct: word.correctWord,
          selected: word.selectedWord,
          count: 1,
        })
      }
    }
  })

  // Calculate derived metrics
  analysis.accuracyPercentage = (analysis.correctCount / analysis.totalWords) * 100
  analysis.wordsPerMinute = (analysis.totalWords / durationInSec) * 60

  // Get top 5 most common mistakes
  analysis.commonMistakes = previousMistakes
    .sort((a, b) => b.count - a.count)
    .slice(0, 5)
    .map((mistake) => ({
      correctWord: mistake.correct,
      incorrectWord: mistake.selected,
      frequency: mistake.count,
    }))

  return analysis
}
