import { Grade } from '@alpha-school/reading-fluency-models'

import { AssessmentResult } from './assessment'
import { LessonAssignment } from './lesson-assignment'

export interface Student {
  id: string
  name: string | null
  email: string
  grade: Grade | null
  createdAt: string
}

export interface StudentDetailsResponse {
  id: string
  name?: string
  email: string
  role: 'STUDENT'
  grade: string
  createdAt: string
  assessments: AssessmentResult[]
  lessonAssignments: LessonAssignment[]
}

export enum GradesColors {
  'KINDERGARTEN' = 'bg-green-100 text-green-800',
  'FIRST_GRADE' = 'bg-blue-100 text-blue-800',
  'SECOND_GRADE' = 'bg-yellow-100 text-yellow-800',
  'THIRD_GRADE' = 'bg-purple-100 text-purple-800',
  'FOURTH_GRADE' = 'bg-pink-100 text-pink-800',
  'FIFTH_GRADE' = 'bg-orange-100 text-orange-800',
  'SIXTH_GRADE' = 'bg-red-100 text-red-800',
}
