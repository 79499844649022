import { useMemo } from 'react'
import { Bar, BarChart, Line } from 'recharts'
import { CartesianGrid, LineChart, Tooltip, XAxis, YAxis } from 'recharts'
import { ResponsiveContainer } from 'recharts'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { parseMaze } from '@/lib/parsers/maze'
import { LessonAssignment } from '@/types/lesson-assignment'

type MazeScoreProps = {
  filteredAssessments: LessonAssignment<'maze'>[]
}

export default function MazeScore({ filteredAssessments }: MazeScoreProps) {
  const scores = useMemo(() => {
    return filteredAssessments
      .map((assignment) => {
        const parsed = parseMaze(assignment.result)
        return {
          ...parsed,
          accuracyPercentage: Number(parsed.accuracyPercentage.toFixed(2)),
          wordsPerMinute: Number(parsed.wordsPerMinute.toFixed(2)),
          completedAt: new Date(assignment.completedAt).toLocaleString(),
          rawDate: new Date(assignment.completedAt),
        }
      })
      .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime())
  }, [filteredAssessments])

  const averageStats = useMemo(() => {
    if (!scores.length) return null

    return {
      accuracy: (scores.reduce((acc, score) => acc + score.accuracyPercentage, 0) / scores.length).toFixed(2),
      wordsPerMinute: (scores.reduce((acc, score) => acc + score.wordsPerMinute, 0) / scores.length).toFixed(2),
      correctRate: (
        scores.reduce((acc, score) => acc + (score.correctCount / score.totalWords) * 100, 0) / scores.length
      ).toFixed(2),
    }
  }, [scores])

  const commonMistakePatterns = useMemo(() => {
    if (!scores.length) return []

    // Aggregate mistakes across all assessments
    const mistakeMap = new Map<string, { count: number; correctWord: string; incorrectWord: string }>()

    scores.forEach((score) => {
      score.commonMistakes.forEach((mistake) => {
        const key = `${mistake.correctWord}-${mistake.incorrectWord}`
        const existing = mistakeMap.get(key)
        if (existing) {
          existing.count += mistake.frequency
        } else {
          mistakeMap.set(key, {
            count: mistake.frequency,
            correctWord: mistake.correctWord,
            incorrectWord: mistake.incorrectWord,
          })
        }
      })
    })

    return Array.from(mistakeMap.values())
      .sort((a, b) => b.count - a.count)
      .slice(0, 5)
  }, [scores])

  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
      <Card>
        <CardHeader>
          <CardTitle>Reading Speed & Accuracy</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={scores}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="completedAt" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Line
                  yAxisId="left"
                  type="monotone"
                  dataKey="wordsPerMinute"
                  name="Words per Minute"
                  stroke="#8884d8"
                />
                <Line yAxisId="right" type="monotone" dataKey="accuracyPercentage" name="Accuracy %" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Correct vs Total Words</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={scores}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="completedAt" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="totalWords" fill="#8884d8" name="Total Words" />
                <Bar dataKey="correctCount" fill="#82ca9d" name="Correct Words" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Common Mistakes</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {commonMistakePatterns.map((mistake) => (
              <div key={`${mistake.correctWord}-${mistake.incorrectWord}`} className="rounded-lg bg-slate-50 p-3">
                <div className="flex items-center justify-between">
                  <div>
                    <span className="text-green-600">{mistake.correctWord}</span>
                    <span className="mx-2">→</span>
                    <span className="text-red-600">{mistake.incorrectWord}</span>
                  </div>
                  <span className="text-sm text-gray-500">{mistake.count}x</span>
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <Card className="xl:col-span-3">
        <CardHeader>
          <CardTitle>Average Performance</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div className="rounded-lg bg-blue-50 p-4">
              <p className="text-sm text-gray-600">Accuracy</p>
              <p className="text-2xl font-bold text-blue-600">{averageStats?.accuracy || '0'}%</p>
            </div>
            <div className="rounded-lg bg-green-50 p-4">
              <p className="text-sm text-gray-600">Words per Minute</p>
              <p className="text-2xl font-bold text-green-600">{averageStats?.wordsPerMinute || '0'}</p>
            </div>
            <div className="rounded-lg bg-purple-50 p-4">
              <p className="text-sm text-gray-600">Correct Rate</p>
              <p className="text-2xl font-bold text-purple-600">{averageStats?.correctRate || '0'}%</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
