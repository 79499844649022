import { LessonAssignment } from '@alpha-school/reading-fluency-models'
import { User } from '@sentry/react'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { ExternalLink, Search, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useNavigate } from 'react-router-dom'

import AdminLayout from '@/components/Admin/ui/AdminLayout'
import { LoadingIcon } from '@/components/Loading/Loading'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { getStudentDetails, getStudents } from '@/utils/api'

function Dashboard() {
  const queryClient = useQueryClient()
  const queryString = window.location.search
  const navigate = useNavigate()
  const { ref: loadMoreRef, inView } = useInView()
  const ITEMS_PER_PAGE = 10

  const [searchTerm, setSearchTerm] = useState('')
  const [activeSearch, setActiveSearch] = useState('')
  const [filter, setFilter] = useState<'all' | 'with_pending' | 'completed'>('all')
  const [isSearching, setIsSearching] = useState(false)

  const studentsQuery = useQuery({
    queryKey: ['total-students'],
    queryFn: async () => {
      const res = await getStudents()
      return res.data
    },
  })

  const totalStudents = studentsQuery?.data
    ? studentsQuery.data.filter((student: User) => student.role === 'STUDENT').length
    : 0

  const {
    data: studentsPages,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isInitialLoading,
  } = useInfiniteQuery({
    queryKey: ['students-infinite', activeSearch, filter],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getStudents()
      const students = res.data
        .filter((student: User) => {
          const matchesSearch = activeSearch
            ? (student.name || student.email || '').toLowerCase().includes(activeSearch.toLowerCase().trim()) ||
              student?.email?.toLowerCase()?.includes(activeSearch.toLowerCase().trim())
            : true

          const hasPendingAssignments = student.lessonAssignments.some(
            (lesson: LessonAssignment) => lesson.status === 'NOT_STARTED'
          )

          switch (filter) {
            case 'with_pending':
              return student.role === 'STUDENT' && hasPendingAssignments && matchesSearch
            case 'completed':
              return student.role === 'STUDENT' && !hasPendingAssignments && matchesSearch
            default:
              return student.role === 'STUDENT' && matchesSearch
          }
        })
        .sort(
          (a: User, b: User) =>
            b.lessonAssignments.filter((lesson: LessonAssignment) => lesson.status === 'NOT_STARTED').length -
            a.lessonAssignments.filter((lesson: LessonAssignment) => lesson.status === 'NOT_STARTED').length
        )

      const start = (pageParam - 1) * ITEMS_PER_PAGE
      return {
        students: students.slice(start, start + ITEMS_PER_PAGE),
        nextPage: students.length > start + ITEMS_PER_PAGE ? pageParam + 1 : undefined,
        totalStudents: students.length,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    initialPageParam: 1,
  })

  useEffect(() => {
    const pagesLength = studentsPages?.pages?.length ?? 0
    if (inView && hasNextPage && !isFetching && !isSearching && pagesLength > 0) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage, isFetching, isSearching, studentsPages?.pages?.length])

  const allLoadedStudents = studentsPages?.pages.flatMap((page) => page.students) ?? []
  const totalOngoingAssessments = studentsQuery?.data?.reduce((acc, student: User) => {
    return acc + student.lessonAssignments.filter((lesson: LessonAssignment) => lesson.status === 'NOT_STARTED').length
  }, 0)

  const totalCompletedAssessments = studentsQuery?.data?.reduce((acc, student: User) => {
    return acc + student.lessonAssignments.filter((lesson: LessonAssignment) => lesson.status === 'COMPLETED').length
  }, 0)

  const handleFilterChange = (value: typeof filter) => {
    setFilter(value)
    setSearchTerm('')
    setActiveSearch('')
  }

  const handleClearSearch = () => {
    setSearchTerm('')
    setActiveSearch('')
  }

  const prefetchStudentDetails = (studentId: string) => {
    queryClient.prefetchQuery({
      queryKey: ['student', studentId],
      queryFn: async () => {
        const { data } = await getStudentDetails(studentId)
        return data
      },
    })
  }

  return (
    <AdminLayout title="Dashboard">
      <div className="p-8 pt-24">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <div className="rounded-lg bg-white p-6 shadow">
            <h2 className="mb-4 text-xl font-semibold">Total Students</h2>
            <p className="text-3xl font-bold">{totalStudents ?? 0}</p>
          </div>

          <div className="rounded-lg bg-white p-6 shadow">
            <h2 className="mb-4 text-xl font-semibold">Active Assessments</h2>
            <p className="text-3xl font-bold">{totalOngoingAssessments ?? 0}</p>
          </div>

          <div className="rounded-lg bg-white p-6 shadow">
            <h2 className="mb-4 text-xl font-semibold">Completed Assessments</h2>
            <p className="text-3xl font-bold">{totalCompletedAssessments ?? 0}</p>
          </div>
        </div>
      </div>

      <div className="p-8">
        <Card>
          <CardHeader>
            <CardTitle>Students Assessments</CardTitle>
            <div className="mt-4 flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-2 top-2.5 size-4 text-muted-foreground" />
                <Input
                  placeholder="Search students... (Press Enter to filter)"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setIsSearching(true)
                      setActiveSearch(searchTerm)
                      setTimeout(() => setIsSearching(false), 300)
                    }
                  }}
                  className="px-8"
                />
                {searchTerm && (
                  <button
                    onClick={handleClearSearch}
                    className="absolute right-0 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                    type="button"
                  >
                    <X className="size-4" />
                  </button>
                )}
              </div>
              <SelectGroup className="w-[280px]">
                <Select value={filter} onValueChange={(value) => handleFilterChange(value as typeof filter)}>
                  <SelectTrigger className="h-[40px]">
                    <SelectValue placeholder="Select a value" />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectItem value="all">All Students</SelectItem>
                    <SelectItem value="with_pending">With Pending Assessments</SelectItem>
                    <SelectItem value="completed">All Assessments Completed</SelectItem>
                  </SelectContent>
                </Select>
              </SelectGroup>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Student</TableHead>
                  <TableHead>Ongoing</TableHead>
                  <TableHead>Completed</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isInitialLoading || isSearching || (isFetching && !studentsPages?.pages.length) ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <div className="flex h-24 items-center justify-center">
                        <LoadingIcon />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : allLoadedStudents.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <div className="flex h-24 items-center justify-center text-sm text-gray-500">
                        No students found
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  allLoadedStudents.map((student: User) => {
                    const notStartedCount = student.lessonAssignments.filter(
                      (lesson: LessonAssignment) => lesson.status === 'NOT_STARTED'
                    ).length

                    return (
                      <TableRow key={student.id} onMouseEnter={() => prefetchStudentDetails(student.id as string)}>
                        <TableCell className="font-medium">{student.name || student.email}</TableCell>
                        <TableCell>{notStartedCount}</TableCell>
                        <TableCell>{student.lessonAssignments.length - notStartedCount}</TableCell>
                        <TableCell className="text-right">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              navigate(`/admin/students/${student.id}${queryString}`)
                            }}
                          >
                            <ExternalLink className="mr-2 size-4" />
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })
                )}
              </TableBody>
            </Table>

            <div ref={loadMoreRef} className="py-4 text-center">
              {hasNextPage && (
                <div className="flex justify-center">
                  <LoadingIcon />
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </AdminLayout>
  )
}

export default Dashboard
