import React, { createContext, ReactNode, useContext, useState } from 'react'

import Modal from '@/components/Modal'

interface IModalContext {
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
  setModalContent: React.Dispatch<React.SetStateAction<ReactNode | null>>
}

const ModalContext = createContext<IModalContext | undefined>(undefined)

interface ModalProviderProps {
  children: ReactNode
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [ModalContent, setModalContent] = useState<ReactNode | null>(null)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, setModalContent }}>
      <>
        {children}
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            {ModalContent}
          </Modal>
        )}
      </>
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
