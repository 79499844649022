import { Pause, Play } from 'lucide-react'
import { useEffect, useRef } from 'react'
import { useState } from 'react'

import Spinner from '@/components/Spinner'
import { Button } from '@/components/ui/button'
import { api } from '@/utils/api'

function AudioPlayer({ s3Key }: { s3Key?: string }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isAudioLoading, setIsAudioLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime)
    }
  }

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration)
      setCurrentTime(0)
      setIsReady(true)
    }
  }

  const handleLoadedData = () => {
    setIsAudioLoading(false)
  }

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = Math.min(Number(e.target.value), duration)
    if (audioRef.current) {
      audioRef.current.currentTime = time
      setCurrentTime(time)
    }
  }

  const formatTime = (time: number) => {
    if (time === 0) return '0:00'
    if (time === Infinity) return ''

    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  useEffect(() => {
    const loadAudio = async () => {
      try {
        setIsLoading(true)
        setIsAudioLoading(true)
        const response = await api.post('/get-s3-file', { key: s3Key })
        const { presignedUrl } = response.data

        const audio = new Audio(presignedUrl)
        audio.addEventListener('timeupdate', handleTimeUpdate)
        audio.addEventListener('loadedmetadata', handleLoadedMetadata)
        audio.addEventListener('loadeddata', handleLoadedData)
        audioRef.current = audio
      } catch (error) {
        console.error('Error loading audio:', error)
        setIsAudioLoading(false)
      } finally {
        setIsLoading(false)
      }
    }

    if (s3Key) {
      loadAudio()
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
        setCurrentTime(0)
        setIsPlaying(false)
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate)
        audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata)
        audioRef.current.removeEventListener('loadeddata', handleLoadedData)
        audioRef.current = null
      }
    }
  }, [s3Key])

  const togglePlay = async () => {
    if (!audioRef.current) return

    if (audioRef.current.paused) {
      await audioRef.current.play()
      setIsPlaying(true)
    } else {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  useEffect(() => {
    const audio = audioRef.current
    if (!audio) return

    const handlePlay = () => setIsPlaying(true)
    const handlePause = () => setIsPlaying(false)
    const handleEnded = () => {
      setIsPlaying(false)
      setCurrentTime(0)
    }

    audio.addEventListener('play', handlePlay)
    audio.addEventListener('pause', handlePause)
    audio.addEventListener('ended', handleEnded)

    return () => {
      audio.removeEventListener('play', handlePlay)
      audio.removeEventListener('pause', handlePause)
      audio.removeEventListener('ended', handleEnded)
    }
  }, [audioRef.current])

  if (!s3Key) return null

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-4">
        <Button
          variant="outline"
          size="icon"
          onClick={togglePlay}
          disabled={isLoading || !isReady}
          className="flex items-center justify-center border-primary p-2 text-primary transition-all hover:scale-105 hover:bg-primary/10"
        >
          {isLoading || isAudioLoading ? (
            <div className="m-4">
              <Spinner />
            </div>
          ) : isPlaying ? (
            <Pause className="size-6" />
          ) : (
            <Play className="size-6" />
          )}
        </Button>
        <div className="flex-1">
          <input
            type="range"
            min={0}
            max={duration}
            step={0.01}
            value={Math.min(currentTime, duration)}
            onChange={handleSeek}
            className="mt-2 w-full accent-primary hover:cursor-pointer"
            disabled={!isReady}
          />
          <div className="flex justify-between text-sm text-gray-500">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
      </div>
      {isAudioLoading && <div className="text-sm text-gray-500">Loading audio...</div>}
    </div>
  )
}

export default AudioPlayer
