import {
  Grade,
  Invitation,
  Lesson,
  LessonAssignment,
  LesssonAssignmentResult,
  User,
} from '@alpha-school/reading-fluency-models'
import { ClassifyPhonemeModels } from '@alpha-school/reading-fluency-models'
import axios from 'axios'

import { LessonAssignmentWithLesson } from '@/hooks/useUser'
import { ResponseData, SpeechTokenType, UserType } from '@/types/models'
import { StudentDetailsResponse } from '@/types/student'

const API_URL = import.meta.env.VITE_API_URL

type PhonemeClassificationResponse = {
  prediction: string
  confidence: number
}

export const getUserSession = (): UserType | null => {
  const rawUserSession = localStorage.getItem('user-session')
  return rawUserSession ? JSON.parse(rawUserSession) : null
}

export const api = axios.create({ baseURL: `${API_URL}/api` })

type SpeechTokenResponseType = Promise<ResponseData<SpeechTokenType>>
export const getSpeechToken = async (): SpeechTokenResponseType => {
  return api.get('/get-speech-token')
}

export const getChatCompletion = async (prompt: string) => {
  return api.post('/openai/chat-completion', {
    messages: [
      {
        role: 'user',
        content: prompt,
      },
    ],
    format: 'json_object',
  })
}

export const getAnthropicChatCompletion = async (prompt: string) => {
  return api.post('/anthropic/chat-completion', {
    messages: [
      {
        role: 'user',
        content: prompt,
      },
    ],
  })
}

export const getLessons = async () => {
  return api.get<Lesson[]>('/lessons')
}

export const verifyUser = async (): Promise<ResponseData<User>> => {
  return api.post('/users/verify')
}

export const getStudents = () => api.get<User[]>('/users/students')

export const assignLesson = async (userId: string, lessonId: string) => {
  return api.post('lesson-assignments', {
    userId,
    lessonId,
  })
}

export const getUserWithLessons = async (): Promise<ResponseData<User>> => {
  return api.post(`/users/lessons`)
}

export const getLessonAssignmentResult = async (id: string) => {
  return api.get(`/lesson-assignment-results/${id}`)
}

export const createLessonAssignmentResult = async ({
  userId,
  lessonAssignmentId,
  exerciseId,
  activityId,
  status,
  result,
  locale,
}: {
  userId: string
  lessonAssignmentId: string
  exerciseId: string
  activityId: string
  status: string
  result: any
  locale?: string
}): Promise<ResponseData<LesssonAssignmentResult>> => {
  return api.post('/lesson-assignment-results', {
    userId,
    lessonAssignmentId,
    exerciseId,
    activityId,
    status,
    result,
    locale,
  })
}

export const correctLessonAssignmentResult = async (
  id: string,
  result: any
): Promise<ResponseData<LesssonAssignmentResult>> => {
  return api.put(`/lesson-assignment-results/correct`, {
    id,
    result,
  })
}

export const deleteLessonAssignmentResult = async (id: string): Promise<ResponseData<LesssonAssignmentResult>> => {
  return api.post('/lesson-assignment-results/delete', {
    id,
  })
}

export const deleteLesson = async (id: string): Promise<ResponseData<Lesson>> => {
  return api.post('/lessons/delete', {
    id,
  })
}

export const updateLessonAssignment = async ({
  lessonAssignmentId,
  status,
  result,
  correctedResult,
}: {
  lessonAssignmentId: string
  status: string
  result?: any
  correctedResult?: any
}): Promise<ResponseData<LessonAssignment>> => {
  return api.put(`/lesson-assignments`, {
    lessonAssignmentId,
    status,
    result,
    correctedResult,
  })
}

export const deleteLessonAssignment = async (id: string): Promise<ResponseData<LessonAssignment>> => {
  return api.post('/lesson-assignments/delete', {
    id,
  })
}

export const getLessonAssignments = async (studentId: string, lessonId: string) => {
  return api.get<LessonAssignment[]>(`/lessons-assignments/${studentId}/${lessonId}`)
}

export const getStudentDetails = async (studentId: string): Promise<ResponseData<StudentDetailsResponse>> => {
  return api.get(`/users/students/${studentId}`)
}

export const getStudentResults = async (
  studentId: string,
  lessonId?: string,
  assignmentId?: string
): Promise<ResponseData<StudentDetailsResponse[]>> => {
  return api.get(`/users/students/${studentId}/results`, {
    params: {
      lessonId,
      assignmentId,
    },
  })
}

export const classifyPhoneme = async (
  audioBlob: Blob,
  selectedLetter: string,
  model: ClassifyPhonemeModels
): Promise<ResponseData<PhonemeClassificationResponse>> => {
  const formData = new FormData()
  formData.append('audio_file', audioBlob)
  formData.append('selected_letter', selectedLetter)
  formData.append('model', model)
  return api.post('/classify-phoneme', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const createLesson = async (data: {
  title: string
  description?: string
  exercises: {
    type: string
    props: any
    activities: {
      type: string
      props: any
    }[]
  }[]
}) => {
  return api.post('/lessons', data)
}

export const updateLesson = async (id: string, data: any) => {
  console.log('Sending update request:', { id, data })
  return api.put(`/lessons/${id}`, data)
}

export const inviteStudent = async (email: string, preassignedLessonIds?: string[]) => {
  return api.post('/users/student/invitations/create', {
    email,
    preassignedLessonIds,
  })
}

export const getStudentInvitations = async (): Promise<ResponseData<Invitation[]>> => {
  return api.get('/users/student/invitations')
}

export const revokeStudentInvitation = async (invitationId: string) => {
  return api.post('/users/student/invitations/revoke', {
    invitationId,
  })
}

export const updateStudent = async (studentId: string, grade: Grade) => {
  return api.put(`/users/students/${studentId}`, { grade })
}

export const getStudentLessons = async (studentId: string) => {
  return api.get(`/users/students/${studentId}/lessons`)
}

export const getUserAssignments = () => {
  return api.get<LessonAssignmentWithLesson[]>('/users/assignments')
}

export const reorderAssignment = async ({ orderedIds, studentId }: { orderedIds: string[]; studentId: string }) => {
  return api.patch(`/users/students/${studentId}/reorder-assignment`, { orderedIds })
}
