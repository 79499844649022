import { useMemo } from 'react'
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel'
import { parseLetterSoundCompletionsFluency } from '@/lib/parsers/letter-sound-completions-fluency'
import { LessonAssignment } from '@/types/lesson-assignment'

interface LetterSoundFluencyScoreProps {
  filteredAssessments: LessonAssignment<'letter-sound-completions-fluency'>[]
}

interface ScoreWithDate {
  correctCount: number
  incorrectCount: number
  correctPhonemes: {
    phoneme: string
    matchQuality: number
    topMatches: string[]
  }[]
  missedPhonemes: {
    phoneme: string
    matchQuality: number
    topMatches: string[]
  }[]
  commonlyMissedPhonemes: {
    phoneme: string
    matchQuality: number
    topMatches: string[]
  }[]
  completedAt: string
  rawDate: Date
}

export default function LetterSoundCompletionFluencyScore({ filteredAssessments }: LetterSoundFluencyScoreProps) {
  const scores = useMemo(() => {
    return filteredAssessments
      ?.map((assignment) => ({
        ...parseLetterSoundCompletionsFluency(assignment.result),
        completedAt: new Date(assignment.completedAt).toLocaleString(),
        rawDate: new Date(assignment.completedAt),
      }))
      .sort((a, b) => a.rawDate.getTime() - b.rawDate.getTime()) as ScoreWithDate[]
  }, [filteredAssessments])

  const aggregatedMissedPhonemes = useMemo(() => {
    if (!scores) return []

    // Create a map to track phoneme statistics
    const phonemeStats = scores.reduce<
      Record<string, { count: number; totalMatchQuality: number; topMatches: string[] }>
    >((acc, analysis) => {
      analysis.missedPhonemes.forEach(({ phoneme, matchQuality, topMatches }) => {
        if (!acc[phoneme]) {
          acc[phoneme] = { count: 0, totalMatchQuality: 0, topMatches }
        }
        acc[phoneme].count++
        acc[phoneme].totalMatchQuality += matchQuality
      })
      return acc
    }, {})

    // Convert to array and calculate averages
    return Object.entries(phonemeStats)
      .map(([phoneme, { count, totalMatchQuality, topMatches }]) => ({
        phoneme,
        count,
        averageMatchQuality: totalMatchQuality / count,
        topMatches,
      }))
      .sort((a, b) => b.count - a.count) // Sort by most frequent first
  }, [scores])

  return (
    <>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
        {filteredAssessments.length > 1 ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={scores}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="correctCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Correct Phonemes']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="correctCount" stroke="#8884d8" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Count</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={scores}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="incorrectCount" />
                      <YAxis />
                      <Tooltip
                        formatter={(value) => [`${value}`, 'Incorrect Phonemes']}
                        labelFormatter={(_, data) => data[0]?.payload?.completedAt || ''}
                      />
                      <Line type="monotone" dataKey="incorrectCount" stroke="#f87171" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Commonly Missed Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={aggregatedMissedPhonemes}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="phoneme" />
                      <YAxis allowDecimals={false} domain={[0, 'auto']} />
                      <Tooltip
                        formatter={(value) => [`${value} times`, 'Times Missed']}
                        labelFormatter={(_, data) => `Phoneme ${data[0]?.payload?.phoneme}` || ''}
                      />
                      <Bar dataKey="count" fill="#f87171" name="Times Missed" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            <Card>
              <CardHeader>
                <CardTitle>Correct Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-green-600">{scores[0]?.correctCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">Phonemes identified correctly</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Incorrect Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-4xl font-bold text-red-600">{scores[0]?.incorrectCount || 0}</p>
                <p className="mt-2 text-sm text-gray-600">Phonemes identified incorrectly</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Missed Phonemes</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-wrap gap-2">
                  {aggregatedMissedPhonemes.map(({ phoneme, averageMatchQuality }) => (
                    <div key={phoneme} className="flex items-center gap-2 rounded-md bg-red-100 px-3 py-1 text-sm">
                      <span className="font-semibold text-red-600">{phoneme}</span>
                      <span className="text-xs text-red-500">{Math.round(averageMatchQuality)}%</span>
                    </div>
                  ))}
                  {aggregatedMissedPhonemes.length === 0 && (
                    <p className="text-sm text-gray-600">No phonemes were missed!</p>
                  )}
                </div>
              </CardContent>
            </Card>
          </>
        )}

        <Card className="xl:col-span-3">
          <CardHeader>
            <CardTitle>Top Matches for Missed Phonemes</CardTitle>
          </CardHeader>
          <CardContent>
            {aggregatedMissedPhonemes.length > 0 ? (
              <div className="relative">
                <Carousel
                  opts={{
                    align: 'start',
                    loop: true,
                  }}
                  className="w-full"
                >
                  <CarouselContent>
                    {aggregatedMissedPhonemes.map(({ phoneme, topMatches }) => (
                      <CarouselItem key={phoneme}>
                        <div className="rounded-md bg-gray-50 p-4">
                          <p className="mb-2 font-semibold">Target Phoneme: {phoneme}</p>
                          <div className="space-y-1">
                            {topMatches.map((match, index) => (
                              <p key={index} className="text-sm text-gray-600">
                                {match}
                              </p>
                            ))}
                          </div>
                        </div>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  {aggregatedMissedPhonemes.length > 1 && (
                    <>
                      <CarouselPrevious className="absolute -left-[40px] top-1/2 size-[40px] -translate-y-1/2" />
                      <CarouselNext className="absolute -right-[40px] top-1/2 size-[40px] -translate-y-1/2" />
                    </>
                  )}
                </Carousel>
              </div>
            ) : (
              <p className="text-sm text-gray-600">No missed phonemes to display!</p>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  )
}
