import './styles.css'

import { Role } from '@alpha-school/reading-fluency-models'
import { useOrganizationList } from '@clerk/clerk-react'
import { MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AvatarClient, AvatarProvider } from 'alpha-ai-avatar-sdk-react'
import { register } from 'extendable-media-recorder'
import { connect } from 'extendable-media-recorder-wav-encoder'
import { ReactNode, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import ConfigPanel from '@/components/ConfigPanel'
import { Toaster } from '@/components/ui/toaster'
import { TooltipProvider } from '@/components/ui/tooltip'
import { AudioProvider } from '@/context/AudioRecordingContext'
import { AuthProvider } from '@/context/AuthContext'
import { AvatarWrapperProvider } from '@/context/AvatarWrapperContext'
import { MouthReadingProvider } from '@/context/MouthReadingContext/MouthReadingContext'
import { NavigationListenerProvider } from '@/context/NavigationListenerContext'
import { SentryUserInjector } from '@/context/Sentry/SentryUserInjector'

import { AppConfigProvider } from './context/AppConfig'
import { ConfettiProvider } from './context/ConfettiContext/ConfettiContext'
import { LessonProvider } from './context/LessonContext'
import { MasteryLessonProvider } from './context/MasteryLessonContext'
import { ModalProvider } from './context/ModalContext'
import { SpeechProvider } from './context/SpeechContext'
import { SpeechRecognitionProvider } from './context/SpeechRecognitionContext'
import { UserProvider } from './context/UserContext'
import { useAuth } from './hooks/useAuth'
import AssignmentDetails from './pages/Admin/AssignmentDetails'
import Dashboard from './pages/Admin/Dashboard'
import Invitations from './pages/Admin/Invitations'
import { LessonAssignment } from './pages/Admin/LessonAssignment'
import Lessons from './pages/Admin/Lessons'
import StudentDetails from './pages/Admin/StudentDetails'
import StudentLessonDetails from './pages/Admin/StudentLessonDetails'
import Students from './pages/Admin/Students'
import { AllDone } from './pages/AllDone/AllDone'
import AcceptToken from './pages/Auth/AcceptToken'
import SignIn from './pages/Auth/SignIn'
import SignUp from './pages/Auth/SignUp'
import { AvatarSettings } from './pages/AvatarSettings'
import Home from './pages/Home'
import MasteryLesson from './pages/MasteryLesson'

const AVATAR_KEY = import.meta.env.VITE_AVATAR_KEY
const BASE_URL = import.meta.env.VITE_AVATAR_BASE_URL

const client = new AvatarClient({ apiKey: AVATAR_KEY, baseUrl: BASE_URL })

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutationKey) => {
      console.error('Mutation error:', error, variables, context, mutationKey)
    },
  }),
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      structuralSharing: true,
    },
  },
})

const ProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const queryString = window.location.search
  const { userId, isLoaded } = useAuth()
  const { isLoaded: isOrgLoaded } = useOrganizationList()

  if (!isLoaded) {
    return null
  }

  if (!userId) {
    return <Navigate to={`/sign-in${queryString}`} replace />
  }

  if (!isOrgLoaded) {
    return null
  }

  return children
}

const AdminProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userId, orgRole, isLoaded } = useAuth()
  const { isLoaded: isOrgLoaded } = useOrganizationList()

  if (!isLoaded) {
    return null
  }

  if (!userId) {
    return <Navigate to="/sign-in" replace />
  }

  if (!isOrgLoaded) {
    return null
  }

  if (orgRole && orgRole === Role.STUDENT) {
    return <Navigate to="/" replace />
  }

  return children
}

function App() {
  const queryString = window.location.search
  const searchParams = new URLSearchParams(queryString)
  const showConfigPanel = searchParams.get('config') === 'true'

  useEffect(() => {
    async function doAsync() {
      await register(await connect())
    }
    doAsync()
  }, [])

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppConfigProvider>
          <SpeechRecognitionProvider>
            <AuthProvider>
              <TooltipProvider>
                <UserProvider>
                  <SentryUserInjector>
                    <LessonProvider>
                      <AvatarProvider client={client}>
                        <AvatarWrapperProvider>
                          <SpeechProvider>
                            {/* <InterventionsProvider> */}
                            <ModalProvider>
                              <ConfettiProvider>
                                <MasteryLessonProvider>
                                  <AudioProvider>
                                    <MouthReadingProvider>
                                      <NavigationListenerProvider>
                                        {/* <MouthReading /> */}
                                        {showConfigPanel && <ConfigPanel />}
                                        <Routes>
                                          <Route path="/accept-token" element={<AcceptToken />} />
                                          <Route path="/sign-in" element={<SignIn />} />
                                          <Route path="/sign-up" element={<SignUp />} />
                                          <Route
                                            path="/admin/avatar-settings"
                                            element={
                                              <AdminProtectedRoute>
                                                <AvatarSettings />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/dashboard"
                                            element={
                                              <AdminProtectedRoute>
                                                <Dashboard />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/students"
                                            element={
                                              <AdminProtectedRoute>
                                                <Students />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/students/:id"
                                            element={
                                              <AdminProtectedRoute>
                                                <StudentDetails />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/students/:id/:lessonId"
                                            element={
                                              <AdminProtectedRoute>
                                                <StudentLessonDetails />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/students/:id/:lessonId/:assignmentId"
                                            element={
                                              <AdminProtectedRoute>
                                                <AssignmentDetails />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/lesson-assignment"
                                            element={
                                              <AdminProtectedRoute>
                                                <LessonAssignment />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/assessments"
                                            element={
                                              <AdminProtectedRoute>
                                                <Lessons />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/admin/invite"
                                            element={
                                              <AdminProtectedRoute>
                                                <Invitations />
                                              </AdminProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path="/"
                                            element={
                                              <ProtectedRoute>
                                                <Home />
                                              </ProtectedRoute>
                                            }
                                          />
                                          <Route
                                            path={`/mastery`}
                                            element={
                                              <ProtectedRoute>
                                                <MasteryLesson />
                                              </ProtectedRoute>
                                            }
                                          />
                                          <Route path="/all-done" element={<AllDone />} />
                                          <Route path="*" element={<Navigate to="/" replace />} />
                                        </Routes>
                                      </NavigationListenerProvider>
                                    </MouthReadingProvider>
                                  </AudioProvider>
                                </MasteryLessonProvider>
                              </ConfettiProvider>
                            </ModalProvider>
                            {/* </InterventionsProvider> */}
                          </SpeechProvider>
                        </AvatarWrapperProvider>
                      </AvatarProvider>
                    </LessonProvider>
                  </SentryUserInjector>
                </UserProvider>
              </TooltipProvider>
            </AuthProvider>
          </SpeechRecognitionProvider>
        </AppConfigProvider>
      </QueryClientProvider>
      <Toaster />
    </>
  )
}

export default App
