import {
  Activity,
  ActivityType,
  Exercise,
  ExerciseType,
  LessonAssignment,
  LesssonAssignmentResult,
  Prisma,
  User,
} from '@prisma/client'

// First export the base types
export type { Invitation } from '@clerk/backend'
export type { Activity, ActivityType, Exercise, ExerciseType, LessonAssignment, LesssonAssignmentResult, Prisma, User }

// Then define and export interfaces that depend on those types
export interface Lesson {
  id: string
  title: string
  active: boolean
  description: string | null
  createdAt: Date | string
  current: {
    exercise: number
    activity: number
  }
  exercises: {
    type: ExerciseType
    id: string
    props: Prisma.JsonValue
    activities: {
      type: ActivityType
      id: string
      props: Prisma.JsonValue
    }[]
  }[]
}

export enum Role {
  FLUENCY_ADMIN = 'org:fluency_admin',
  STUDENT = 'org:student',
  TEACHER = 'org:teacher',
  ADMIN = 'org:admin',
}

export const StudentAccessRoles = [Role.FLUENCY_ADMIN.toString(), Role.ADMIN.toString(), Role.TEACHER.toString()]

export enum Grade {
  KINDERGARTEN = 'Kindergarten',
  FIRST_GRADE = 'First Grade',
  SECOND_GRADE = 'Second Grade',
  THIRD_GRADE = 'Third Grade',
  FOURTH_GRADE = 'Fourth Grade',
  FIFTH_GRADE = 'Fifth Grade',
  SIXTH_GRADE = 'Sixth Grade',
}

export interface LessonAssignmentWithLesson extends LessonAssignment {
  lesson: Lesson & {
    exercises: Array<
      Exercise & {
        activities: Activity[]
      }
    >
  }
}

export enum ClassifyPhonemeModels {
  FAST = 'fast',
  FSF = 'fsf',
  FULL = 'full',
}
